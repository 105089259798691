/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import { useCallback, useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import Slider from 'react-slick';

import { articlesService } from 'config/services';

import { HttpError } from 'helpers/http';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { Article } from 'types/services/articles';

import { articleToCardDetails as details } from './ArticleToCardDetails';
import { Card } from './Card';

type ContainerProps = {
  classNames?: string;
  isAvazTv?: boolean;
  width?: string;
  height?: string;
  dark?: string;
};

export type HeaderProps = {
  title?: string | React.ReactNode | JSX.Element;
  link?: string;
  titleColor?: string;
  borderColor?: string;
  chevron: 'right' | 'bottom' | 'none' | 'right ml-[-1rem]';
  appendHashtag?: boolean;
  isArticle?: boolean;
};

export type SectionProps = {
  articles: Article[];
  sortByBlockPosition?: boolean;
  header?: HeaderProps;
  stampColor?: string;
  hideTimeAgo?: boolean;
  hasAd?: boolean;
  category?: boolean;
  increasePadding?: boolean;
  initialBlock?: boolean;
};

export const ChevronLeft = ({ classNames = 'h-6 w-6 right-dark-mode', dark }: ContainerProps) => (
  <svg xmlns="http://www.w3.org/2000/svg" className={classNames} fill="none" viewBox="0 0 24 24" stroke="currentColor">
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M15 19l-7-7 7-7"
      color={dark === 'dark' ? 'white' : ''}
    />
  </svg>
);

export const ChevronRight = ({ width, height, classNames, dark }: ContainerProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    width={width}
    height={height}
    x="0"
    y="0"
    viewBox="0 0 128 128"
    xmlSpace="preserve"
    stroke="currentColor"
    id="Right_Arrow_4_Svg"
  >
    <g>
      <path
        id="Right_Arrow_4_"
        d="m44 108c-1.023 0-2.047-.391-2.828-1.172-1.563-1.563-1.563-4.094 0-5.656l37.172-37.172-37.172-37.172c-1.563-1.563-1.563-4.094 0-5.656s4.094-1.563 5.656 0l40 40c1.563 1.563 1.563 4.094 0 5.656l-40 40c-.781.781-1.805 1.172-2.828 1.172z"
        data-original="#000000"
        className={classNames}
        style={{ fill: dark === 'dark' ? 'white' : '' }}
      />
    </g>
  </svg>
);
export function PrevArrow(props: any) {
  const { style, onClick, dark } = props;
  return (
    <div
      style={{ ...style, display: 'flex', boxShadow: '0px 8px 24px rgba(0, 0, 0, 0.15)' }}
      onClick={onClick}
      id="arrow-styles-l"
    >
      <div
        style={{
          background: dark === 'dark' ? '#333' : '#FFFFFF',
          width: '36px',
          height: '36px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <ChevronLeft width="15px" height="15px" classNames="not-index-chevron" dark={dark} />
      </div>
    </div>
  );
}

// NEXT ARROW
export function NextArrow(props: any) {
  const { style, onClick, dark } = props;
  return (
    <div
      style={{
        ...style,
        display: 'flex',
        boxShadow: '0px 8px 24px rgba(0, 0, 0, 0.15)',
      }}
      onClick={onClick}
      id="arrow-styles"
    >
      <div
        style={{
          background: dark === 'dark' ? '#333' : '#FFFFFF',
          width: '36px',
          height: '36px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <ChevronRight width="15px" height="15px" classNames="not-index-chevron" dark={dark} />
      </div>
    </div>
  );
}

export const ArticleSliderPage = (props: SectionProps) => {
  const { blockId, takeNum, dark } = useParams<{ blockId: string; takeNum: string; dark: string }>();
  const {
    isLoading,
    isFetching,
    isError,
    data: response,
    error,
    refetch,
  } = useQuery<any, HttpError>(
    ['listByBlock'],
    () => articlesService.listByBlock({ takeNum: takeNum, blockId: blockId }),
    { enabled: blockId !== undefined && takeNum !== undefined },
  );

  let url = new URL(window.location.href);
  let source = new URLSearchParams(url.search).getAll('source');

  useEffect(() => {
    if (source && source.length > 0) {
      console.log('source = ', source[0]);
      window.gtag('event', 'view-' + source[0]);
    }
  }, []);

  const { header, articles, hideTimeAgo, sortByBlockPosition, increasePadding } = props;
  const [hasInfinite, setHasInfinite] = useState(false);
  const [isShuffled, setIsShuffled] = useState(false);

  useEffect(() => {
    if (response !== undefined) {
      if (response.blockImage.isRandom && !isShuffled) {
        // shuffle response.articles
        response.articles = response.articles.sort(() => Math.random() - 0.5);
        setIsShuffled(true);
      } else if (!response.blockImage.isRandom) setIsShuffled(true);

      if (response.length > 4) setHasInfinite(true);

      const arts = response?.articles;

      for (let index = 0; index < arts.length; index++) {
        const element = arts[index];
        console.log(element.id, 'article.id');
        if (source && source.length > 0) {
          console.log('source = ', source[0]);
          window.gtag('event', 'single-' + element.id + '-' + source[0]);
        }
      }
    }
  }, [response]);

  const sliderSettings = {
    speed: 500,
    dots: false,
    infinite: hasInfinite,
    slidesToShow: 4,
    slidesToScroll: 4,
    nextArrow: <NextArrow dark={dark} />,
    prevArrow: <PrevArrow dark={dark} />,
  };

  const useMediaQuery = (width: number) => {
    const [targetReached, setTargetReached] = useState(false);

    const updateTarget = useCallback((e) => {
      if (e.matches) {
        setTargetReached(true);
      } else {
        setTargetReached(false);
      }
    }, []);

    useEffect(() => {
      const media = window.matchMedia(`(max-width: ${width}px)`);
      media.addEventListener('change', updateTarget);
      // Check on mount (callback is not called until a change occurs)
      if (media.matches) {
        setTargetReached(true);
      }

      return () => media.removeEventListener('change', updateTarget);
    }, []);

    return targetReached;
  };

  const isBreakpoint = useMediaQuery(768);

  const Divider = () => <div className={`dividerMiniCms ${dark === 'dark' ? 'divider-darkmode' : ''}`} />;

  return (
    <div
      style={{ backgroundColor: '#f9f9f9', paddingRight: isBreakpoint ? '20px' : '0px' }}
      className={`${dark === 'dark' ? 'darkMode-background' : ''}`}
    >
      {!isLoading && response?.articles.length > 0 && (
        <div
          style={{
            display: 'flex',
            backgroundColor: '#f9f9f9',
            marginBottom: '20px',
            paddingLeft: isBreakpoint ? '20px' : '0px',
          }}
          className={`${dark === 'dark' ? 'darkMode-background' : ''} headerDesktopWidth`}
        >
          {response?.blockImage?.imageBlock?.thumbnailUrl || response?.blockImage?.imageBlockDark?.thumbnailUrl ? (
            <>
              <div className="containers-flex">
                {!isLoading && (
                  <img
                    className="logoStyles"
                    src={
                      dark === 'light'
                        ? response?.blockImage?.imageBlock?.thumbnailUrl
                          ? response?.blockImage?.imageBlock?.thumbnailUrl
                          : response?.blockImage?.imageBlockDark?.thumbnailUrl
                        : response?.blockImage?.imageBlockDark?.thumbnailUrl
                        ? response?.blockImage?.imageBlockDark?.thumbnailUrl
                        : response?.blockImage?.imageBlock?.thumbnailUrl
                    }
                    alt="123"
                  ></img>
                )}
              </div>
              <div className="containers-flex">
                <ChevronRight width="15px" height="15px" classNames={`chevron-class`} dark={dark} />
              </div>
              <div style={{ width: '100%', marginLeft: '3px' }} className={`containers-flex`}>
                <Divider></Divider>
              </div>
            </>
          ) : (
            <></>
          )}
        </div>
      )}
      {!isBreakpoint ? (
        <>
          {!isLoading && isShuffled && response?.articles.length > 0 ? (
            <Slider {...sliderSettings}>
              {response?.articles?.map((article: any) => {
                return (
                  <div>
                    <div key={`article-${article.id}`} className="responsive-article-content smMaxW" style={{}}>
                      <Card
                        {...details({ article })}
                        id={article.id}
                        hideTimeAgo={hideTimeAgo}
                        orientation="vertical"
                        isAds={article.isAds}
                        containerClassNames="!space-x-[2rem]"
                        thumbnail={{
                          src: article.thumbnailImage.imageUrl,
                          aspect: 'video',
                          width: '100%',
                        }}
                        imageClassNames={'w-[174px] h-[97px]'}
                        titleClassNames={props.header?.titleColor || 'text-white'}
                        stampVisibility={true}
                        source={source && source.length > 0 ? source[0] : undefined}
                      />
                    </div>
                  </div>
                );
              })}
            </Slider>
          ) : (
            <></>
          )}
        </>
      ) : (
        <div
          style={{
            display: 'flex',
            flexWrap: 'nowrap',
            overflowX: 'auto',
            width: '100vw',
            paddingLeft: isBreakpoint ? '20px' : '0px',
          }}
          className="scrollBarClass pr-small"
        >
          <div>
            <div
              style={{
                flexWrap: 'nowrap',
                display: 'flex',
              }}
              className="articleContainer"
            >
              {response?.articles &&
                isShuffled &&
                response?.articles.length > 0 &&
                response?.articles?.map((article: any) => (
                  <div
                    key={`article-${article.id}`}
                    className="smMaxW responsive-article-content"
                    style={{ marginRight: '20px', maxWidth: '180px' }}
                  >
                    <Card
                      {...details({ article })}
                      hideTimeAgo={hideTimeAgo}
                      orientation="vertical"
                      isAds={article.isAds}
                      thumbnail={{
                        src: article?.thumbnailImage.imageUrl,
                        aspect: 'video',
                        width: '100%',
                      }}
                      imageClassNames={'w-[174px] h-[97px]'}
                      titleClassNames={props.header?.titleColor || 'text-white'}
                      stampVisibility={true}
                      isMobile={isBreakpoint}
                    />
                  </div>
                ))}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
