import {
  LogoutOutlined,
  UserOutlined,
  SaveOutlined,
  PlusOutlined,
  UploadOutlined,
  HomeOutlined,
  PlusSquareOutlined,
  RadarChartOutlined,
} from '@ant-design/icons';
import {
  Layout,
  Menu,
  Avatar,
  Dropdown,
  Badge,
  Form,
  Drawer,
  Button,
  Input,
  Select,
  Upload,
  message,
  Modal,
} from 'antd';
import { Image as Picture } from 'antd';
import MenuDivider from 'antd/lib/menu/MenuDivider';
import classnames from 'classnames';
import { DateTime } from 'luxon';
import { ReactNode, useEffect, useState, useRef, useCallback } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { useTranslation } from 'react-i18next';
import { useQuery, useMutation } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import {
  logout as logoutAction,
  toggleSubmenu as toggleSubmenuAction,
  closeAllSubmenus as closeAllSubmenusAction,
} from 'actions/index';

import AvazLogo from 'assets/transparentna.png';
import AvazLogoSmall from 'assets/transparentnamala.png';

import { usersService } from 'config/services';

import { HttpError } from 'helpers/http';
import { capitalizeFirstLetter, getBase64 } from 'helpers/shared';

import { UploadImages } from 'routes/articles/components/UploadImages/UploadImages';

import { Item as NavUtilItem, Submenu as NavUtilSubmenu, isSubmenu } from 'types/common';
import { PatchParams } from 'types/common';
import { FileExtended, UploadFile } from 'types/files';
import { PermissionsMap } from 'types/services/auth';
import { Image } from 'types/services/images';
import { Role } from 'types/services/rbac';
import { User } from 'types/services/users';
import { SessionState, StoreState } from 'types/store';

import './index.scss';

const { Header, Content, Sider } = Layout;
const { SubMenu } = Menu;

interface Props extends RouteComponentProps {
  children: ReactNode;
  mode?: 'row' | 'card';
  maxCount?: number;
  multiple?: boolean;
  value?: Image | Image[];
  onChangeProp?: (images: Image[]) => void;
}

export type ModalPromise = {
  resolve: (image: FileExtended | UploadFile) => void;
  reject: (image?: FileExtended | UploadFile) => void;
};

export const Navigation = withRouter(
  ({ children, history, mode = 'card', maxCount, multiple, onChangeProp }: Props) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const [isCollapsed, collapse] = useState(false);
    const [isMobileOpen, setIsMobileOpen] = useState(false);
    const [notiCount, setNotiCount] = useState(0);
    const [toSeen, setToSeen] = useState(false);
    const [formEdit] = Form.useForm<User>();
    const [thumbnailUrl, setThumbnailUrl] = useState<string | null>();
    const [isDrawerVisible, setIsDrawerVisible] = useState(false);
    const [squareImageEdit] = useState(false);
    const [warning, setWarning] = useState(false);
    const isCardMode = mode === 'card';
    const [fileList, setFileList] = useState<UploadFile[]>([]);
    const [logoImageInput, setLogoImageInput] = useState(false);

    const { data: getuser, refetch: refetchEmail } = useQuery<any, HttpError>({
      queryKey: ['getUserByEmail'],
      queryFn: () => usersService.getByEmail(user?.email),
      enabled: true,
    });

    const [selectedImage, setSelectedImage] = useState<{ isNewImage: boolean; image?: FileExtended | UploadFile }>();
    const editImageModalPromise = useRef<ModalPromise>();
    const [warningType, setWarningType] = useState<any>([]);
    const [roles, setRoles] = useState<Role[]>([]);

    const { Option } = Select;

    const { isLoading: isEditing, mutateAsync } = useMutation<User, HttpError, PatchParams<User>>(
      'editUser',
      (params) => usersService.patch(params),
    );

    const { user } = useSelector<StoreState, SessionState>(({ session }) => session);

    const forward = (path: string) => history.push(path);
    const onCollapse = () => {
      collapse(!isCollapsed);
      dispatch(closeAllSubmenusAction());
    };

    const logout = () => dispatch(logoutAction());
    const toggleSubmenu = (submenu: string) => dispatch(toggleSubmenuAction(submenu));

    const entries = useSelector<StoreState, (NavUtilItem | NavUtilSubmenu)[]>(({ sidebar }) => sidebar.entries);
    const activeSubmenuKeys = useSelector<StoreState, string[] | undefined>(({ sidebar }) => sidebar.activeSubmenuKeys);
    const permissionMap = useSelector<StoreState, PermissionsMap>(({ session }) => session.permissions);
    //console.log(permissionMap, 'permMAp');
    const visible: any = [];
    const nonvisible: any = [];

    function shouldBeVisible(entry: NavUtilItem) {
      //console.log(permissionMap[entry.path]?.view, entry, '---');
      if (permissionMap[entry.path]?.view === true) visible.push(entry.path);
      if (permissionMap[entry.path]?.view !== true) nonvisible.push(entry.path);
      return permissionMap[entry.path]?.view === true ? permissionMap[entry.path].view : false;
    }

    function getEntries() {
      //console.log(entries, '------');
      const data = [...entries]
        .filter((entry) => (isSubmenu(entry) ? entry.items.some(shouldBeVisible) : shouldBeVisible(entry)))
        .sort((a, b) => (a.rank || 0) - (b.rank || 0));
      //console.log(visible, nonvisible, 'visible-non');

      if (user?.role === 'ADMINISTRATOR') return data.slice(1);
      else {
        const removedElement = data.splice(1, 1);
        return data;
      }
    }

    function getSelectedItemKeys(path: string) {
      return entries
        .flatMap((entry) => (isSubmenu(entry) ? entry.items : [entry]))
        .filter((entry) => entry.path === path)
        .map((entry) => entry.key);
    }

    const clearNotifications = (e: any) => {
      setToSeen(true);
      setNotiCount(0);
      e.preventDefault();
      if (notiCount > 0) {
      }
    };

    /**
     * @name renderItem
     */
    const renderItem = ({ key, path, icon: Icon }: any) => {
      //console.log(path, 'item');
      return !nonvisible.includes(path) ? (
        <Menu.Item key={key} icon={Icon && <Icon />} onClick={() => forward(path as string)}>
          {t(`menu:${key}`)}
        </Menu.Item>
      ) : (
        ''
      );
    };

    /**
     * @name renderSubmenu
     */
    const renderSubmenu = ({ key, items, icon: Icon }: NavUtilSubmenu) => (
      <SubMenu key={key} title={t(`menu:${key}`)} icon={Icon && <Icon />} onTitleClick={() => toggleSubmenu(key)}>
        {items.map((item) => renderItem(item))}
      </SubMenu>
    );

    /**
     * @name renderEntry
     * @param entry
     */
    const renderEntry = (entry: any) => (isSubmenu(entry) ? renderSubmenu(entry) : renderItem(entry));

    const NoNotificationsDropDownMenu = (
      <Menu>
        <div style={{ padding: '15px' }}>
          <span style={{ fontFamily: 'DM Sans', fontWeight: '700', fontSize: '20px', color: '#8B8B8B' }}>
            NOTIFIKACIJE
          </span>
        </div>
        <Menu.Item key={'notikey'}>
          <div
            style={{
              overflowY: 'auto',
              height: '100%',
              width: '360px',
              background: '#FFFFFF',
              borderRadius: '5px',
            }}
          >
            <div>
              <>
                <div
                  style={{
                    borderBottom: '1px solid #F3F3F3',
                    borderRadius: '5px',
                    marginBottom: '10px',
                    marginRight: '10px',
                  }}
                >
                  <div style={{ color: 'black', fontFamily: 'DM Sans' }}>{t('common:No new notifications')}</div>
                </div>
              </>
            </div>
          </div>
        </Menu.Item>
      </Menu>
    );

    useEffect(() => {
      if (getuser !== undefined) {
        formEdit.setFieldsValue({
          role: getuser.data?.role,
          email: getuser.data.email,
          firstName: getuser.data.firstName,
          lastName: getuser.data.lastName,
          id: getuser.data.id,
          // facebook: getuser.data.facebook,
          // twitter: getuser.data.twitter,
          username: getuser.data.username,
          thumbnailUrl: getuser.data.thumbnailUrl,
        });
        setThumbnailUrl(getuser.data.thumbnailUrl);
      }
    }, [getuser]);

    function openDrawer(data: any) {
      refetchEmail();
      if (getuser !== undefined) {
        formEdit.setFieldsValue({
          role: data.role,
          email: data.email,
          firstName: getuser.data.firstName,
          lastName: getuser.data.lastName,
          id: getuser.data.id,
          // facebook: getuser.data.facebook,
          // twitter: getuser.data.twitter,
          username: getuser.data.username,
          thumbnailUrl: getuser.data.thumbnailUrl,
        });
        setThumbnailUrl(getuser.data.thumbnailUrl);
      }
      setIsDrawerVisible(true);
    }

    function closeDrawer() {
      setWarningType([]);
      setWarning(false);
      setThumbnailUrl(null);
      formEdit.resetFields();
      setIsDrawerVisible(false);
    }

    const closeEditImageModal = () => {
      setSelectedImage(undefined);
    };

    async function onFormSubmit() {
      const values = { ...formEdit.getFieldsValue(), thumbnailUrl: thumbnailUrl } as User;
      await mutateAsync(values)
        .then((resp: any) => {
          if (resp.errors) {
            setIsDrawerVisible(true);
            setWarningType(resp.errors);
            setWarning(true);
            message.error(t(`common:Invalid data`));
          } else {
            setIsDrawerVisible(false);
            setWarning(false);
            message.success(t(`common:Successfully Updated`, { resource: t('common:User') }));
            formEdit.resetFields();
            setFileList([]);
            setThumbnailUrl(null);
            logout();
          }
        })
        .catch((e) => {});
    }

    const handleUploadChange = (info: any) => {
      const { fileList, file } = info;
      if (file.percent === 100) {
        const image = fileList.find((i: UploadFile) => i.uid === file.uid);
        image.url = file.response.imageUrl;
        image.uid = file.response.id;
        image.description = file.response.description;
        image.caption = file.response.caption;
        image.source = file.response.source;
      }
      handleChange(fileList);
    };

    const handleChange = useCallback(
      (files: UploadFile[]) => {
        setFileList(files);

        if (files.every((f) => f.status === 'done')) {
          onChangeProp?.(files.map((f) => f.response as Image));
        }
      },
      [onChangeProp],
    );

    const customRequest = async (options: any) => {
      const { onSuccess = () => {}, onError = () => {} } = options;

      try {
        const { thumbnailUrl } = await usersService.createPhoto(options);
        setThumbnailUrl(thumbnailUrl);
        onSuccess({
          thumbnailUrl,
        });
      } catch (error: any) {
        onError(error);
      }
    };

    const attachData = (file: UploadFile) => {
      return new Promise<Record<string, any>>(async (resolve, reject) => {
        if (!file.url) {
          const base64String = await getBase64(file as unknown as Blob);
          file.url = base64String;
        }
        resolve(file);
      });
    };

    const beforeUpload = async (file: File, fileList: File[]) => {
      const modalPromise = new Promise<any>((resolve, reject) => {
        editImageModalPromise.current = { resolve, reject };
      });
      if (fileList.length > 1) {
        return Promise.resolve(true);
      }
      modalPromise.finally(closeEditImageModal);

      const fileExtended = file as unknown as FileExtended;
      const base64String = await getBase64(file);
      fileExtended.url = base64String;

      setSelectedImage({ isNewImage: true, image: fileExtended });
      return modalPromise;
    };

    const toggleLogoImageInput = () => {
      setLogoImageInput(!logoImageInput);
    };

    const useMediaQuery = (width: number) => {
      const [targetReached, setTargetReached] = useState(false);

      const updateTarget = useCallback((e) => {
        if (e.matches) {
          setTargetReached(true);
        } else {
          setTargetReached(false);
        }
      }, []);

      useEffect(() => {
        const media = window.matchMedia(`(max-width: ${width}px)`);
        media.addEventListener('change', updateTarget);
        // Check on mount (callback is not called until a change occurs)
        if (media.matches) {
          setTargetReached(true);
        }

        return () => media.removeEventListener('change', updateTarget);
      }, []);

      return targetReached;
    };

    const isBreakpoint = useMediaQuery(768);

    // useEffect(() => {
    //   if (isBreakpoint) {
    //     collapse(true);
    //   }
    // }, [isBreakpoint]);

    // RENDER
    return (
      <Layout>
        {!isBreakpoint && (
          <Sider
            className="site-sider"
            theme="light"
            collapsible={!isBreakpoint}
            collapsed={isCollapsed}
            onCollapse={onCollapse}
            style={{ background: '#333' }}
          >
            <div className="site-logo-container">
              <img
                alt="logo"
                src={isCollapsed ? AvazLogoSmall : AvazLogo}
                className={classnames({
                  'site-logo': true,
                  'site-logo--collapsed': isCollapsed,
                })}
              />
            </div>
            <Menu
              theme="light"
              mode="inline"
              openKeys={activeSubmenuKeys}
              selectedKeys={getSelectedItemKeys(history.location.pathname)}
              className={'menuScrol'}
              style={{ backgroundColor: '#333', color: 'white' }}
            >
              <Menu.Item
                key={'homeItem'}
                onClick={() => (window.location.href = '/')}
                id={'homeItem'}
                icon={<HomeOutlined style={{ paddingLeft: '10px', color: 'white' }} />}
                style={!isCollapsed ? { paddingLeft: '15px', color: 'white' } : { paddingLeft: '21px', color: 'white' }}
                className={`${window.location.pathname === '/' ? 'borderedSelectedItem' : ''}`}
              >
                Početna
              </Menu.Item>
              <Menu.Item
                key={'sitesItem'}
                onClick={() => (window.location.href = '/sites')}
                id={'sitesItem'}
                icon={<RadarChartOutlined style={{ paddingLeft: '10px', color: 'white' }} />}
                style={!isCollapsed ? { paddingLeft: '15px', color: 'white' } : { paddingLeft: '21px', color: 'white' }}
                className={`${window.location.pathname === '/sites' ? 'borderedSelectedItem' : ''}`}
              >
                Sajtovi
              </Menu.Item>
              {getEntries().map(renderEntry)}
            </Menu>
            <Menu>
              <Menu.Item
                key={'notix'}
                title=""
                style={{
                  background: '#333',
                  textDecorationColor: 'white',
                  display: 'flex',
                  justifyContent: 'center',
                  position: 'absolute',
                  bottom: 0,
                  marginBottom: '60px',
                  width: '100%',
                  zIndex: 999,
                }}
                onClick={() => openDrawer(user as any)}
              >
                <Avatar icon={<UserOutlined />} />
                <span style={!isCollapsed ? { paddingLeft: '14px', color: 'white' } : { display: 'none' }}>
                  {user?.name}
                </span>
              </Menu.Item>
              <Menu.Item
                className="logoutitem"
                key={'notiKey3'}
                onClick={logout}
                id={'logout'}
                style={{ background: '#333', color: 'white' }}
                icon={
                  <LogoutOutlined
                    style={
                      !isCollapsed ? { paddingLeft: '16px', color: 'white' } : { paddingLeft: '10px', color: 'white' }
                    }
                  />
                }
              >
                {t(`common:Logout`)}
              </Menu.Item>
            </Menu>
          </Sider>
        )}

        {isBreakpoint && isMobileOpen && (
          <Sider
            className="site-sider-mobile"
            theme="light"
            collapsible={false}
            // collapsed={isCollapsed}
            onCollapse={onCollapse}
            style={{ background: '#333' }}
          >
            {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
            <div
              className="site-logo-mobile"
              onClick={() => {
                setIsMobileOpen(!isMobileOpen);
                dispatch(closeAllSubmenusAction());
              }}
            >
              <img
                alt="logo"
                src={isCollapsed ? AvazLogoSmall : AvazLogo}
                className={classnames({
                  'site-logo': true,
                  'site-logo--collapsed': isCollapsed,
                })}
              />
            </div>
            <Menu
              theme="light"
              mode="inline"
              openKeys={activeSubmenuKeys}
              selectedKeys={getSelectedItemKeys(history.location.pathname)}
              className={'menuScrol'}
              style={{ backgroundColor: '#333', color: 'white' }}
            >
              <Menu.Item
                key={'homeItem'}
                onClick={() => (window.location.href = '/')}
                id={'homeItem'}
                icon={<HomeOutlined style={{ paddingLeft: '10px', color: 'white' }} />}
                style={!isCollapsed ? { paddingLeft: '15px', color: 'white' } : { paddingLeft: '21px', color: 'white' }}
              >
                Početna
              </Menu.Item>
              {getEntries().map(renderEntry)}
            </Menu>
            <Menu>
              <Menu.Item
                key={'notix'}
                title=""
                style={{
                  background: '#333',
                  textDecorationColor: 'white',
                  display: 'flex',
                  justifyContent: 'center',
                  position: 'absolute',
                  bottom: 0,
                  marginBottom: '60px',
                  width: '100%',
                  zIndex: 999,
                }}
                onClick={() => openDrawer(user as any)}
              >
                <Avatar icon={<UserOutlined />} />
                <span style={!isCollapsed ? { paddingLeft: '14px', color: 'white' } : { display: 'none' }}>
                  {user?.name}
                </span>
              </Menu.Item>
              <Menu.Item
                className="logoutitem"
                key={'notiKey3'}
                onClick={logout}
                id={'logout'}
                style={{ background: '#333', color: 'white' }}
                icon={
                  <LogoutOutlined
                    style={
                      !isCollapsed ? { paddingLeft: '16px', color: 'white' } : { paddingLeft: '10px', color: 'white' }
                    }
                  />
                }
              >
                {t(`common:Logout`)}
              </Menu.Item>
            </Menu>
          </Sider>
        )}

        <Layout
          className={`${isBreakpoint ? 'site-layout-mobile' : 'site-layout'} ${
            isCollapsed ? 'site-layout--closed' : ''
          }`}
        >
          {/* <Header className={`${isBreakpoint ? 'layout-header-mobile' : ''} site-layout__header`}>
            <div style={{ display: 'flex', flexDirection: 'row', marginTop: '20px', float: 'right' }}>
              {isBreakpoint && (
                // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
                <div
                  style={{ float: 'right' }}
                  className="site-logo-mobile-closed"
                  onClick={() => {
                    setIsMobileOpen(!isMobileOpen);
                    dispatch(closeAllSubmenusAction());
                  }}
                >
                  <img alt="logo" src={AvazLogoSmall} className="site-logo-mobile-mini" />
                </div>
              )}

              {user?.role && user.role !== 'JOURNALIST' && (
                <div>
                  <button
                    onClick={() => {
                      window.location.href = '/article/new';
                    }}
                    className={`${isBreakpoint ? 'nav-create-article-mobile' : 'nav-create-article'}`}
                  >
                    + {t(`common:Create article`)}
                  </button>
                </div>
              )}
            </div>
          </Header> */}
          <Content className="site-layout__content relative">
            <div className="site-layout-background">{children}</div>
          </Content>
        </Layout>

        {/* ================ */}
        {/* EDIT USER DRAWER */}
        {/* ================ */}

        <Drawer
          width="500"
          placement="right"
          onClose={closeDrawer}
          visible={isDrawerVisible}
          title={
            <div>
              <UserOutlined style={{ marginRight: '5px', color: 'white' }} />
              {<span>{t('users:Edit user')}</span>}
            </div>
          }
          footer={[
            <div style={{ float: 'right' }}>
              <Button style={{ marginRight: '10px' }} key="back" disabled={isEditing} onClick={closeDrawer}>
                {t('common:Cancel')}
              </Button>

              <Button
                key="submit"
                type="primary"
                disabled={isEditing}
                loading={isEditing}
                icon={<SaveOutlined />}
                onClick={() => {
                  formEdit.submit();
                }}
              >
                {t('common:Save')}
              </Button>
            </div>,
          ]}
        >
          {/* ================== */}
          {/* EDIT USER FORM */}
          {/* ================== */}
          <div>
            <Form
              form={formEdit}
              labelCol={{ span: 6 }}
              wrapperCol={{ span: 18 }}
              layout="horizontal"
              size="middle"
              onFinish={onFormSubmit}
            >
              {warning && (
                <Form.Item name="warningMessage" style={{ fontFamily: 'DM Sans' }}>
                  {warningType.map((role: any, i: any) => (
                    <>
                      <span key={`role-${i}`}>{t('users:' + capitalizeFirstLetter(role))}</span>
                      <br></br>
                    </>
                  ))}
                  <br></br>
                  <label style={{ color: 'red' }}>{t('users:Wrong credentials')}</label>
                </Form.Item>
              )}
              <Form.Item name="id" label="ID">
                <Input disabled readOnly />
              </Form.Item>
              <Form.Item
                name="firstName"
                rules={[{ required: true, message: t('article:First name required') }]}
                label={t('users:First Name')}
              >
                <Input />
              </Form.Item>
              <Form.Item
                name="lastName"
                rules={[{ required: true, message: t('article:Last name required') }]}
                label={t('users:Last Name')}
              >
                <Input />
              </Form.Item>
              <Form.Item
                name="email"
                rules={[{ required: true, message: t('article:Email required') }]}
                label={t('users:Email')}
              >
                <Input />
              </Form.Item>
              {/* <Form.Item
                name="facebook"
                rules={[{ required: false, message: t('article:Facebook required') }]}
                label={t('users:Facebook')}
              >
                <Input />
              </Form.Item>
              <Form.Item
                name="twitter"
                rules={[{ required: false, message: t('article:Twitter required') }]}
                label={t('users:Twitter')}
              >
                <Input />
              </Form.Item> */}
              <Form.Item
                name="username"
                rules={[{ required: true, message: t('article:Username required') }]}
                label={t('users:Username')}
              >
                <Input />
              </Form.Item>
              <Form.Item
                name="role"
                rules={[{ required: true, message: t('article:Role required') }]}
                label={t('users:Role')}
              >
                <Select disabled={true}>
                  {roles.map((role) => (
                    <Option key={`role-${role.name}`} value={role.name}>
                      {capitalizeFirstLetter(role.name)}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item name="password" label={t('users:New password')}>
                {/* TODO: Add info popover */}
                <Input.Password />
              </Form.Item>

              {/* <Form.Item name="userImage" label={t('users:User image')}>
                <div className="userRow">
                  <DndProvider backend={HTML5Backend}>
                    <Upload
                      listType={isCardMode ? 'picture-card' : 'picture'}
                      fileList={fileList}
                      onChange={handleUploadChange}
                      customRequest={customRequest}
                      data={attachData}
                      beforeUpload={beforeUpload}
                      maxCount={maxCount}
                      multiple={multiple}
                    >
                      {isCardMode ? (
                        <div>
                          <PlusOutlined />
                          <div style={{ marginTop: 8 }}>{t('images:upload')}</div>
                        </div>
                      ) : (
                        <Button icon={<UploadOutlined />}>{t('images:upload')}</Button>
                      )}
                    </Upload>
                  </DndProvider>
                </div>
                {thumbnailUrl && (
                  <div className="currentUser">
                    <Picture src={thumbnailUrl} />
                  </div>
                )}
              </Form.Item> */}
            </Form>
          </div>
        </Drawer>
      </Layout>
    );
  },
);
