import { HttpClient } from 'helpers/http';

import { IObitaryFormatService, ObitauryFormats } from 'types/services/obituaryFormat';

export class ObituaryFormatService implements IObitaryFormatService {
  constructor(private client: HttpClient) {}

  API = {
    obitauryFormat: '/api/obituaryFormat/get-obituary-formats',
  };

  getObitauryFormats = () => {
    let url = `${this.API.obitauryFormat}`;

    return this.client.get<ObitauryFormats>(url);
  };
}
