import cx from 'classnames';
import { useState, useCallback, useEffect } from 'react';
import { Draggable } from 'react-beautiful-dnd';
import { FaTimes, FaCheck } from 'react-icons/fa';
import { GiMove } from 'react-icons/gi';
import { GrEdit } from 'react-icons/gr';

import { Image } from 'types/services/images';

import { ExtendedBlockType } from './BlocksEditor';
import EmbeddedBlock from './EmbeddedBlock';
import ImagesBlock from './ImagesBlock';
import QuoteBlock from './QuoteBlock';
import RelatedArticleBlock from './RelatedArticleBlock';
import TextBlock from './TextBlock';
import VideoBlock from './VideoBlock';

const blockFactory = ({
  block,
  currentContent,
  images,
  articleId,
  isEditing,
  highlighting,
  onHighLightCalled,
  index,
  onChange,
  onImagesChange,
  onThumnailSelect,
}: {
  block: ExtendedBlockType;
  currentContent: any;
  images: Image[];
  articleId?: number;
  isEditing: boolean;
  highlighting: boolean;
  onHighLightCalled?: Function;
  index?: number;
  onChange: (block: ExtendedBlockType) => void;
  onImagesChange: (images: Image[]) => void;
  onThumnailSelect?: (image: Image) => void;
}) => {
  switch (block.type) {
    case 'text':
      return (
        <TextBlock
          content={block.content}
          onChange={onChange}
          highlighting={highlighting}
          onHighLightCalled={onHighLightCalled}
        />
      );
    case 'images':
      return (
        <ImagesBlock
          images={images}
          content={currentContent}
          isEditing={isEditing}
          index={index}
          onChange={onChange}
          onImagesChange={onImagesChange}
          onThumnailSelect={onThumnailSelect}
        />
      );
    case 'video':
      return <VideoBlock content={currentContent} isEditing={isEditing} onChange={onChange} />;
    case 'quote':
      return <QuoteBlock content={block.content} onChange={onChange} />;
    case 'related':
      return (
        <RelatedArticleBlock content={currentContent} onChange={onChange} isEditing={isEditing} articleId={articleId} />
      );
    case 'embedded':
      return <EmbeddedBlock content={currentContent} onChange={onChange} isEditing={isEditing} />;
    case 'lineBreak':
      return <hr style={{ marginTop: '40px' }} />;
    default:
      return null;
  }
};

interface BlockComponentProps {
  block: ExtendedBlockType;
  index: number;
  images: Image[];
  articleId?: number;
  highlighting?: boolean;
  isEditing: boolean;
  draggable?: boolean;
  onHighLightCalled?: Function;
  onImagesChange: (images: Image[]) => void;
  onSetEditing?: (index: number, isEditing: boolean) => void;
  onDelete?: (index: number) => void;
  onThumnailSelect?: (image: Image) => void;
}

export const isEditable = (type: string) => {
  return ['images', 'video', 'related', 'embedded'].includes(type);
};

const BlockComponent = ({
  block,
  index,
  images,
  articleId,
  isEditing,
  draggable = true,
  highlighting = false,
  onHighLightCalled,
  onImagesChange,
  onSetEditing,
  onDelete,
  onThumnailSelect,
}: BlockComponentProps) => {
  const [content, setContent] = useState(block.content);

  useEffect(() => {
    setContent(block.content);
  }, [block]);

  const getItemStyle = (isDragging: boolean, draggableStyle: any) => ({
    userSelect: 'none',
    color: '#b3b3b1',
    fontSize: '20px',
    ...draggableStyle,
  });

  const handleXClick = () => {
    if (isEditing) {
      onSetEditing?.(index, false);
    } else {
      onDelete?.(index);
    }
  };

  const handleChange = useCallback(
    (newContent: any) => {
      const isRelatedArticleBlock = block.type === 'related';
      //save the images, video or embedded blocks as soon as they are changed so they won't remain empty
      //if they aren't confirmed and article is saved

      if (isRelatedArticleBlock) {
        onSetEditing?.(index, false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [block, index],
  );

  const confirmEdit = () => {
    onSetEditing?.(index, false);
  };

  const blockContent = () => {
    return (
      <>
        <div className="block-btns">
          {!isEditing && draggable && <GiMove style={{ color: 'black' }} className="visible" />}
          {isEditable(block.type) && !isEditing && (
            <GrEdit style={{ color: 'black' }} onClick={() => onSetEditing?.(index, true)} />
          )}
          {isEditable(block.type) && isEditing && (
            <FaCheck style={{ color: 'black' }} className="confirm-block-btn" onClick={confirmEdit} />
          )}
          {(isEditing || onDelete) && (
            <FaTimes style={{ color: 'black' }} className="remove-block-btn" onClick={handleXClick} />
          )}
        </div>

        {blockFactory({
          block,
          currentContent: content,
          images,
          articleId,
          isEditing,
          highlighting,
          onHighLightCalled,
          index,
          onChange: handleChange,
          onImagesChange,
          onThumnailSelect,
        })}
      </>
    );
  };

  return draggable ? (
    <Draggable key={block.key} draggableId={block.key.toString()} index={index} isDragDisabled={isEditing}>
      {(provided, snapshot) => (
        <div
          className={cx('relative block-wrapper cursor', { editing: isEditing })}
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
        >
          {blockContent()}
        </div>
      )}
    </Draggable>
  ) : (
    <div className={cx('relative block-wrapper', { editing: isEditing })}>{blockContent()}</div>
  );
};

export default BlockComponent;
