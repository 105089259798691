import { FileDoneOutlined } from '@ant-design/icons';

import { withNavigation } from 'containers/Navigation';

import { ActivityLogDetail } from 'routes/statistics/ActivityLogDetail';

import { IRoute, Submenu } from 'types/common';

import { ActivityLogs } from '../statistics/ActivityLogs';

export const routes: IRoute[] = [
  {
    component: withNavigation(ActivityLogs),
    exact: true,
    key: 'Activity logs',
    path: '/activity-log',
    isPrivate: true,
  },
  {
    component: withNavigation(ActivityLogDetail),
    exact: true,
    key: 'activity-log',
    path: '/activity-log/:id',
    isPrivate: true,
  },
];

export const submenu: Submenu = {
  key: 'Izvještaji',
  icon: FileDoneOutlined,
  rank: 5,
  items: routes.slice(0, 1).map((route) => ({ key: route.key, path: route.path as string })),
};
