import { registerEntries } from 'actions/index';

import { routes as articlesRoutes, submenu as articlesSubmenu, item as articlesList } from './articles/routes';
import { routes as blockRoutes, submenu as blockSubmenu } from './block/routes';
import {
  route as homeRoute,
  item as homeItem,
  previewItem,
  previewRoute,
  analyticsRoute,
  analyticsTotal,
  routeSites,
  itemSites,
} from './home/route';
import { route as loginRoute } from './login/route';
import { routes as reportsRoutes, submenu as reportsSubmenu } from './reports/routes';
import { routes as forgotPasswordRoutes } from './resetPassword/routes';
import { routes as usersRoutes, submenu as usersSubmenu } from './users/routes';

export const routes = [
  homeRoute,
  routeSites,
  loginRoute,
  previewRoute,
  analyticsRoute,
  analyticsTotal,
  ...blockRoutes,
  ...usersRoutes,
  ...forgotPasswordRoutes,
  ...articlesRoutes,
  ...reportsRoutes,
];

// REGISTER ROUTES IN SIDEBAR
setTimeout(() => {
  window.store.dispatch(
    registerEntries([
      homeItem,
      usersSubmenu,
      articlesSubmenu,
      reportsSubmenu,
      previewItem,
      blockSubmenu,
      itemSites,
      articlesList,
    ]),
  );
});
