import { List, Input } from 'antd';
import throttle from 'lodash/throttle';
import { memo, useState, useRef, useEffect } from 'react';
import { useQuery } from 'react-query';

import { articlesService } from 'config/services';

import { HttpError } from 'helpers/http';

import { RelatedArticleBlock, PaginatedArticles, Article } from 'types/services/articles';

import RelatedArticleCard from './RelatedArticleCard';

const { Search } = Input;

interface RelatedArticleBlockInteface {
  content: RelatedArticleBlock['content'];
  articleId?: number;
  isEditing: boolean;
  onChange: (content: any) => void;
}

const RelatedArticleBlockComponent = ({ content, articleId, onChange, isEditing }: RelatedArticleBlockInteface) => {
  const [searchText, setSearchText] = useState('');

  const { isLoading, data, refetch } = useQuery<PaginatedArticles, HttpError>(
    ['getArticlesByTitle', searchText],
    () => articlesService.list({ page: 1, perPage: 10 }, { title: [searchText], status: ['approved'] }),
    {
      enabled: !!searchText,
    },
  );

  const throttledRefetch = useRef(throttle(refetch, 500));

  useEffect(() => {
    if (searchText) {
      throttledRefetch.current();
    }
  }, [searchText]);

  const articles = data?.data || [];

  const handleArticleSelect = (selectedArticleId: number) => {
    let clickedArticle = articles.filter((i) => i.id === selectedArticleId);
    let currentArticle: RelatedArticleBlock['content'] = { id: -1 };
    if (clickedArticle.length > 0) {
      currentArticle.id = clickedArticle[0].id;
      currentArticle.publicationTime = clickedArticle[0].publicationTime;
      currentArticle.stamp = clickedArticle[0].stamp;
      if (clickedArticle[0].thumbnailImage !== null) {
        currentArticle.thumbnailImage = clickedArticle[0].thumbnailImage;
      }
      currentArticle.title = clickedArticle[0].title;
    }

    onChange(currentArticle);
  };

  const onSearchChange = (e: any) => {
    setSearchText(e.target.value);
  };

  return isEditing ? (
    <div className="editing-container">
      <Search className="images-search" enterButton value={searchText} onChange={onSearchChange} loading={isLoading} />
      <List
        size="small"
        loading={isLoading}
        itemLayout="horizontal"
        dataSource={articles?.filter((art) => art.id !== articleId)}
        renderItem={(article) => (
          <List.Item onClick={() => handleArticleSelect(article.id)}>
            <List.Item.Meta
              avatar={
                <img src={article?.thumbnailImage?.thumbnailUrl} alt="article thumbnail" style={{ width: '100px' }} />
              }
              title={<span style={{ fontSize: '12px' }}>{article?.stamp}</span>}
              description={<span style={{ fontSize: '16px', color: 'black' }}>{article?.title}</span>}
            />
          </List.Item>
        )}
      />
    </div>
  ) : (
    <RelatedArticleCard article={content as unknown as Article} />
  );
};

export default memo(RelatedArticleBlockComponent);
