//* █▀█ █▄▀
//* █▄█ █░█

export type Image = {
  description: string;
  createdAt: string;
  updatedAt: string;
  //deletedAt: null;
  id: number;
  imageUrl: string;
  thumbnailUrl: string;
  squareThumbnailUrl: string;
  landscape: string;
  mimeType: string;
  source: string;
};

export interface Article {
  createdAt: string;
  updatedAt: string;
  //deletedAt: null;
  id: number;
  title: string;
  subtitle: string;
  stamp: string;

  slug: string;
  isPublished: boolean;
  blockPosition: number;
  publicationTime: string;

  commentsCount: number;
  commentsDisabled: boolean;
  hasGallery: boolean;
  hasVideo: boolean;

  thumbnailImage: Image;
  status?: string;
  moreFromWeb?: any;
  marketing?: any;
  latest?: any;
  popular?: any;

  author: {
    createdAt: string;
    updatedAt: string;
    //deletedAt: null;
    id: number;
    username: string;
    email: string;
    firstName: string;
    lastName: string;
    password: string;
    role: string;
    image: any;
    thumbnailUrl: string;
  };

  legacy_author: any;

  hasAds: boolean;
  thumbnailUrl: string;
  color?: string;
  url?: string;
  isAds?: boolean;
}

export type CardDetailsProps = {
  link: string;
  stamp: string;
  stampColor?: string;
  stampClassNames?: string;
  timeAgo: string;
  hideTimeAgo?: boolean;
  timeAgoPosition?: 'bottom-left' | 'top-right';
  title: string;
  titleClassNames?: string;
  stampVisibility?: boolean;
  isRelated?: boolean;
  lc3?: boolean;
  isLinked?: boolean;
};

type Props = {
  article: Article;
  override?: Partial<CardDetailsProps>;
};

export const articleToCardDetails = ({
  article,
  override,
}: Props): CardDetailsProps & {
  hasGallery?: boolean;
  hasVideo?: boolean;
} => {
  const { stamp, title, createdAt, publicationTime, hasGallery, hasVideo, color, url } = article;

  const stampColor = override?.stampColor || color;

  let link = url;
  if (link === undefined) {
    link = '';
  }

  return {
    link,
    stampColor,
    timeAgo: publicationTime || createdAt,
    stamp,
    title,
    hasGallery,
    hasVideo,
  };
};
