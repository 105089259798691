import { FileTextOutlined } from '@ant-design/icons';

import { withNavigation } from 'containers/Navigation';

import { IRoute, Item, Submenu } from 'types/common';

import { ArticlePage } from './Article';
import { Articles } from './Articles';
import { ArticleSliderPage } from './ArticleSlider';

export const routes: IRoute[] = [
  {
    component: withNavigation(ArticlePage),
    exact: true,
    key: 'create-article',
    path: '/article/new',
    isPrivate: true,
  },
  {
    component: withNavigation(Articles),
    exact: true,
    key: 'list-articles',
    path: '/articles',
    isPrivate: true,
  },
  {
    component: withNavigation(ArticlePage),
    exact: true,
    key: 'article',
    path: '/article/:id',
    isPrivate: true,
  },
  {
    component: ArticleSliderPage,
    exact: true,
    key: 'article',
    path: '/widget/:blockId/:takeNum/:dark',
    isPrivate: false,
  },
];

export const submenu: Submenu = {
  key: 'Članci',
  icon: FileTextOutlined,
  rank: 2,
  items: routes.slice(0, routes.length - 2).map((route) => ({ key: route.key, path: route.path as string })),
};

export const item: Item = {
  key: 'list-articles',
  path: '/articles',
  icon: FileTextOutlined,
  rank: 1,
};
