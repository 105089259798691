import { UserSwitchOutlined } from '@ant-design/icons';

import { withNavigation } from 'containers/Navigation';

import { IRoute, Submenu } from 'types/common';

import { ListRoles } from './ListRoles';
import { ListUsers } from './ListUsers';

export const routes: IRoute[] = [
  {
    component: withNavigation(ListUsers),
    exact: true,
    key: 'list-users',
    path: '/users/list',
    isPrivate: true,
  },
  {
    component: withNavigation(ListRoles),
    exact: true,
    key: 'list-roles',
    path: '/users/roles',
    isPrivate: true,
  },
];

export const submenu: Submenu = {
  key: 'users',
  icon: UserSwitchOutlined,
  rank: 6,
  items: routes.map((route) => ({ key: route.key, path: route.path as string })),
};
