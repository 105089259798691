import { RouteProps } from 'react-router-dom';
import { Merge } from 'type-fest';

export type GenericFunction<T = any> = (...args: any[]) => T;

export type PlainObject<T = any> = Record<string, T>;
export interface Status {
  ERROR: 'error';
  IDLE: 'idle';
  READY: 'ready';
  RUNNING: 'running';
  SUCCESS: 'success';
}

export interface IRoute extends RouteProps {
  key: string;
  isPrivate?: boolean;
}

export interface PaginationParams<T = any> {
  page: number;
  perPage: number;
  filters?: T;
  admin?: boolean;
  total?: number;
}

export interface ByBlockParams {
  takeNum: string;
  blockId: string;
}

export type PatchParams<T> = Merge<{ id: number }, Partial<T>>;

export type Pagination = {
  page: number;
  perPage: number;
  total: number;
};
export interface Paginated<T> {
  data: T[];
  pagination: Pagination;
}
export interface PaginatedStats<T> {
  data: T[];
  pagination: Pagination;
  dateOne: Date;
  dateTwo: Date;
}
export interface AuthorStats<T> {
  data: T[];
}

export interface Response<T> {
  data: T;
}

export interface TimeStampedEntity {
  id: number;
  createdAt: string;
  updatedAt: string;
  deletedAt?: string;
}

export type Item = { key: string; path: string; rank?: number; icon?: any };
export type Submenu = { key: string; items: Item[]; rank?: number; icon?: any };

export function isSubmenu(entry: Item | Submenu): entry is Submenu {
  return (entry as Submenu).items !== undefined;
}
