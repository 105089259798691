import { Resources, AvailableActions, Roles } from 'types/permissions';

const { USERS, IMAGES, ROLES, PERMISSIONS, RESOURCES, ARTICLES, ARTICLE_BLOCKS } = Resources;
const { LIST, GET, REMOVE, UPDATE, UPDATE_LIST, CREATE, MERGE, EDIT_LAYOUT, APPROVE } = AvailableActions;
const { ADMINISTRATOR, JOURNALIST } = Roles;

const permissionsMap: {
  [key: string]: { [key: string]: string[][] | string[] };
} = {
  '/articles': {
    roles: [ADMINISTRATOR, JOURNALIST],
    view: [[ARTICLES, LIST]],
    update: [
      [ARTICLES, GET],
      [ARTICLES, UPDATE],
      [USERS, LIST],
    ],
    delete: [[ARTICLES, REMOVE]],
    approve: [[ARTICLES, APPROVE]],
    create: [ARTICLES, CREATE],
  },
  '/article/new': {
    roles: [ADMINISTRATOR],
    view: [
      [ARTICLES, GET],
      [ARTICLES, CREATE],
      [ARTICLES, LIST],
      [USERS, LIST],
    ],
    create: [ARTICLES, CREATE],
  },
  '/article/:id': {
    roles: [ADMINISTRATOR],
  },

  // '/activity-log': {
  //   roles: [ADMINISTRATOR, EDITOR],
  //   view: [
  //     [OBITUARIES, GET],
  //     [ARTICLE_BLOCKS, UPDATE],
  //     [ARTICLE_BLOCKS_TYPES, LIST],
  //     [CATEGORIES, LIST],
  //   ],
  // },
  '/users/list': {
    roles: [ADMINISTRATOR],
    view: [
      [USERS, LIST],
      [ROLES, LIST],
    ],
    update: [
      [USERS, UPDATE],
      [ROLES, LIST],
    ],
    delete: [[USERS, REMOVE]],
  },
  /* '/report': {
    roles: [ADMINISTRATOR, JOURNALIST],
    view: [
      [OVERVIEW, LIST],
      [ROLES, LIST],
    ],
    update: [
      [OVERVIEW, UPDATE],
      [ROLES, LIST],
    ],
    delete: [[OVERVIEW, REMOVE]],
  }, */
  '/block/index': {
    roles: [ADMINISTRATOR],
    view: [
      [ARTICLE_BLOCKS, LIST],
      [ARTICLES, LIST],
    ],
    update: [
      [USERS, UPDATE],
      [ROLES, LIST],
    ],
    delete: [[USERS, REMOVE]],
  },
  '/block/article': {
    roles: [ADMINISTRATOR],
    view: [
      [USERS, LIST],
      [ROLES, LIST],
    ],
    update: [
      [USERS, UPDATE],
      [ROLES, LIST],
    ],
    delete: [[USERS, REMOVE]],
  },
  '/users/roles': {
    roles: [ADMINISTRATOR],
    view: [[ROLES, LIST]],
    update: [
      [ROLES, LIST],
      [RESOURCES, LIST],
      [PERMISSIONS, LIST],
      [PERMISSIONS, UPDATE],
    ],
  },
};

permissionsMap['/article/:id'].view = permissionsMap['/articles'].update;
permissionsMap['/article/:id'].approve = permissionsMap['/articles'].approve;
permissionsMap['/article/:id'].update = permissionsMap['/articles'].update;

export default permissionsMap;
