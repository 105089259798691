import { Button } from 'antd';
import classNames from 'classnames';
import { useState } from 'react';

export const Header = () => {
  const [search, setSearch] = useState('');
  const [isSearchOpen, toggleSearch] = useState(false);
  type ContainerProps = {
    classNames?: string;
    isAvazTv?: boolean;
    width?: string;
    height?: string;
  };
  const Search = ({ classNames = 'sWH' }: ContainerProps) => (
    <svg
      className={classNames}
      fill="none"
      stroke="currentColor"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
      ></path>
    </svg>
  );

  const Close = ({ classNames = 'sWH' }: ContainerProps) => (
    <svg
      className={classNames}
      fill="none"
      stroke="currentColor"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12"></path>
    </svg>
  );
  return (
    <header className="header-copy">
      {/* NAVBAR */}
      <div className="navbar-header" style={{ background: '#333' }}>
        ​{/* NAVBAR__LOGO/SEARCH */}
        <div className="navbar-logo">
          {/* NAVBAR__LOGO */}
          <div className={classNames('navbar-logo-img', isSearchOpen && 'navbar-logo-im')}>
            <a href={'/articles'}>
              <img alt="Avaz Logo" src="/gismo-header.png" style={{ height: '45px', width: '140px' }} />
            </a>
          </div>
          <div className="left-part">
            <div style={{ marginRight: '29.5px' }}></div>
          </div>
          {/* NAVBAR__SEARCH */}
          {/* <form
            action="/smrtovnice"
            className={classNames('navbar-search', !isSearchOpen && 'navbar-searc')}
            style={{
              maxWidth: '940px',
              marginLeft: 'auto',
              marginRight: 'auto',
            }}
          >
            <button
              type="submit"
              className={classNames('search-btnMini', !isSearchOpen && 'navbar-logo-im')}
              onClick={() => {
                toggleSearch(true);
              }}
            >
              <Search classNames="search-componentMini" />
            </button>
            <input
              name="q"
              value={search}
              type="text"
              minLength={3}
              onKeyPress={(e: any) => {
                if (e.target.value.length < 3) {
                  e.target.setCustomValidity('Molimo unesite više od 3 karaktera!');
                } else {
                  e.target.setCustomValidity('');
                }
              }}
              placeholder={'Pretražite...'}
              className="input-search"
              onChange={(e) => setSearch(e.target.value)}
              id="text-search"
            ></input>
          </form> */}
          {/* <div className={'right-part'}>
            <button
              type="submit"
              className={classNames('search-btn', isSearchOpen && 'navbar-logo-im')}
              onClick={() => {
                toggleSearch(true);
              }}
            >
              <Search classNames="search-component" />
            </button>
            <button
              type="submit"
              className={classNames('close-btn', !isSearchOpen && 'close-btn-hide')}
              onClick={() => {
                toggleSearch(false);
              }}
            >
              <Close classNames="close-component"></Close>
            </button>
          </div> */}
        </div>
      </div>
    </header>
  );
};
