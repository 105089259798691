import { Pie, PieConfig } from '@ant-design/plots';
import { Empty, Spin } from 'antd';
import { useMemo } from 'react';
export const PieChart = (response: any) => {
  let allresponse = [
    { title: 'Avaz', value: 10, color: '#ED1C24' },
    { title: 'Azra', value: 5, color: '#339933' },
    { title: 'Express', value: 4, color: '#8B1E8A' },
    { title: 'Klix', value: 2, color: '#F6C543' },
    { title: 'Smrtovnice', value: 1, color: '#5895C2' },
  ];
  const data = useMemo(() => {
    const data = [];
    //let totalCount = 0;
    // if (response.props !== undefined) {
    //   for (let i = 0; i < allresponse.length; i++) {
    //     for (let j = 0; j < response.props.data.length; j++) {
    //       if (response.props.data[j].title === allresponse[i].title) {
    //         allresponse[i].value = response.props.data[j].value;
    //       }
    //     }
    //   }
    //   // for (let i = 0; i < response.props.data.length; i++) {
    //   //   data[i].title += ' ' + ((Number(data[i].value) / totalCount) * 100).toFixed(1) + '%';
    //   // }
    // }
    if (response.props !== undefined) {
      for (let i = 0; i < response.props.length; i++) {
        data.push({
          title: response.props[i].name,
          value: Number(response.props[i].value),
          modifiedTitle: '',
        });
        //totalCount += Number(response.props.data[i].value);
      }
      // } else {
      // dodani mock podaci, nema potrebe uopste else stavljati, ako response bude prazan prikazace se Nema podataka + slika
      /* for (let i = 0; i < allresponse.length; i++) {
        data.push({ title: allresponse[i].title, value: Number(allresponse[i].value), modifiedTitle: '' });
      } */
    }
    return data;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [response]);
  const config: PieConfig = {
    animation: {
      update: false,
      enter: false,
      leave: false,
      appear: false,
    },
    appendPadding: 10,
    data,
    legend: {
      layout: 'horizontal',
      position: 'bottom',
      flipPage: false,
      content: ({ current }: any) => `${current} + 100`,
    },
    tooltip: {
      //showContent: false,
    },
    angleField: 'value',
    colorField: 'title',
    color: [
      '#ED1C24',
      '#EE2F36',
      '#F04248',
      '#F1555A',
      '#F3686D',
      '#F47B7F',
      '#F68E91',
      '#F7A1A4',
      '#F8B4B6',
    ] /*({ title }) => {
      for (let i = 0; i < allresponse.length; i++) {
        //title.substring(0, title.indexOf(' ')) je islo umjesto title
        if (title === allresponse[i].title) return allresponse[i].color;
      }
    },*/,
    radius: 0.9,
    label: {
      type: 'inner',
      offset: '-30%',
      content: ({ percent }) => `${(percent * 100).toFixed(1)}%`,
      style: {
        fontSize: 14,
        textAlign: 'center',
      },
    },
    interactions: [
      {
        type: 'element-active',
      },
      {
        type: 'legend-filter',
        enable: false,
      },
    ],
  };
  return <>{data && data.length > 0 ? <Pie {...config} /> : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />}</>;
};

export const PieChartTotal = (totalResponse: any) => {
  let allresponseTotal = [
    { title: 'Avaz', value: 5, color: '#527A5F' },
    { title: 'Azra', value: 10, color: '#7C9082' },
    { title: 'Express', value: 1, color: '#A7A284' },
    { title: 'Klix', value: 2, color: '#373F51' },
    { title: 'Smrtovnice', value: 4, color: '#000000' },
  ];
  const data = useMemo(() => {
    const data = [];
    //let totalCount = 0;
    // if (response.props !== undefined) {
    //   for (let i = 0; i < allresponse.length; i++) {
    //     for (let j = 0; j < response.props.data.length; j++) {
    //       if (response.props.data[j].title === allresponse[i].title) {
    //         allresponse[i].value = response.props.data[j].value;
    //       }
    //     }
    //   }
    //   // for (let i = 0; i < response.props.data.length; i++) {
    //   //   data[i].title += ' ' + ((Number(data[i].value) / totalCount) * 100).toFixed(1) + '%';
    //   // }
    // }
    if (totalResponse.props !== undefined) {
      for (let i = 0; i < totalResponse.props.length; i++) {
        data.push({
          title: totalResponse.props[i].name,
          value: Number(totalResponse.props[i].value),
          modifiedTitle: '',
        });
        //totalCount += Number(response.props.data[i].value);
      }
      // } else {
      // dodani mock podaci, nema potrebe uopste else stavljati, ako response bude prazan prikazace se Nema podataka + slika
      /* for (let i = 0; i < allresponseTotal.length; i++) {
        data.push({ title: allresponseTotal[i].title, value: Number(allresponseTotal[i].value), modifiedTitle: '' });
      } */
    }
    return data;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [totalResponse]);
  const config: PieConfig = {
    height: 350,
    animation: {
      update: false,
      enter: false,
      leave: false,
      appear: false,
    },
    appendPadding: 10,
    data,
    legend: {
      layout: 'horizontal',
      position: 'bottom',
      maxRow: 1,
      flipPage: true,
      content: ({ current }: any) => `${current} + 100`,
    },
    tooltip: {
      //showContent: false,
    },
    angleField: 'value',
    colorField: 'title',
    color: [
      '#ED1C24',
      '#EE2F36',
      '#F04248',
      '#F1555A',
      '#F3686D',
      '#F47B7F',
      '#F68E91',
      '#F7A1A4',
      '#F8B4B6',
    ] /*({ title }) => {
      for (let i = 0; i < allresponse.length; i++) {
        //title.substring(0, title.indexOf(' ')) je islo umjesto title
        if (title === allresponse[i].title) return allresponse[i].color;
      }
    },*/,
    radius: 0.7,
    label: {
      type: 'inner',
      offset: '-30%',
      content: ({ percent }) => `${(percent * 100).toFixed(1)}%`,
      style: {
        fontSize: 14,
        textAlign: 'center',
      },
    },
    interactions: [
      {
        type: 'element-active',
      },
      {
        type: 'legend-filter',
        enable: false,
      },
    ],
  };
  return <>{data && data.length > 0 ? <Pie {...config} /> : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />}</>;
};
