import { Article } from 'types/services/articles';

export const transformResource = (resource: Article, stats: any, trends: any) => {
  let clicks = 0;
  let views = 0;
  let ctr = 0;
  let values = [
    [0, 0],
    [1, 0],
    [2, 0],
    [3, 0],
    [4, 0],
    [5, 0],
    [6, 0],
  ];
  for (var i = 0; i < stats?.length; i++) {
    if (stats[i].id === resource.id) {
      clicks = stats[i].clicks;
      views = stats[i].views;
      if (Number(views) !== 0) ctr = Number(((Number(clicks) / Number(views)) * 100).toFixed(2));
    }
    if (trends[i].id === resource.id) {
      for (var j = 0; j < 7; j++) {
        values[j][1] = Number(trends[i].days[j]);
      }
    }
  }

  let defaultValue = '';
  let blockName = 'Nije u bloku';
  if (resource.blockPivot && resource.blockPivot.length > 0) {
    let newDate = new Date();

    for (var k = 0; k < resource.blockPivot.length; k++) {
      blockName = resource.blockPivot[k].block.name;
      let currDate = new Date(resource.blockPivot[k].newDate);
      let diff = newDate.getTime() - currDate.getTime();
      const totalSeconds = Math.floor(diff / 1000);
      const days = Math.floor(totalSeconds / (3600 * 24));
      const hours = Math.floor(totalSeconds / 3600);
      const minutes = Math.floor((totalSeconds % 3600) / 60);

      let timeAgoTemp = '';
      let hourSuf = '';
      switch (hours) {
        case 2:
        case 3:
        case 4:
        case 22:
        case 23:
          hourSuf = 'a';
          break;

        case 1:
        case 21:
          hourSuf = '';
          break;

        default:
          hourSuf = 'i';
          break;
      }
      let minSuf = '';
      switch (minutes) {
        case 1:
        case 21:
        case 31:
        case 41:
        case 51:
          minSuf = 'u';
          break;

        case 2:
        case 3:
        case 4:
        case 22:
        case 23:
        case 24:
        case 32:
        case 33:
        case 34:
        case 42:
        case 43:
        case 44:
        case 52:
        case 53:
        case 54:
          minSuf = 'e';
          break;

        default:
          minSuf = 'a';
          break;
      }

      if (days === 0) {
        if (hours > 0) timeAgoTemp = hours + ' sat' + hourSuf;
        if (hours > 0 && minutes > 0) timeAgoTemp += ' ';
        if (minutes > 0) timeAgoTemp += minutes + ' minut' + minSuf;
      } else if (days === 1) {
        let tempsuf = 'Jučer';
        timeAgoTemp = tempsuf;
      } else if (days > 0) {
        let tempsufDays = 'Dana';
        timeAgoTemp = days + ' ' + tempsufDays;
      } else if (minutes <= 1) {
        timeAgoTemp = 'minut';
      }
      defaultValue += String(timeAgoTemp) + ' ' + String(blockName) + '\n';
    }
  }
  return {
    key: resource.id,
    ...resource,
    createdAt: resource.createdAt
      .slice(8, 10)
      .concat(
        '.',
        resource.createdAt.slice(5, 7),
        '.',
        resource.createdAt.slice(0, 4),
        ' - ',
        resource.createdAt.slice(11, 16),
      ), //DateTime.fromISO(resource.createdAt).toLocaleString(DateTime.DATETIME_SHORT_WITH_SECONDS),
    updatedAt: resource.updatedAt
      .slice(8, 10)
      .concat(
        '.',
        resource.updatedAt.slice(5, 7),
        '.',
        resource.updatedAt.slice(0, 4),
        ' - ',
        resource.updatedAt.slice(11, 16),
      ), //DateTime.fromISO(resource.updatedAt).toLocaleString(DateTime.DATETIME_SHORT_WITH_SECONDS),
    author: resource.author ? `${resource?.author?.firstName} ${resource?.author?.lastName}` : null,
    owner: resource.owner ? `${resource?.owner?.username}` : null,
    status: resource.status,
    createdBy: `${resource?.author?.firstName} ${resource?.author?.lastName}`,
    numberOfHits: resource?.articleStatistics?.numberOfHits,
    external_link: articleUrl(resource),
    note: resource.note,
    thumbnailImage: resource.thumbnailImage ? resource.thumbnailImage.thumbnailUrl : '',
    block: blockName || 'Nije u bloku',
    timeInBlock: defaultValue,
    clicks: clicks,
    views: views,
    ctr: ctr,
    graph: values,
  };
};

export const articleUrl = (article: Article) => {
  let url = '';

  return url;
};
