import { HomeOutlined } from '@ant-design/icons';

import { withNavigation } from 'containers/Navigation';

import { IRoute, Item } from 'types/common';

import { AnalyticsPreview } from './AnalyticsPreview';
import { AnalyticsTotal } from './AnalyticsTotal';
import { Home } from './Home';
import { ObituaryPreview } from './ObituaryPreview';
import { Sites } from './Sites';

export const route: IRoute = {
  component: withNavigation(Home),
  exact: true,
  isPrivate: true,
  key: 'home',
  path: '/',
};

export const routeSites: IRoute = {
  component: withNavigation(Sites),
  exact: true,
  isPrivate: true,
  key: 'home',
  path: '/sites',
};

export const previewRoute: IRoute = {
  component: ObituaryPreview,
  exact: true,
  isPrivate: false,
  key: 'home',
  path: '/prikaz/:id',
};

export const analyticsRoute: IRoute = {
  component: AnalyticsPreview,
  exact: true,
  isPrivate: false,
  key: 'analytics',
  path: '/report/article/:hash',
};

export const analyticsTotal: IRoute = {
  component: AnalyticsTotal,
  exact: true,
  isPrivate: false,
  key: 'analyticsOverall',
  path: '/report/total',
};

export const item: Item = {
  key: route.key,
  path: route.path as string,
  icon: HomeOutlined,
  rank: 1,
};

export const itemSites: Item = {
  key: routeSites.key,
  path: routeSites.path as string,
  icon: HomeOutlined,
  rank: 1,
};

export const previewItem: Item = {
  key: previewRoute.key,
  path: previewRoute.path as string,
  icon: HomeOutlined,
  rank: 1,
};
