/* eslint-disable jsx-a11y/label-has-associated-control */

import { PlusSquareOutlined } from '@ant-design/icons';
import { Image as Picture, Select } from 'antd';
import { Form, Input, Button, Spin, message, Modal } from 'antd';
import { Collapse } from 'antd';
import { DateTime } from 'luxon';
import moment from 'moment';
import { useEffect, useState, useRef, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery, useMutation } from 'react-query';
import { useSelector } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import { useLocation } from 'react-router-dom';

import { articlesService, usersService } from 'config/services';

import { HttpError } from 'helpers/http';

import { Article, ArticlePayload, ArticlePayloadWithId, ArticleStatusPayload } from 'types/services/articles';
import { PermissionsMap } from 'types/services/auth';
import { Image } from 'types/services/images';
import { PaginatedUsers, User } from 'types/services/users';
import { SessionState, StoreState } from 'types/store';

import BlocksEditor from './components/BlocksEditor/BlocksEditor';
import StatusAuditModal from './components/StatusAuditModal';
// eslint-disable-next-line import/order
import { UploadImages } from './components/UploadImages/UploadImages';
import { routes } from './routes';
import './index.scss';

export const ArticlePage = () => {
  const history = useHistory();
  const location = useLocation();
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const { id } = useParams<{ id?: string }>();

  const [articleImages, setArticleImages] = useState<Image[]>([]);
  const [showStatusAudit, setShowStatusAudit] = useState(false);
  const highlightUsed = useRef(false);
  const [coverImageInput, setCoverImageInput] = useState(false);
  const [coverImagePreview, setCoverImagePreview] = useState<Image>();
  const [coverImageWarning, setCoverImageWarning] = useState(false);
  const { user } = useSelector<StoreState, SessionState>(({ session }) => session);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { isLoading: usersLoading, data: usersData } = useQuery<PaginatedUsers, HttpError>('listUsers', () =>
    usersService.list({ page: 1, perPage: 1000, filters: {} }),
  );

  const {
    isLoading,
    data: article,
    refetch,
  } = useQuery<Article, HttpError>(['getArticle', id], () => articlesService.get(parseInt(id || '')), {
    enabled: typeof id !== 'undefined',
  });

  const { isLoading: isLoadingCreate, mutateAsync: createArticle } = useMutation(
    'createArticle',
    ({ articlePayload }: { articlePayload: ArticlePayload }) => articlesService.create(articlePayload),
  );

  const { isLoading: isLoadingUpdate, mutateAsync: updateArticle } = useMutation(
    'updateArticle',
    (articlePayload: ArticlePayloadWithId) => articlesService.patch(articlePayload),
  );

  const { isLoading: isStatusUpdating, mutateAsync: updateArticleStatus } = useMutation(
    'setArticleStatus',
    ({ id, status }: { id: number; status: ArticleStatusPayload }) => articlesService.setStatus(id, status),
  );

  useEffect(() => {
    if (!isLoading && article) {
      form.setFieldsValue({
        ...article,
        publicationTime: article.publicationTime ? moment(article.publicationTime) : null,
        authorId: article.author ? article.author.id : null,
        ownerId: article.owner ? article.owner.id : null,
      });

      highlightUsed.current = false;
    }
  }, [article, form, isLoading]);

  useEffect(() => {
    if (form.getFieldValue('thumbnailImage') !== undefined) {
      if (Array.isArray(form.getFieldValue('thumbnailImage'))) {
        setCoverImagePreview(form.getFieldValue('thumbnailImage')[0]);
      } else setCoverImagePreview(form.getFieldValue('thumbnailImage'));
      setCoverImageWarning(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form.getFieldValue('thumbnailImage')]);

  const saveArticle = (values: any) => {
    let userTemp;
    usersData?.data.forEach((u) => {
      if (u.firstName + ' ' + u.lastName === user?.name && u.email === user.email) {
        userTemp = u;
      }
    });

    if (values.hasAds === undefined) {
      values.hasAds = false;
    }

    const isUpdate = id && article;

    const articlePayload = {
      title: values.title,
      // subtitle: values.subtitle,
      thumbnailImageId: values.thumbnailImage
        ? (Array.isArray(values.thumbnailImage) ? values.thumbnailImage[0] : values.thumbnailImage).id
        : coverImagePreview?.id,
      publicationTime: values.publicationTime
        ? values.publicationTime.format()
        : DateTime.now().set({ millisecond: 0 }).toISO({ suppressMilliseconds: true }),
      authorId: values.authorId || null,
      ownerId: values.ownerId || null,
      stamp: values.stamp,
      url: values.url,
      createdBy: isUpdate ? article.author : userTemp ? userTemp : null,
      updatedBy: isUpdate ? (userTemp ? userTemp : null) : null,
    };

    return isUpdate ? updateArticle({ id: article?.id, ...articlePayload }) : createArticle({ articlePayload });
  };

  const onFinish = (values: any) => {
    const promise = saveArticle(values);
    const isUpdate = id && article;
    promise
      .then((article) => {
        message.success(
          t(`common:Successfully ${isUpdate ? 'Updated' : 'Created'}`, { resource: t('article:Article') }),
        );
        if (!isUpdate) {
          history.push(`/article/${article.id}`);
        }
      })
      .catch((e) => {
        message.error(
          t('common:Action failed', {
            action: t(`common:${isUpdate ? 'Update' : 'Creation'}`),
            resource: t('article:Article').toLowerCase(),
            suffix: 'a',
          }),
        );
        console.error(e);
      });
  };

  const onReset = () => {
    const articleRoute = routes.find((r) => r.key === 'list-articles')?.path as string | undefined;
    articleRoute && history.push(articleRoute);
  };

  const handleTumbnailSelect = (image: Image) => {
    form.setFieldsValue({
      ...form.getFieldsValue(true),
      thumbnailImage: image,
      ownerId: article?.owner ? article.owner.id : null,
    });
    setCoverImagePreview(image);
    setCoverImageWarning(false);
  };

  const toggleStatusAuditModal = () => {
    setShowStatusAudit((show) => !show);
  };

  const handleArticleApprove = async () => {
    if (article) {
      await updateArticleStatus({ id: article.id, status: { status: 'approved' } });
      refetch();
      message.success(` ${t('article:Status change success')}`);
    }
  };

  const handleImagesChange = (images: Image[]) => {
    const newImages = [...articleImages];
    images.forEach((image) => {
      const aI = articleImages.find((ai) => ai.id === image.id);
      if (!aI) {
        newImages.push(image);
      } else {
        aI.source = image.source;
        aI.description = image.description;
      }
    });
    setArticleImages(newImages);
  };

  const handlePreviewClick = async () => {
    window.open(
      //TODO: edit/create env file for production ie:(https://shazam.rotocms.com/clanak/726766/preview)
      process.env.SHAZAM_APP_API_URL + '/clanak/' + article?.id + '/preview',
      '_blank',
      'noopener,noreferrer',
    );
  };

  const handleArticleRejection = async (comment: string) => {
    if (article) {
      const payload: ArticleStatusPayload = { status: 'rejected', comment };
      await updateArticleStatus({ id: article.id, status: payload });
      refetch();
      message.success(` ${t('article:Status change success')}`);
    }
  };

  const handleHighlightCalled = () => {
    highlightUsed.current = true;
  };

  const dataLoading = isLoading || isLoadingCreate || isLoadingUpdate || isStatusUpdating;
  const everythingReady = !dataLoading;

  const permissionMap = useSelector<StoreState, PermissionsMap>(({ session }) => session.permissions);
  const permissions = permissionMap[location.pathname.match(/\/article\//g) ? '/article/:id' : location.pathname];

  const toggleCoverImageInput = () => {
    setCoverImageInput(!coverImageInput);
  };

  const showCoverImageWarning = () => {
    if (coverImagePreview === undefined) setCoverImageWarning(true);
  };

  const userArr: User[] = [];
  usersData?.data.forEach((u) => {
    if (u.firstName + ' ' + u.lastName !== user?.name) {
      userArr.push(u);
    }
  });

  const useMediaQuery = (width: number) => {
    const [targetReached, setTargetReached] = useState(false);

    const updateTarget = useCallback((e) => {
      if (e.matches) {
        setTargetReached(true);
      } else {
        setTargetReached(false);
      }
    }, []);

    useEffect(() => {
      const media = window.matchMedia(`(max-width: ${width}px)`);
      media.addEventListener('change', updateTarget);
      // Check on mount (callback is not called until a change occurs)
      if (media.matches) {
        setTargetReached(true);
      }

      return () => media.removeEventListener('change', updateTarget);
    }, []);

    return targetReached;
  };

  const isBreakpoint = useMediaQuery(768);

  return (
    <>
      {dataLoading ? (
        <div style={{ padding: '10px', textAlign: 'center' }}>
          <Spin size="large" />
        </div>
      ) : null}
      {/* <div style={{ height: '20px', background: 'black' }}></div> */}
      <Form
        layout="vertical"
        form={form}
        name="article-form"
        onFinish={onFinish}
        className="article-form"
        // onValuesChange={handleFormChange}
      >
        {everythingReady && (
          <>
            <div className={isBreakpoint ? 'grid' : 'flex'} style={{ background: '#F9F9F9' }}>
              <div style={{ width: '100%', padding: '20px', maxWidth: '660px' }} className="flex flex-col">
                <Form.Item label={t('article:External url')} name="url" className="article-stamp">
                  <Input.TextArea
                    rows={1}
                    style={{ background: '#F9F9F9' }}
                    spellCheck={false}
                    placeholder={t('article:url')}
                  />
                </Form.Item>

                <Form.Item
                  label={t('article:Card label')}
                  name="stamp"
                  className="article-stamp"
                  rules={[{ required: true }]}
                >
                  <Input.TextArea
                    rows={1}
                    style={{ background: '#F9F9F9', width: '90%' }}
                    spellCheck={false}
                    placeholder={t('article:Card label')}
                    maxLength={article?.legacy ? 100 : 25}
                    showCount
                  />
                </Form.Item>

                <Form.Item
                  label={t('article:title')}
                  name="title"
                  className="article-title"
                  rules={[{ required: true }]}
                >
                  <Input.TextArea
                    rows={2}
                    spellCheck={false}
                    placeholder={t('article:title')}
                    maxLength={
                      article?.legacy ? 200 : user?.role === 'EDITOR' || user?.role === 'ADMINISTRATOR' ? 1000 : 100
                    }
                    showCount
                    autoSize={true}
                    style={{ width: '90%' }}
                  />
                </Form.Item>
                {/* <Form.Item
                  label={t('article:subtitle')}
                  name="subtitle"
                  className="article-subtitle"
                  rules={[{ required: true }]}
                >
                  <Input.TextArea
                    rows={2}
                    spellCheck={false}
                    placeholder={t('article:subtitle')}
                    maxLength={article?.legacy ? 200 : 150}
                    showCount
                    style={{ width: '90%' }}
                  />
                </Form.Item> */}
                <BlocksEditor
                  images={articleImages || []}
                  articleId={article?.id}
                  highlighting={permissions.approve}
                  onHighLightCalled={handleHighlightCalled}
                  onImagesChange={handleImagesChange}
                  onThumnailSelect={handleTumbnailSelect}
                />
              </div>

              <div
                style={{
                  width: '100%',
                  maxWidth: '350px',
                  alignItems: 'end',
                  justifyContent: 'end',
                  background: 'white',
                  padding: '15px 35px 43px 35px',
                }}
                className="images-section"
              >
                <div>
                  <div>
                    {(user?.role === 'EDITOR' || user?.role === 'ADMINISTRATOR') &&
                    user?.email !== article?.author?.email &&
                    article?.author?.email !== undefined &&
                    article?.author.role !== 'EDITOR' &&
                    article?.author.role !== 'ADMINISTRATOR' ? (
                      <>
                        <div className="flex" style={{ justifyContent: 'space-between' }}>
                          {permissions.approve && (
                            <>
                              <Button
                                type="primary"
                                style={
                                  article.status === 'rejected'
                                    ? { marginRight: '18px', visibility: 'hidden' }
                                    : { marginRight: '18px' }
                                }
                                className={`cancel-article-button`}
                              >
                                {t('article:Reject')}
                              </Button>

                              <Button
                                type="primary"
                                style={
                                  article.status === 'approved' ? { visibility: 'hidden' } : { marginRight: '0px' }
                                }
                                onClick={handleArticleApprove}
                                className="publish-article-button"
                              >
                                {t('article:Approve')}
                              </Button>
                            </>
                          )}
                        </div>
                        {permissions.approve && (
                          <div style={{ marginTop: '10px', marginBottom: '10px' }}>
                            <Button
                              type="primary"
                              className="publish-article-button w-full save-changes"
                              htmlType="submit"
                              onClick={showCoverImageWarning}
                            >
                              {id ? t('common:Save changes') : t('common:Publish Article')}
                            </Button>
                          </div>
                        )}
                      </>
                    ) : (
                      <div className="flex" style={{ marginBottom: '20px', justifyContent: 'space-between' }}>
                        <Button htmlType="button" onClick={onReset} className="cancel-article-button">
                          {t('common:Cancel')}
                        </Button>
                        <Button
                          type="primary"
                          className="publish-article-button"
                          htmlType="submit"
                          onClick={showCoverImageWarning}
                        >
                          {id ? t('common:Save changes') : t('common:Publish Article')}
                        </Button>
                      </div>
                    )}
                  </div>
                  {/* <span>{t('article:Status')}: </span>
                  {article && <b>{t(`article:Status ${article.status}`)} </b>}
                  <Button htmlType="button" onClick={toggleStatusAuditModal}>
                    {t('article:Changes')}
                  </Button> */}
                </div>
                <Button
                  onClick={toggleCoverImageInput}
                  icon={<PlusSquareOutlined />}
                  style={{
                    width: '100%',
                    marginBottom: '10px',
                    fontFamily: 'DM Sans',
                    textTransform: 'uppercase',
                  }}
                >
                  {t('images:Choose cover image')}
                </Button>
                {coverImageWarning && <p style={{ color: '#ff4d4f' }}>{t('article:Cover image required')}</p>}
                {coverImagePreview?.imageUrl && (
                  <>
                    <div style={{ display: 'flex', marginTop: '5px', justifyContent: 'space-between' }}>
                      <Picture src={coverImagePreview.imageUrl} style={{ width: '170px', height: '95px' }}></Picture>
                      <Picture
                        src={coverImagePreview.squareThumbnailUrl}
                        style={{ height: '95px', width: '95px' }}
                      ></Picture>
                    </div>
                  </>
                )}
                <Modal
                  title={t('images:Choose cover image')}
                  visible={coverImageInput}
                  onOk={toggleCoverImageInput}
                  onCancel={toggleCoverImageInput}
                  className="imageModal"
                  width={500}
                >
                  <Form.Item
                    name="thumbnailImage"
                    style={{ marginTop: '20px' }}
                    rules={[{ required: true, message: t('article:Cover image required') }]}
                  >
                    <UploadImages maxCount={1} showSquareThumbnail={true} index={-1} isCover={true} />
                  </Form.Item>
                </Modal>

                <Form.Item
                  name="ownerId"
                  style={{ marginTop: '10px' }}
                  label={'Klijent'}
                  className="author"
                  hidden={false}
                >
                  <Select
                    showSearch
                    options={usersData?.data.map((user) => ({
                      label: `${user.firstName} ${user.lastName}`,
                      value: user.id,
                    }))}
                    filterOption={(input, option) =>
                      option?.label ? (option?.label as string)?.toLowerCase().includes(input.toLowerCase()) : false
                    }
                  />
                </Form.Item>

                {/* <div style={{ marginTop: '10px', color: '#8B8B8B' }}>{t('article:Publication time')}</div>
                <div
                  className="flex"
                  style={{ marginBottom: '10px', marginTop: '10px', justifyContent: 'space-between' }}
                >
                  <button
                    className="publish-now"
                    style={
                      !isActive
                        ? { color: 'white', background: '#333333' }
                        : { background: '#e0e0e0', color: '#333333', fontWeight: 'normal' }
                    }
                    onClick={refuseHandleToggle}
                  >
                    {t('article:Now')}
                  </button>
                  <button
                    className="publish-later"
                    style={
                      isActive
                        ? { color: 'white', background: '#333333' }
                        : { background: '#e0e0e0', color: '#333333', fontWeight: 'normal' }
                    }
                    onClick={handleToggle}
                  >
                    {t('article:Publish later')}
                  </button>
                </div>
                <div className={isActive ? 'block' : 'hidden'}>
                  <Form.Item name="publicationTime">
                    <DatePicker
                      style={{
                        width: '100%',
                        marginTop: '10px',
                        borderRadius: '5px',
                        borderColor: '#d9d9d9',
                        height: '34px',
                      }}
                      showTime
                      format="DD.MM.YYYY HH:mm"
                      className="publication"
                      disabledDate={(current) => {
                        let customDate = moment().format('YYYY-MM-DD');
                        return current && current < moment(customDate, 'YYYY-MM-DD');
                      }}
                    />
                  </Form.Item>
                </div> */}
              </div>
            </div>
          </>
        )}
      </Form>
      {article && (
        <StatusAuditModal
          statusAudit={article?.statusAudit}
          visible={showStatusAudit}
          onCancel={toggleStatusAuditModal}
        />
      )}
    </>
  );
};
