//* █▀█ █▄▀
//* █▄█ █░█

import classNames from 'classnames';

export type CardDetailsProps = {
  link: string;
  stamp: string;
  stampColor?: string;
  stampClassNames?: string;
  timeAgo: string;
  hideTimeAgo?: boolean;
  timeAgoPosition?: 'bottom-left' | 'top-right';
  title: string;
  titleClassNames?: string;
  stampVisibility?: boolean;
  isRelated?: boolean;
  lc3?: boolean;
  isLinked?: boolean;
};

export const CardDetails = ({
  link,
  stamp,
  stampColor,
  stampClassNames,
  timeAgo,
  hideTimeAgo,
  timeAgoPosition = 'top-right',
  title,
  titleClassNames,
  stampVisibility = true,
  isRelated,
  lc3,
  isLinked,
}: CardDetailsProps) => {
  let temp = timeAgo as any;
  const year = temp.slice(0, 4);
  const month = Number(temp.slice(5, 7)) - 1;
  const day = temp.slice(8, 10);
  const hour = temp.slice(11, 13);
  const minute = temp.slice(14, 16);

  const currDate = new Date() as any;
  const createdDate = new Date(year, month, day, hour, minute) as any;

  var delta = Math.abs(currDate - createdDate) / 1000;

  var days = Math.floor(delta / 86400);
  delta -= days * 86400;

  var hours = Math.floor(delta / 3600) % 24;
  delta -= hours * 3600;

  var minutes = Math.floor(delta / 60) % 60;
  delta -= minutes * 60;

  let timeAgoTemp = '';
  let hourSuf = '';
  switch (hours) {
    case 2:
    case 3:
    case 4:
    case 22:
    case 23:
      hourSuf = 'a';
      break;

    case 1:
    case 21:
      hourSuf = '';
      break;

    default:
      hourSuf = 'i';
      break;
  }

  if (days === 0) {
    if (hours > 0) timeAgoTemp = hours + ' h';
    if (hours > 0 && minutes > 0) timeAgoTemp += ' ';
    if (minutes > 0) timeAgoTemp += minutes + ' min';
  } else if (days === 1) {
    let tempsuf = window.location.href.includes('english') ? 'yesterday' : 'common:yesterday';
    timeAgoTemp = tempsuf;
  } else if (days > 0 && days < 8) {
    let tempsufDays = window.location.href.includes('english') ? 'days' : 'common:days';
    timeAgoTemp = days + ' ' + tempsufDays;
  } else {
    let tempMonth: any = month + 1;
    let tempDayString = String(day);
    let tempDay: any = day;
    if (tempDayString.startsWith('0')) tempDay = tempDayString.substring(1);
    timeAgoTemp = tempDay + '.' + tempMonth + '.' + year;
  }
  const timeAgoElement = (
    <p className={classNames('stamp color-timestamp grey-timeago', stampClassNames)} lang="bs">
      {timeAgoTemp !== '' ? timeAgoTemp : 'Upravo'}
    </p>
  );

  let titlePrefix = null;
  let titleRemainder = null;

  if (title.includes('/')) {
    titlePrefix = title.split('/')[0];
    titleRemainder = title.replace(titlePrefix + '/', '');
  }
  //TODO: errori u konzoli, potrebno je odkomentati
  // let dL = link.startsWith('/');
  return (
    <div className="h-full flex flex-col space-y-[0.8rem]">
      <div className="flex avaz-tv-stamp">
        {stampVisibility ? (
          <div className="flex-1">
            <p
              style={{ color: stampColor }}
              className={classNames(
                `stamp overflow-hidden text-ellipsis color_${stampColor?.slice(1).toLocaleLowerCase()}`,
                stampClassNames,
              )}
            >
              {stamp.toLocaleUpperCase()}
            </p>
          </div>
        ) : (
          <></>
        )}
      </div>

      {/* <Link href={dL ? link : '/' + link}> */}
      {/* //TODO: errori u konzoli, potrebno je odkomentati*/}
      <a href={link}>
        <h2
          className={classNames(
            `title ${isRelated === true || lc3 === true ? 'cl3' : 'line-clamp-4'} clamp-style`,
            titleClassNames,
            window.location.href.includes('/avaz-tv/') ? 'text-white' : '',
            'lineClamp4',
          )}
        >
          {titlePrefix && titleRemainder ? (
            <>
              <span
                className="text-[#ED1C24] color_ed1c24"
                dangerouslySetInnerHTML={{
                  __html: titlePrefix,
                }}
              />
              <span className="text-[#d3d3d3]">/</span>
              <span
                dangerouslySetInnerHTML={{
                  __html: titleRemainder,
                }}
              />
            </>
          ) : (
            <>{title}</>
          )}
        </h2>
      </a>
    </div>
  );
};
