import React, { useEffect, useState } from 'react';
import Chart from 'react-google-charts';
import { XAxis, YAxis, CartesianGrid, Tooltip, Legend, Line, LineChart, ResponsiveContainer } from 'recharts';

const data1 = [
  {
    date: '19-10-2023',
    impressions: 4000,
    clicks: 2400,
  },
  {
    date: '20-10-2023',
    impressions: 3000,
    clicks: 1398,
  },
  {
    date: '21-10-2023',
    impressions: 2000,
    clicks: 9800,
  },
  {
    date: '22-10-2023',
    impressions: 2780,
    clicks: 3908,
  },
  {
    date: '23-10-2023',
    impressions: 1890,
    clicks: 4800,
  },
  {
    date: '24-10-2023',
    impressions: 2390,
    clicks: 3800,
  },
  {
    date: '25-10-2023',
    impressions: 3490,
    clicks: 4300,
  },
];

export const StackedAreaChart2 = (response: any) => {
  const [data, setData] = useState<any>([]);
  useEffect(() => {
    if (
      response.response.length > 0 &&
      Object.keys(response.response[0]).length !== 0 &&
      Object.keys(response.response[1]).length !== 0
    ) {
      const newData = Object.keys(response.response[0])
        .reverse()
        .map((r: string) => {
          let newDate = r;
          let newDateSplit = r.split('-');
          if (newDate.length > 0) newDate = newDateSplit[2] + '-' + newDateSplit[1] + '-' + newDateSplit[0];
          return [newDate, Number(response.response[0][r]), Number(response.response[1][r])];
        });

      newData.unshift(['Date', 'Impresije', 'Klikovi']);
      setData(newData);
      console.log(newData, 'poruka armin nije smijesan');
    } else {
      // If there's no response data, set data to an empty array
      setData([]);
    }
  }, [response.response]);

  const data1 = [
    ['Year', 'Sales', 'Expenses'],
    ['2004', 1000, 400],
    ['2005', 1170, 460],
    ['2006', 660, 1120],
    ['2007', 1030, 540],
  ];
  const options = {
    backgroundColor: '#f0f2f5',
    // title: 'Company Performance',
    curveType: 'function',
    legend: { position: 'none' },
    vAxis: {
      viewWindow: {
        min: 0,
      },
    },
  };

  //   useEffect(() => {
  //     console.log(response, 'aa');
  //   }, [response]);

  return <Chart chartType="LineChart" width="99%" height="340px" data={data} options={options} />;
};
