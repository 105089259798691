import { Divider, Form, Input } from 'antd';
import { DateTime } from 'luxon';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';

import { obituaryService } from 'config/services';

import { HttpError } from 'helpers/http';

import { Obituary } from 'types/services/obituary';

import { Footer } from './Footer';
import { Header } from './Header';

export const ObituaryPreview = () => {
  const { id } = useParams<{ id?: string }>();
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const {
    isLoading,
    data: obituary,
    refetch,
  } = useQuery<Obituary, HttpError>(['getObituary', id], () => obituaryService.getObituaryById(parseInt(id || '')), {
    enabled: typeof id !== 'undefined',
  });

  //TODO:implement following functions
  const onFinish = (values: any) => {};
  const handleFormChange = (changedValues: any) => {};
  return (
    <>
      <Header></Header>
      <div className="main-container">
        <div className="left-container">
          <div className="flex pre">
            <p className="mta">Pregled smrtovnice</p> <Divider />
          </div>
          <div className="outline">
            <div className="inner-outline">
              <span className="innerElement">{obituary?.personal_message}</span>
              <br></br>
              <span className="innerElement">{obituary?.introductory_text}</span>
              <br></br>
              {obituary?.people_id.length === 1 ? (
                <div>
                  <span className="innerElement">
                    <img src="/1.png" alt="1" style={{ width: '87px', height: '120px' }}></img>
                  </span>
                  <br></br>
                  <span className="innerElement">
                    {obituary?.people_id[0].firstName} {obituary?.people_id[0].additional_name}{' '}
                    {obituary?.people_id[0].lastName}
                  </span>
                  <br></br>
                  <span className="innerElement">
                    {obituary?.people_id[0].birth_date
                      ? DateTime.fromISO(String(obituary?.people_id[0].birth_date)).toLocaleString(DateTime.DATE_SHORT)
                      : obituary?.people_id[0].birth_approx}
                    {' - '}
                    {obituary?.people_id[0].birth_end
                      ? DateTime.fromISO(String(obituary?.people_id[0].birth_end)).toLocaleString(DateTime.DATE_SHORT)
                      : obituary?.people_id[0].birth_end_approx}
                  </span>
                </div>
              ) : obituary?.people_id !== undefined && obituary?.people_id.length > 1 ? (
                <div className="obituary-wrapper">
                  {obituary.people_id.map((p, i) => {
                    return (
                      <div className="obituary-child">
                        <span className="innerElement">
                          <img src="/1.png" alt="1" style={{ width: '87px', height: '120px' }}></img>
                        </span>
                        <br></br>
                        <span className="innerElement">
                          {obituary?.people_id[i].firstName} {obituary?.people_id[i].additional_name}{' '}
                          {obituary?.people_id[i].lastName}
                        </span>
                        <span className="innerElement bottom-date">
                          {obituary?.people_id[i].birth_date
                            ? DateTime.fromISO(String(obituary?.people_id[i].birth_date)).toLocaleString(
                                DateTime.DATE_SHORT,
                              )
                            : obituary?.people_id[i].birth_approx}
                          {' - '}
                          {obituary?.people_id[i].birth_end
                            ? DateTime.fromISO(String(obituary?.people_id[i].birth_end)).toLocaleString(
                                DateTime.DATE_SHORT,
                              )
                            : obituary?.people_id[i].birth_end_approx}
                        </span>
                      </div>
                    );
                  })}
                </div>
              ) : (
                <></>
              )}
              <br></br>
              <span className="innerElement">{obituary?.main_text}</span>
              <br></br>
              <span className="innerElement">{obituary?.bereaved}</span>
            </div>
          </div>
        </div>
        <div className="right-container">
          <div className="flex">
            <p className="mta">Cijena</p> <Divider />
          </div>
          <div>
            <Form
              layout="vertical"
              form={form}
              name="article-form"
              onFinish={onFinish}
              className=""
              onValuesChange={handleFormChange}
            >
              <div className="">
                <Form.Item label={t('article:Publication date')} name="dateOfPublish" className="flex f-direction">
                  <span className="fright">
                    {DateTime.fromISO(String(obituary?.published_date)).toLocaleString(DateTime.DATE_SHORT)}
                  </span>
                </Form.Item>

                <Form.Item label={t('article:totalToPay')} name="totalToPay" className="flex f-direction">
                  <span className="fright">$$$</span>
                </Form.Item>
                <Divider></Divider>
                {/* <span>Molimo Vas da ispod ostavite Vaš kontakt broj</span> */}
                <Form.Item label={t('article:leaveNumber')} name="leaveNumber" className="flex">
                  <Input placeholder="npr. +387 33 281 356"></Input>
                </Form.Item>
                <span>
                  <b>Napomene:</b> Dnevni avaz vikendom izlazi kao dvobroj.
                  <br />
                  <br /> Molimo Vas da smrtovnice uplatite do 18:30, ukoliko želite da iste budu objavljene sutradan u
                  printanom izdanju.
                  <br />
                  <br /> U cijenu je uključena objava smrtovnice na portalu.
                </span>
              </div>
            </Form>
          </div>
        </div>
      </div>
      <div style={{ borderBottom: '1px solid #E3E3E3' }}></div>
      <Footer></Footer>
    </>
  );
};
