import { UserSwitchOutlined } from '@ant-design/icons';

import { withNavigation } from 'containers/Navigation';

import { IRoute, Submenu } from 'types/common';

import { ArticleBlocksPage } from './ArticleBlocksPage';
import { EditBlock } from './EditBlock';

export const routes: IRoute[] = [
  {
    component: withNavigation(EditBlock),
    exact: true,
    key: 'block-index',
    path: '/block/index',
    isPrivate: true,
  },
  {
    component: withNavigation(ArticleBlocksPage),
    exact: true,
    key: 'list-article-blocks',
    path: '/article-blocks',
    isPrivate: true,
  },
  // {
  //   component: withNavigation(EditBlock),
  //   exact: true,
  //   key: 'block-article',
  //   path: '/block/article',
  //   isPrivate: true,
  // },
];

export const submenu: Submenu = {
  key: 'Blokovi',
  icon: UserSwitchOutlined,
  rank: 6,
  items: routes.map((route) => ({ key: route.key, path: route.path as string })),
};
