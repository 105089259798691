import { Column } from '@ant-design/plots';
import { Empty } from 'antd';
import { useMemo } from 'react';

export const GroupedColumn = (response: any) => {
  let temp = [
    { name: 'Browsers', title: 'Mozilla', value: 5 },
    { name: 'Browsers', title: 'Safari', value: 7 },
    { name: 'Browsers', title: 'Chrome', value: 9 },
    { name: 'Browsers', title: 'Samsung internet', value: 22 },
  ];

  const data = useMemo(() => {
    // console.log('pita2');
    // console.log(response);
    const data = [];

    if (response.props !== undefined) {
      for (let i = 0; i < response.props.length; i++) {
        data.push({
          name: 'Browsers',
          title: response.props[i].name,
          value: Number(response.props[i].value),
        });
      }
    }
    return data;
  }, [response]);

  const config = {
    data,
    xField: 'title',
    yField: 'value',
    seriesField: 'name',
    // legend: { custom: false, background: { style: { fill: 'blue', fillOpacity: 1 } } },
    // legend: {
    //   layout: 'horizontal',
    //   position: 'top',
    // },
    color: ['#ED1C24', '#E21219', '#D01117', '#BD0F15', '#AA0E13', '#970C11', '#840B0F', '#71090D', '#5E080B'],
    yAxis: {
      //   label: null,
      grid: {
        line: {
          style: {
            stroke: '#527A5F',
            lineWidth: 0.2,
          },
        },
      },
    },
    maxColumnWidth: 90,
    padding: 45,

    interactions: [
      {
        type: 'legend-filter',
        enable: false,
      },
    ],
  };
  return <>{data && data.length > 0 ? <Column {...config} /> : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />}</>;
};
