import { ActivityLogService } from 'services/activity-log';
import { ArticleBlockTypesService } from 'services/article-block-types';
import { ArticleBlocksService } from 'services/article-blocks';
import { ArticlesService } from 'services/articles';
import { AuthService } from 'services/auth';
import { HomeStatisticsService } from 'services/home-statistics';
import { ImagesService } from 'services/images';
import { ObituaryService } from 'services/obituaries';
import { ObituaryFormatService } from 'services/obituaryFormat';
import { RbacService } from 'services/rbac/service';
import { UsersService } from 'services/users';

import { client } from './client';

export const activityLogService = new ActivityLogService(client);
export const authService = new AuthService(client);
export const imagesService = new ImagesService(client);
export const usersService = new UsersService(client);
export const rbacService = new RbacService(client);
export const articlesService = new ArticlesService(client);
export const homeStatisticsService = new HomeStatisticsService(client);
export const obituaryFormatService = new ObituaryFormatService(client);
export const obituaryService = new ObituaryService(client);
export const articleBlocksService = new ArticleBlocksService(client);
export const articleBlockTypesService = new ArticleBlockTypesService(client);
