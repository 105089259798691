import { Row, Spin, Table } from 'antd';
import { isNil, omitBy } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';

import { obituaryService } from 'config/services';

import { HttpError } from 'helpers/http';

import { SessionState, StoreState } from 'types/store';

export const Sites = (props?: any) => {
  const { Column } = Table;
  const { t } = useTranslation();

  const { user } = useSelector<StoreState, SessionState>(({ session }) => session);

  const { isLoading, data, isFetching } = useQuery<any, HttpError>(['getSites'], () =>
    obituaryService.getSitesByClient(user?.email),
  );

  const total = 10;

  useEffect(() => {
    console.log(data?.byClientFinal?.views, 'rikii');
  }, [data]);

  const useMediaQuery = (width: number) => {
    const [targetReached, setTargetReached] = useState(false);

    const updateTarget = useCallback((e) => {
      if (e.matches) {
        setTargetReached(true);
      } else {
        setTargetReached(false);
      }
    }, []);

    useEffect(() => {
      const media = window.matchMedia(`(max-width: ${width}px)`);
      media.addEventListener('change', updateTarget);
      // Check on mount (callback is not called until a change occurs)
      if (media.matches) {
        setTargetReached(true);
      }

      return () => media.removeEventListener('change', updateTarget);
    }, []);

    return targetReached;
  };

  const isBreakpoint = useMediaQuery(768);

  const handleTableChange = (
    pagination: any,
    filters: {
      status?: string[];
      category?: number[];
      author?: null | number[];
      title?: string[];
      owner?: null | number[];
    },
    sorter: any,
  ) => {
    let filterValues = omitBy(
      {
        status: filters.status,
        categoryIds: filters.category,
        authorId: filters.author,
        title: filters.title,
        ownerId: filters.owner,
      },
      isNil,
    );

    if (Object.keys(filterValues).length === 0) {
      if (props.authorId !== -1 && props.authorId !== undefined) {
        filterValues.authorId = props.authorId;
        filterValues.ownerId = props.ownerId;
        filterValues.dateFrom = props.dateFrom;
        filterValues.dateTo = props.dateTo;
        filterValues.status = props.status;
        filterValues.title = props.title;
      }
    }

    if (props.dateFrom && props.dateTo) {
      filterValues.dateFrom = props.dateFrom;
      filterValues.dateTo = props.dateTo;
    }

    if (user?.role === 'JOURNALIST') filterValues.email = [user.email];
    // setFilters(filterValues);
  };

  return (
    <div style={{ padding: '10px', textAlign: 'center' }} className="journalist">
      <Row gutter={[8, 16]}>
        <Table
          bordered
          sticky
          size="middle"
          loading={isLoading || isFetching}
          dataSource={data?.byClientFinal?.views}
          onChange={handleTableChange}
          scroll={{ x: isBreakpoint ? true : undefined }}
        >
          <Column key="0" dataIndex="name" title={'Sajt'} width={75} render={(text, art: any) => <span>{text}</span>} />
          <Column
            key="0"
            dataIndex="value"
            title={'Impresije'}
            width={75}
            render={(text, art: any) => <span>{text}</span>}
          />
        </Table>
      </Row>
    </div>
  );
};
