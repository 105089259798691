import { FacebookIcon, TwitterIcon, InstagramIcon, YouTubeIcon, WhatsAppIcon, ViberIcon, LinkedInIcon } from './Icons';

export const config = {
  social: [
    {
      key: 'facebook',
      icon: FacebookIcon,
      link: 'https://www.facebook.com/dnevniavaz',
    },
    {
      key: 'twitter',
      icon: TwitterIcon,
      link: 'https://twitter.com/DnevniAvaz',
    },
    {
      key: 'instagram',
      icon: InstagramIcon,
      link: 'https://www.instagram.com/dnevniavaz',
    },
    {
      key: 'youtube',
      icon: YouTubeIcon,
      link: 'https://www.youtube.com/c/DnevniAvazTV',
    },
    {
      key: 'whatsapp',
      icon: WhatsAppIcon,
      link: 'whatsapp://tel:+38765822888',
    },
    {
      key: 'viber',
      icon: ViberIcon,
      link: 'viber://chat?number=+38765822888',
    },
    {
      key: 'linkedin',
      icon: LinkedInIcon,
      link: 'https://www.linkedin.com/showcase/dnevniavaz/about/',
    },
  ],

  links: [
    { key: 'Impressum', link: '/impressum' },
    { key: 'Oglašavanje', link: '/oglasavanje' },
    { key: 'Uslovi korištenja', link: '/uslovi-koristenja' },
    { key: 'Politika privatnosti', link: '/politika-privatnosti' },
    { key: 'Kontakt', link: '/kontakt' },
  ],

  linksEnglish: [
    { key: 'Impressum', link: '/english/impressum' },
    { key: 'Advertising', link: '/english/marketing' },
    { key: 'Terms of use', link: '/english/terms-of-use' },
    { key: 'Privacy policy', link: '/english/privacy-policy' },
    { key: 'Contact', link: '/english/contact' },
  ],

  info: [
    'Vlasnik autorskih prava © avaz-roto press d.o.o.',
    'ISSN 1840-3522.',
    'Zabranjeno preuzimanje sadržaja bez dozvole izdavača.',
  ],

  infoEnglish: ['Copyright Avaz-roto press d.o.o Sarajevo. ISSN 1840-3522.'],
};
