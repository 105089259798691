import { Navigation } from './Navigation';

export function withNavigation<T>(Component: React.ComponentType<T>) {
  return function (props: T) {
    return (
      <Navigation>
        <Component {...props} />
      </Navigation>
    );
  };
}
