import { useState, useRef } from 'react';
import { DragDropContext, Droppable, DropResult } from 'react-beautiful-dnd';

import { BlockType } from 'types/services/articles';
import { Image } from 'types/services/images';

import Block from './Block';

import './style.scss';

export type ExtendedBlockType = BlockType & { editable: boolean; isEditing: boolean; key: number };

interface BlocksEditorProps {
  images: Image[];
  articleId?: number;
  highlighting: boolean;
  onHighLightCalled?: Function;
  onImagesChange: (images: Image[]) => void;
  onThumnailSelect: (image: Image) => void;
}

const BlocksEditor = ({
  images,
  articleId,
  highlighting = false,
  onHighLightCalled,
  onImagesChange,
  onThumnailSelect,
}: BlocksEditorProps) => {
  const [internalBlocks, setInternalBlocks] = useState<ExtendedBlockType[]>([]);
  const indexInEdit = useRef<{ [key: string]: boolean }>({});

  const handleSetEditing = (index: number, isEditing: boolean) => {
    const copy = [...internalBlocks];
    copy[index].isEditing = isEditing;
    if (isEditing) {
      indexInEdit.current[index] = true;
    } else {
      delete indexInEdit.current[index];
    }

    setInternalBlocks(copy);
  };

  const handleRemove = (index: number) => {
    const copy = [...internalBlocks];
    copy.splice(index, 1);
    setInternalBlocks(copy);
  };

  const onDragEnd = (result: DropResult) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const newList = [...internalBlocks];
    newList.splice(result.destination.index, 0, newList.splice(result.source.index, 1)[0]);

    setInternalBlocks(newList);
  };

  return (
    <div className="blocks-editor">
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable">
          {(provided, snapshot) => (
            <div className="blocks-editor-body" {...provided.droppableProps} ref={provided.innerRef}>
              {internalBlocks.map((block, index) => (
                <Block
                  key={block.key}
                  block={block}
                  index={index}
                  images={images}
                  articleId={articleId}
                  highlighting={highlighting}
                  isEditing={block.isEditing}
                  onHighLightCalled={onHighLightCalled}
                  onImagesChange={onImagesChange}
                  onDelete={handleRemove}
                  onSetEditing={handleSetEditing}
                  onThumnailSelect={onThumnailSelect}
                />
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </div>
  );
};

export default BlocksEditor;
