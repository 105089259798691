import {
  EditTwoTone,
  UserOutlined,
  SaveOutlined,
  DeleteTwoTone,
  SearchOutlined,
  PlusOutlined,
  UploadOutlined,
  RedoOutlined,
} from '@ant-design/icons';
import {
  Drawer,
  Button,
  Table,
  Spin,
  Row,
  Form,
  Space,
  Input,
  InputRef,
  Select,
  Popconfirm,
  message,
  Upload,
  Checkbox,
} from 'antd';
import { Image as Picture } from 'antd';
import { isNil, omitBy } from 'lodash';
import { useState, useEffect, useRef, useCallback } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from 'react-query';
import { useSelector } from 'react-redux';

import { usersService, rbacService } from 'config/services';

import { HttpError } from 'helpers/http';
import { capitalizeFirstLetter, getBase64 } from 'helpers/shared';

import { PaginationParams, PatchParams } from 'types/common';
import { FileExtended, UploadFile } from 'types/files';
import { Image } from 'types/services/images';
import { Role } from 'types/services/rbac';
import { User, PaginatedUsers, CreateUser } from 'types/services/users';
import { SessionState, StoreState } from 'types/store';

import './style.scss';

const { Column } = Table;
const { Option } = Select;

interface UploadUserImagesProps {
  value?: Image | Image[];
  onChangeProp?: (images: Image[]) => void;
  maxCount?: number;
  multiple?: boolean;
  mode?: 'row' | 'card';
}

export type ModalPromise = {
  resolve: (image: FileExtended | UploadFile) => void;
  reject: (image?: FileExtended | UploadFile) => void;
};

export const ListUsers = ({
  value,
  maxCount,
  multiple = false,
  mode = 'card',
  onChangeProp,
}: UploadUserImagesProps) => {
  const { t } = useTranslation();
  const { user } = useSelector<StoreState, SessionState>(({ session }) => session);

  const [isDrawerVisible, setIsDrawerVisible] = useState(false);
  const [isDrawerVisibleCreate, setIsDrawerVisibleCreate] = useState(false);
  const [isReloading, setIsReloading] = useState(false);
  const [searchedColumn, setSearchedColumn] = useState('');
  const [roles, setRoles] = useState<Role[]>([]);
  const [form] = Form.useForm<User>();
  const [formEdit] = Form.useForm<User>();
  const [pagination, setPagination] = useState<PaginationParams>({
    page: 1,
    perPage: 10,
    filters: {},
    admin: false,
  });
  const [filters, setFilters] = useState<Partial<User>>({});
  const filterInputNode = useRef<InputRef>(null);
  const [warning, setWarning] = useState(false);
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const editImageModalPromise = useRef<ModalPromise>();
  const [selectedImage, setSelectedImage] = useState<{ isNewImage: boolean; image?: FileExtended | UploadFile }>();
  const [warningType, setWarningType] = useState<any>([]);
  const [squareImageEdit] = useState(false);
  const [thumbnailUrl, setThumbnailUrl] = useState<string | null>();
  const isCardMode = mode === 'card';
  const [showDeleted, setShowDeleted] = useState(false);

  useEffect(() => {
    setPagination({
      page: pagination.page,
      perPage: pagination.perPage,
      filters: pagination.filters,
      admin: showDeleted,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showDeleted]);

  const {
    isLoading,
    isError,
    data: response,
    error,
    refetch,
  } = useQuery<PaginatedUsers, HttpError>('listDeletedUsers', () => usersService.list(pagination));

  const { isLoading: isEditing, mutateAsync } = useMutation<User, HttpError, PatchParams<User>>('editUser', (params) =>
    usersService.patch(params),
  );

  const { isLoading: isCreatingUser, mutateAsync: createUser } = useMutation('createUser', (user: CreateUser) =>
    usersService.create(user),
  );

  useEffect(() => {
    async function fetchActions() {
      try {
        const response = await rbacService.getRoles();
        setRoles(response.roles);
      } catch (err) {
        // TODO: Add error handler
      }
    }

    fetchActions();
  }, []);

  useEffect(() => {
    async function refetchQuery() {
      setIsReloading(true);
      await refetch();
      setIsReloading(() => false);
    }

    refetchQuery();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pagination]);

  const handleChange = useCallback(
    (files: UploadFile[]) => {
      setFileList(files);

      if (files.every((f) => f.status === 'done')) {
        onChangeProp?.(files.map((f) => f.response as Image));
      }
    },
    [onChangeProp],
  );

  function onChange(page: number) {
    setPagination((previousPagination) => ({ ...previousPagination, page }));
  }

  function onShowSizeChange(current: number, pageSize: number) {
    setPagination({ page: current, perPage: pageSize });
  }

  function transform(users: User[]) {
    return users.map((user) => ({ key: user.id, ...user }));
  }

  function closeDrawer() {
    setWarningType([]);
    setWarning(false);
    setThumbnailUrl(null);
    formEdit.resetFields();
    setIsDrawerVisible(false);
  }

  function closeDrawerCreate() {
    setWarningType([]);
    setWarning(false);
    setThumbnailUrl(null);
    form.resetFields();
    setIsDrawerVisibleCreate(false);
  }

  function openDrawer(data: User) {
    formEdit.setFieldsValue(data);
    setThumbnailUrl(data.thumbnailUrl);
    setIsDrawerVisible(true);
  }

  function openDrawerCreate() {
    setIsDrawerVisibleCreate(true);
  }

  async function onFormSubmit() {
    const values = { ...formEdit.getFieldsValue(), thumbnailUrl: thumbnailUrl } as User;
    await mutateAsync(values)
      .then((resp: any) => {
        if (resp.errors) {
          setIsDrawerVisible(true);
          setWarningType(resp.errors);
          setWarning(true);
          message.error(t(`common:Invalid data`));
        } else {
          refetch();
          setIsDrawerVisible(false);
          setWarning(false);
          message.success(t(`common:Successfully Updated`, { resource: t('common:User') }));
          formEdit.resetFields();
          setFileList([]);
          setThumbnailUrl(null);
        }
      })
      .catch((e) => {});
  }

  const handleUploadChange = (info: any) => {
    const { fileList, file } = info;
    if (file.percent === 100) {
      const image = fileList.find((i: UploadFile) => i.uid === file.uid);
      image.url = file.response.imageUrl;
      image.uid = file.response.id;
      image.description = file.response.description;
      image.caption = file.response.caption;
      image.source = file.response.source;
    }
    handleChange(fileList);
  };

  const customRequest = async (options: any) => {
    const { onSuccess = () => {}, onError = () => {} } = options;

    try {
      const { thumbnailUrl } = await usersService.createPhoto(options);
      setThumbnailUrl(thumbnailUrl);
      onSuccess({
        thumbnailUrl,
      });

      refetch();
    } catch (error: any) {
      onError(error);
    }
  };

  const attachData = (file: UploadFile) => {
    return new Promise<Record<string, any>>(async (resolve, reject) => {
      if (!file.url) {
        const base64String = await getBase64(file as unknown as Blob);
        file.url = base64String;
      }
      resolve(file);
    });
  };

  const beforeUpload = async (file: File, fileList: File[]) => {
    const modalPromise = new Promise<any>((resolve, reject) => {
      editImageModalPromise.current = { resolve, reject };
    });
    if (fileList.length > 1) {
      return Promise.resolve(true);
    }
    modalPromise.finally(closeEditImageModal);

    const fileExtended = file as unknown as FileExtended;
    const base64String = await getBase64(file);
    fileExtended.url = base64String;

    setSelectedImage({ isNewImage: true, image: fileExtended });
    return modalPromise;
  };

  const closeEditImageModal = () => {
    setSelectedImage(undefined);
  };

  const useMediaQuery = (width: number) => {
    const [targetReached, setTargetReached] = useState(false);

    const updateTarget = useCallback((e) => {
      if (e.matches) {
        setTargetReached(true);
      } else {
        setTargetReached(false);
      }
    }, []);

    useEffect(() => {
      const media = window.matchMedia(`(max-width: ${width}px)`);
      media.addEventListener('change', updateTarget);
      // Check on mount (callback is not called until a change occurs)
      if (media.matches) {
        setTargetReached(true);
      }

      return () => media.removeEventListener('change', updateTarget);
    }, []);

    return targetReached;
  };

  const isBreakpoint = useMediaQuery(768);

  async function onFormSubmitCreate() {
    const values = { ...form.getFieldsValue(), thumbnailUrl: thumbnailUrl } as CreateUser;

    createUser(values)
      .then((resp: any) => {
        if (resp.errors) {
          setIsDrawerVisibleCreate(true);
          setWarningType(resp.errors);
          setWarning(true);
          message.error(t(`common:Invalid data`));
        } else {
          refetch();
          setIsDrawerVisibleCreate(false);
          setWarning(false);
          message.success(t(`common:Successfully Created`, { resource: t('common:User') }));
          form.resetFields();
          setFileList([]);
          setThumbnailUrl(null);
        }
      })
      .catch((e) => {});
  }

  async function onDelete(id: number) {
    try {
      await usersService.delete(id);
      refetch();
    } catch (err) {
      // TODO: Add error handler
    }
  }

  async function onRecover(id: number) {
    try {
      await usersService.recover(id);
      refetch();
    } catch (err) {
      // TODO: Add error handler
    }
  }

  const getColumnSearchProps = (dataIndex: keyof User) => ({
    filterDropdown: () => (
      <div style={{ padding: 8 }}>
        <Input.Search
          value={filters[dataIndex]}
          placeholder={t('common:Search term')}
          onChange={({ target }) => setFilters({ ...filters, [dataIndex]: target.value })}
          onSearch={() => setPagination({ ...pagination, filters, page: 1, admin: showDeleted })}
          ref={(node) => {
            if (searchedColumn === dataIndex) {
              (filterInputNode as any).current = node;
            }
          }}
        />
      </div>
    ),
    filterIcon: (_: boolean) => {
      const isFiltered = !!filters[dataIndex];
      return <SearchOutlined style={{ color: isFiltered ? '#1890ff' : undefined }} />;
    },
    onFilterDropdownVisibleChange: (visible: boolean) => {
      if (visible) {
        setTimeout(() => {
          setSearchedColumn(dataIndex);
          filterInputNode.current?.select();
        }, 100);
      }
    },
  });

  if (isError) {
    return (
      <div>
        <pre>{JSON.stringify(error, undefined, 2)}</pre>
      </div>
    );
  }

  if (!response) {
    return (
      <div style={{ padding: '10px', textAlign: 'center' }}>
        <Spin size="large" />
      </div>
    );
  }

  const { data: users } = response;
  const { total } = response.pagination;

  const handleTableChange = (
    pagination: any,
    filters: {
      firstName?: string;
      lastName?: string;
      username?: string;
      email?: string;
      role?: string;
    },
    sorter: any,
  ) => {
    let filterValues = omitBy(
      {
        firstName: filters.firstName,
        lastName: filters.lastName,
        username: filters.username,
        email: filters.email,
        role: filters.role,
      },
      isNil,
    );

    //setPagination((p) => ({ ...p, page: 1 }));
    if (Object.keys(filterValues).length === 0) {
      filterValues.username = filters.firstName;
      filterValues.lastName = filters.lastName;
      filterValues.username = filters.username;
      filterValues.role = filters.role;
    }

    setFilters(filterValues);
  };

  const onShowDeletedChange = (e: any) => {
    setShowDeleted(!showDeleted);
  };

  return (
    <>
      <div style={{ padding: '10px', textAlign: 'center' }}>
        <Row gutter={[8, 16]}>
          <Button
            onClick={openDrawerCreate}
            type="primary"
            icon={<PlusOutlined />}
            disabled={isDrawerVisibleCreate}
            style={{ marginBottom: '10px' }}
            id="create-new-user"
          >
            {t('users:Add user')}
          </Button>
          {user?.role === 'ADMINISTRATOR' && (
            <Checkbox
              checked={showDeleted}
              onClick={onShowDeletedChange}
              style={{ fontWeight: '300', marginLeft: '12px', marginTop: '6px' }}
            >
              Prikaži obrisane
            </Checkbox>
          )}
          <Table
            bordered
            sticky
            size="small"
            loading={isLoading || isReloading}
            dataSource={transform(users)}
            pagination={{
              onChange,
              onShowSizeChange,
              size: 'default',
              position: ['bottomCenter'],
              showSizeChanger: true,
              showLessItems: true,
              current: pagination.page,
              pageSize: pagination.perPage,
              total,
            }}
            onChange={handleTableChange}
            scroll={{ x: isBreakpoint ? true : undefined }}
          >
            <Column
              title={t('users:First Name')}
              dataIndex="firstName"
              key="firstName"
              {...getColumnSearchProps('firstName')}
            />
            <Column
              title={t('users:Last Name')}
              dataIndex="lastName"
              key="lastName"
              {...getColumnSearchProps('lastName')}
            />
            <Column
              title={t('users:Username')}
              dataIndex="username"
              key="username"
              {...getColumnSearchProps('username')}
            />
            <Column title={t('users:Email')} dataIndex="email" key="email" {...getColumnSearchProps('email')} />
            <Column
              title={t('users:Role')}
              dataIndex="role"
              key="role"
              render={(value: string) => <>{capitalizeFirstLetter(value)}</>}
              {...getColumnSearchProps('role')}
            />
            <Column
              title={t('common:Options')}
              align="center"
              fixed="right"
              width="180px"
              key="edit"
              render={(_, user: { key: React.Key } & User) => (
                <Space>
                  <Button shape="round" icon={<EditTwoTone />} onClick={() => openDrawer(user)} />

                  <Popconfirm
                    okText={t('common:Yes')}
                    cancelText={t('common:No')}
                    placement="left"
                    onConfirm={() => onDelete(user.id)}
                    title={`${t('users:Delete user')}?`}
                  >
                    <Button shape="round" icon={<DeleteTwoTone twoToneColor="#eb2f96" />} />
                  </Popconfirm>
                  <Popconfirm
                    okText={t('common:Yes')}
                    cancelText={t('common:No')}
                    placement="left"
                    onConfirm={() => onRecover(user.id)}
                    title={`${t('users:Recover user')}?`}
                  >
                    <Button shape="round" icon={<RedoOutlined twoToneColor="#eb2f96" />} />
                  </Popconfirm>
                </Space>
              )}
            />
          </Table>
        </Row>
      </div>

      {/* ================ */}
      {/* CREATE USER DRAWER */}
      {/* ================ */}

      <Drawer
        width="500"
        placement="right"
        onClose={closeDrawerCreate}
        visible={isDrawerVisibleCreate}
        title={
          <div>
            <UserOutlined style={{ marginRight: '5px' }} />
            {<span>{t('users:Add user')}</span>}
          </div>
        }
        footer={[
          <div style={{ float: 'right' }}>
            <Button
              style={{ marginRight: '10px' }}
              key="back"
              disabled={isEditing || isCreatingUser}
              onClick={closeDrawerCreate}
            >
              {t('common:Cancel')}
            </Button>

            <Button
              key="submit"
              type="primary"
              disabled={isEditing || isCreatingUser}
              loading={isEditing}
              icon={<SaveOutlined />}
              onClick={form.submit}
            >
              {t('common:Save')}
            </Button>
          </div>,
        ]}
      >
        {/* ================== */}
        {/* CREATE USER FORM */}
        {/* ================== */}

        <div>
          <Form
            form={form}
            labelCol={{ span: 6 }}
            wrapperCol={{ span: 18 }}
            layout="horizontal"
            size="middle"
            onFinish={onFormSubmitCreate}
          >
            {warning && (
              <Form.Item name="warningMessage" style={{ fontFamily: 'DM Sans' }}>
                {warningType.map((role: any, i: any) => (
                  <>
                    <span key={`role-${i}`}>{t('users:' + capitalizeFirstLetter(role))}</span>
                    <br></br>
                  </>
                ))}
                <br></br>
                <label style={{ color: 'red' }}>{t('users:Wrong credentials')}</label>
              </Form.Item>
            )}
            <Form.Item
              name="firstName"
              rules={[{ required: true, message: t('article:First name required') }]}
              label={t('users:First Name')}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="lastName"
              rules={[{ required: true, message: t('article:Last name required') }]}
              label={t('users:Last Name')}
            >
              <Input />
            </Form.Item>
            {/* <Form.Item
              name="facebook"
              rules={[{ required: false, message: t('article:Facebook required') }]}
              label={t('users:Facebook')}
            >
              <Input />
            </Form.Item> */}
            {/* <Form.Item
              name="twitter"
              rules={[{ required: false, message: t('article:Twitter required') }]}
              label={t('users:Twitter')}
            >
              <Input />
            </Form.Item> */}
            <Form.Item
              name="email"
              rules={[{ required: true, message: t('article:Email required') }]}
              label={t('users:Email')}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="username"
              rules={[{ required: true, message: t('article:Username required') }]}
              label={t('users:Username')}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="role"
              rules={[{ required: true, message: t('article:Role required') }]}
              label={t('users:Role')}
            >
              <Select>
                {roles.map((role) => (
                  <Option key={`role-${role.name}`} value={role.name}>
                    {capitalizeFirstLetter(role.name)}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              name="password"
              rules={[{ required: true, message: t('article:Password required') }]}
              label={t('users:New password')}
            >
              <Input.Password />
            </Form.Item>

            {/* <Form.Item name="userImage" label={t('users:User image')}>
              <div className="userRow">
                <DndProvider backend={HTML5Backend}>
                  <Upload
                    listType={isCardMode ? 'picture-card' : 'picture'}
                    fileList={fileList}
                    onChange={handleUploadChange}
                    customRequest={customRequest}
                    data={attachData}
                    beforeUpload={beforeUpload}
                    maxCount={maxCount}
                    multiple={multiple}
                  >
                    {isCardMode ? (
                      <div>
                        <PlusOutlined />
                        <div style={{ marginTop: 8 }}>{t('images:upload')}</div>
                      </div>
                    ) : (
                      <Button icon={<UploadOutlined />}>{t('images:upload')}</Button>
                    )}
                  </Upload>
                </DndProvider>
              </div>
              {thumbnailUrl && (
                <div className="currentUser">
                  <Picture src={thumbnailUrl} />
                </div>
              )}
            </Form.Item> */}
          </Form>
        </div>
      </Drawer>

      {/* ================ */}
      {/* EDIT USER DRAWER */}
      {/* ================ */}

      <Drawer
        width="500"
        placement="right"
        onClose={closeDrawer}
        visible={isDrawerVisible}
        title={
          <div>
            <UserOutlined style={{ marginRight: '5px' }} />
            {<span>{t('users:Edit user')}</span>}
          </div>
        }
        footer={[
          <div style={{ float: 'right' }}>
            <Button style={{ marginRight: '10px' }} key="back" disabled={isEditing} onClick={closeDrawer}>
              {t('common:Cancel')}
            </Button>

            <Button
              key="submit"
              type="primary"
              disabled={isEditing}
              loading={isEditing}
              icon={<SaveOutlined />}
              onClick={formEdit.submit}
            >
              {t('common:Save')}
            </Button>
          </div>,
        ]}
      >
        {/* ================== */}
        {/* EDIT USER FORM */}
        {/* ================== */}
        <div>
          <Form
            form={formEdit}
            labelCol={{ span: 6 }}
            wrapperCol={{ span: 18 }}
            layout="horizontal"
            size="middle"
            onFinish={onFormSubmit}
          >
            {warning && (
              <Form.Item name="warningMessage" style={{ fontFamily: 'DM Sans' }}>
                {warningType.map((role: any, i: any) => (
                  <>
                    <span key={`role-${i}`}>{t('users:' + capitalizeFirstLetter(role))}</span>
                    <br></br>
                  </>
                ))}
                <br></br>
                <label style={{ color: 'red' }}>{t('users:Wrong credentials')}</label>
              </Form.Item>
            )}
            <Form.Item name="id" label="ID">
              <Input disabled readOnly />
            </Form.Item>
            <Form.Item
              name="firstName"
              rules={[{ required: true, message: t('article:First name required') }]}
              label={t('users:First Name')}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="lastName"
              rules={[{ required: true, message: t('article:Last name required') }]}
              label={t('users:Last Name')}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="email"
              rules={[{ required: true, message: t('article:Email required') }]}
              label={t('users:Email')}
            >
              <Input />
            </Form.Item>
            {/* <Form.Item
              name="facebook"
              rules={[{ required: false, message: t('article:Facebook required') }]}
              label={t('users:Facebook')}
            >
              <Input />
            </Form.Item> */}
            {/* <Form.Item
              name="twitter"
              rules={[{ required: false, message: t('article:Twitter required') }]}
              label={t('users:Twitter')}
            >
              <Input />
            </Form.Item> */}
            <Form.Item
              name="username"
              rules={[{ required: true, message: t('article:Username required') }]}
              label={t('users:Username')}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name="role"
              rules={[{ required: true, message: t('article:Role required') }]}
              label={t('users:Role')}
            >
              <Select>
                {roles.map((role) => (
                  <Option key={`role-${role.name}`} value={role.name}>
                    {capitalizeFirstLetter(role.name)}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item name="password" label={t('users:New password')}>
              {/* TODO: Add info popover */}
              <Input.Password />
            </Form.Item>

            {/* <Form.Item name="userImage" label={t('users:User image')}>
              <div className="userRow">
                <DndProvider backend={HTML5Backend}>
                  <Upload
                    listType={isCardMode ? 'picture-card' : 'picture'}
                    fileList={fileList}
                    onChange={handleUploadChange}
                    customRequest={customRequest}
                    data={attachData}
                    beforeUpload={beforeUpload}
                    maxCount={maxCount}
                    multiple={multiple}
                  >
                    {isCardMode ? (
                      <div>
                        <PlusOutlined />
                        <div style={{ marginTop: 8 }}>{t('images:upload')}</div>
                      </div>
                    ) : (
                      <Button icon={<UploadOutlined />}>{t('images:upload')}</Button>
                    )}
                  </Upload>
                </DndProvider>
              </div>
              {thumbnailUrl && (
                <div className="currentUser">
                  <Picture src={thumbnailUrl} />
                </div>
              )}
            </Form.Item> */}
          </Form>
        </div>
      </Drawer>
    </>
  );
};
