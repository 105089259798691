import classNames from 'classnames';

import { config } from './config';

interface Props {
  dividerColor?: string;
  footerColor?: string;
  footerClass?: string;
}

export const Footer = ({ dividerColor, footerColor, footerClass = 'ftClassMt' }: Props) => {
  const { social, links, info } = config;

  const renderLocationsRow = (locations: { key: string; link: string }[], divide = false) => (
    <div className={classNames('renderLoc', dividerColor)}>
      {locations.map(({ key, link }) => (
        <div key={link} className={classNames('renderLocation', divide && 'renderLocation2')}>
          <a href={link}>
            <p>{key}</p>
          </a>
        </div>
      ))}
    </div>
  );
  return (
    <>
      <div className={classNames(`footer-container ${footerClass}`, footerColor)} />
      <div className={`footer-wrapper`}>
        {/* <div className="footer-wr">
          <div className="ft-class">
            <div className="ft-main">
              <div className="ft-rel">
                <a href="/">
                  <img
                    alt={'footer:avaz-logo'}
                    src={'/gismo-footer-transp.png'}
                    style={{ height: '50px', width: '230px' }}
                  />
                </a>
              </div>
            </div>
          </div>

          <div className="footer-socials">
            <div className="footer-s">
              {social.map(({ key, link, icon: Icon }) => (
                <div key={key} className="footer-s-item">
                  <a href={link}>
                    <Icon classNames="footer-s-icon" />
                  </a>
                </div>
              ))}
            </div>
          </div>

          <div className="footer-links">
            <div className="ft-lks">
              <div className="ftspeclg footerSpecific2">{renderLocationsRow(links.slice(0, 3))}</div>

              <div className="ftspec">{renderLocationsRow(links.slice(3), true)}</div>
            </div>

            <div className="footer-main">
              {info.map((text) => (
                <div key={text} className="footer-single">
                  {text}
                </div>
              ))}
            </div>
          </div>
        </div> */}
      </div>
    </>
  );
};
