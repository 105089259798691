import { HttpClient } from 'helpers/http';

import {
  IObitaryService,
  Obituary,
  ObituaryFormat,
  ObituaryPayload,
  ObituaryPayloadWithId,
  OFormat,
} from 'types/services/obituary';

export class ObituaryService implements IObitaryService {
  constructor(private client: HttpClient) {}

  API = {
    obituary: '/api/obituaries',
    obituaryFormat: '/api/obituaryFormat',
    signature: '/api/signature',
    report: '/api/statistics',
    reportTotal: '/api/statistics',
    sites: '/api/statistics',
  };

  getObituaryById = (id: number) => {
    let url = `${this.API.obituary}/${id}`;

    return this.client.get<Obituary>(url);
  };

  create = (obituary: ObituaryPayload) => this.client.post<Obituary>(this.API.obituary, { obituary });

  patch = (obituary: ObituaryPayloadWithId) => {
    const url = `${this.API.obituary}/${obituary.id}`;
    return this.client.patch<Obituary>(url, { obituary });
  };

  getObituaryFormat = () => {
    let url = `${this.API.obituaryFormat}/get-obituary-formats`;

    return this.client.get<OFormat>(url);
  };
  getSignature = (uuid?: string) => {
    // if (!uuid)
    uuid = String(Date.now());
    let url = `${this.API.signature}?uuid=${uuid}`;
    return this.client.get<any>(url);
  };

  getReportForID = (id?: string) => {
    let url = `${this.API.report}/${id}`;
    return this.client.get<any>(url);
  };

  getReportByClient = (id?: string, selectedFilter?: string, selectedClick?: string) => {
    let url = `${this.API.reportTotal}/total?client=${id}&selectedFilter=${selectedFilter}&selectedClick=${selectedClick}`;
    return this.client.get<any>(url);
  };

  getSitesByClient = (id?: string) => {
    let url = `${this.API.sites}/getSitesByClient?client=${id}`;
    return this.client.get<any>(url);
  };
}
