import { PlusOutlined, SearchOutlined, UploadOutlined } from '@ant-design/icons';
import { Button, message, Upload } from 'antd';
import cx from 'classnames';
import { useState, useRef, useEffect, useCallback } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { useTranslation } from 'react-i18next';

import DraggableItem from 'components/DraggableItem';
import { EditImageModal, ModalPromise } from 'components/EditImageModal/EditImageModal';
import { ImageItem } from 'components/ImageItem/ImageItem';
import { ImageLibraryModal, SelectedImagesData } from 'components/ImageLibraryModal/ImageLibraryModal';

import { imagesService } from 'config/services';

import { getBase64 } from 'helpers/shared';

import { UploadFile, FileExtended } from 'types/files';
import { Image } from 'types/services/images';

import { mapImageToUploadFile } from '../helpers';

import ImageRow from './ImageRow';

import './UploadImages.scss';

interface UploadImagesProps {
  value?: Image | Image[];
  onChange?: (images: Image[]) => void;
  onThumbnailSelect?: (image: Image) => void;
  maxCount?: number;
  multiple?: boolean;
  draggable?: boolean;
  mode?: 'row' | 'card';
  showSquareThumbnail?: boolean;
  imageData?: imageDataType;
  index?: number;
  isCover?: boolean;
  isUpload?: boolean;
}

export type imageDataType = {
  description: string;
  source: string;
};

export const UploadImages = ({
  value,
  maxCount,
  multiple = false,
  draggable = false,
  mode = 'card',
  showSquareThumbnail,
  index,
  onChange,
  onThumbnailSelect,
  imageData,
  isCover,
  isUpload,
}: UploadImagesProps) => {
  const { t } = useTranslation();
  const [showImageLibraryModal, setShowImageLibraryModal] = useState(false);
  const [selectedImage, setSelectedImage] = useState<{ isNewImage: boolean; image?: FileExtended | UploadFile }>();
  const editImageModalPromise = useRef<ModalPromise>();
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [squareImageEdit, setSquareImageEdit] = useState(false);
  const [dragDropKey] = useState(
    Math.random()
      .toString(36)
      .replace(/[^a-z]+/g, ''),
  );

  const image = showSquareThumbnail ? (Array.isArray(value) ? value[0] : value) : null;
  const isCardMode = mode === 'card';

  useEffect(() => {
    setFileList((value ? (value instanceof Array ? value : [value]) : []).map(mapImageToUploadFile));
  }, [value]);

  //remove the image from filelist if steps for uploading are interupted, those images dont have property status, while other have
  useEffect(() => {
    let index = -1;

    fileList.forEach((i, ind) => {
      if (i.status === undefined) {
        index = ind;
      }
    });
    if (index !== -1) {
      fileList.splice(index, 1);
    }
  }, [fileList]);

  const handleChange = useCallback(
    (files: UploadFile[]) => {
      setFileList(files);

      if (files.every((f) => f.status === 'done')) {
        onChange?.(files.map((f) => f.response as Image));
      }
    },
    [onChange],
  );

  const handleUploadChange = (info: any) => {
    const { fileList, file } = info;
    if (file.percent === 100) {
      const image = fileList.find((i: UploadFile) => i.uid === file.uid);
      image.url = file.response.imageUrl;
      image.uid = file.response.id;
      image.description = file.response.description;
      image.caption = file.response.caption;
      image.source = file.response.source;
    }
    handleChange(fileList);
  };

  const customRequest = async (options: any) => {
    const { onSuccess = () => {}, onError = () => {} } = options;

    options.file.description = options.file.description ? options.file.description : imageData?.description;
    options.file.source = options.file.source ? options.file.source : imageData?.source;

    try {
      const { images } = await imagesService.upload(options);
      onSuccess({
        ...images[0],
        description: options.file.description,
        source: options.file.source,
        caption: options.file.caption,
      });
    } catch (error: any) {
      onError(error);
    }
  };

  const toggleImageLibraryModal = () => {
    setShowImageLibraryModal((value) => !value);
  };

  const handleImageSelection = ({ images, selectedIds }: SelectedImagesData) => {
    handleChange(
      fileList.filter((file) => selectedIds.includes(parseInt(file.uid))).concat(images.map(mapImageToUploadFile)),
    );
    toggleImageLibraryModal();
  };

  const handleImageEdit = (uid: string) => {
    const img = fileList.find((f) => f.uid === uid);

    setSelectedImage({ image: img, isNewImage: false });
    setSquareImageEdit(false);
    const modalPromise = new Promise<FileExtended | UploadFile>((resolve, reject) => {
      editImageModalPromise.current = { resolve, reject };
    });
    modalPromise.then(async (file) => {
      const currentCaption = file.caption;
      closeEditImageModal();
      let { images } = await imagesService.upload({ file, onProgress: () => {} });
      if (currentCaption !== undefined) images[0].caption = currentCaption;
      handleChange([...fileList.filter((i) => i.uid !== uid), ...images.map(mapImageToUploadFile)]);
    }, handleNonEditedImage);
  };

  const closeEditImageModal = () => {
    setSelectedImage(undefined);
  };

  const handleNonEditedImage = (image: FileExtended) => {
    closeEditImageModal();
    if (image) {
      const existingImage = fileList.find((i: UploadFile) => i.uid === (image as any).uid);
      if (existingImage) {
        existingImage.response.caption = image.caption;

        handleChange(fileList);
      }
    }
  };

  const attachData = (file: UploadFile) => {
    return new Promise<Record<string, any>>(async (resolve, reject) => {
      if (!file.url) {
        const base64String = await getBase64(file as unknown as Blob);
        file.url = base64String;
      }
      resolve(file);
    });
  };

  const beforeUpload = async (file: File, fileList: File[]) => {
    const isImage = ['image/jpg', 'image/jpeg', 'image/png', 'image/gif'].includes(file.type);
    if (!isImage) {
      message.error(`Niste odabrali sliku!`);
      return Upload.LIST_IGNORE;
    }

    const modalPromise = new Promise<any>((resolve, reject) => {
      editImageModalPromise.current = { resolve, reject };
    });
    modalPromise.finally(closeEditImageModal);

    const fileExtended = file as unknown as FileExtended;

    const base64String = await getBase64(file);
    fileExtended.url = base64String;

    if (fileList.length > 1) {
      return Promise.resolve(true);
    } else {
      setSelectedImage({ isNewImage: true, image: fileExtended });
      return modalPromise;
    }
  };

  const selectSquareThumbnailForEdit = () => {
    if (image) {
      setSelectedImage({ isNewImage: true, image: mapImageToUploadFile(image) });
      setSquareImageEdit(true);
      const modalPromise = new Promise<FileExtended | UploadFile>((resolve, reject) => {
        editImageModalPromise.current = { resolve, reject };
      });
      modalPromise
        .then(async (file) => {
          closeEditImageModal();
          const { image: updatedImage } = await imagesService.updateSquareThumbnail(image.id, file as FileExtended);
          const newList = [...fileList];
          newList.splice(0, 1, mapImageToUploadFile(updatedImage));
          handleChange(newList);
        }, closeEditImageModal)
        .finally(() => {
          setSquareImageEdit(false);
        });
    }
  };

  const moveRow = useCallback(
    (dragIndex, hoverIndex) => {
      const newList = [...fileList];
      newList.splice(hoverIndex, 0, newList.splice(dragIndex, 1)[0]);
      handleChange(newList);
    },
    [fileList, handleChange],
  );

  const imageItem = (file: any, actions: any, index: number) => {
    return isCardMode ? (
      <ImageItem
        uid={file.uid}
        height={104}
        width={showSquareThumbnail ? 185 : 104}
        src={file.url || file.thumbUrl}
        onImageEdit={handleImageEdit}
        onDelete={actions.remove}
        onThumbnailSelect={onThumbnailSelect ? () => onThumbnailSelect?.(file.response as Image) : undefined}
      />
    ) : (
      <ImageRow
        uid={file.uid}
        index={index + 1}
        src={file.url || file.thumbUrl || file.preview}
        text={file.description}
        onImageEdit={handleImageEdit}
        onDelete={actions.remove}
        onThumbnailSelect={onThumbnailSelect ? () => onThumbnailSelect?.(file.response as Image) : undefined}
      />
    );
  };

  const renderImage = (originNode: any, file: any, fileList: any, actions: any) => {
    const index = fileList.indexOf(file);
    if (index === -1) return null;
    return draggable ? (
      <DraggableItem moveRow={moveRow} index={index} dragDropKey={dragDropKey}>
        {imageItem(file, actions, index)}
      </DraggableItem>
    ) : (
      imageItem(file, actions, index)
    );
  };
  return (
    <div style={{ padding: '8px', display: 'flex' }} className="relative">
      <div className={cx({ 'flex flex-row': showSquareThumbnail })}>
        <DndProvider backend={HTML5Backend}>
          <Upload
            className={cx(
              { 'thumbnail-image': showSquareThumbnail, 'scrollable-images-list': !isCardMode },
              'image-container',
            )}
            fileList={fileList}
            listType={isCardMode ? 'picture-card' : 'picture'}
            onChange={handleUploadChange}
            customRequest={customRequest}
            data={attachData}
            beforeUpload={beforeUpload}
            itemRender={renderImage}
            maxCount={maxCount}
            multiple={multiple}
            accept=".jpg,.jpeg,.png,.gif"
          >
            {(maxCount ? fileList.length < maxCount : true) &&
              (isCardMode ? (
                <div>
                  <PlusOutlined />
                  <div style={{ marginTop: 8 }}>{t('images:upload')}</div>
                </div>
              ) : (
                <Button icon={<UploadOutlined />}>{t('images:upload')}</Button>
              ))}
          </Upload>
        </DndProvider>
        {showSquareThumbnail && image && (
          <div className="relative border-dotted">
            <ImageItem
              uid={image?.id.toString()}
              height={104}
              width={104}
              src={image?.squareThumbnailUrl}
              onImageEdit={selectSquareThumbnailForEdit}
            />
          </div>
        )}
      </div>

      {!isUpload && (
        <Button
          onClick={toggleImageLibraryModal}
          icon={<SearchOutlined />}
          style={{
            marginTop: isCover ? '0px' : '5px',
            backgroundColor: '#fafafa',
            height: '104px',
            width: '104px',
            whiteSpace: 'break-spaces',
            paddingTop: '15px',
            color: 'black',
            display: image?.imageUrl ? 'none' : '',
          }}
          className={cx({ 'choose-existing-btn': !isCardMode })}
          type="dashed"
        >
          {t('images:choose existing')}
        </Button>
      )}

      <ImageLibraryModal
        alreadySelectedIds={fileList.map((f) => parseInt(f.uid))}
        maxCount={maxCount}
        open={showImageLibraryModal}
        onClose={toggleImageLibraryModal}
        onConfirm={handleImageSelection}
      />
      <EditImageModal
        image={selectedImage?.image}
        visible={!!selectedImage}
        isNewImage={selectedImage?.isNewImage}
        squareThumbnailEdit={squareImageEdit}
        modalPromise={editImageModalPromise.current}
        index={index}
        onCancel={closeEditImageModal}
      />
    </div>
  );
};
