import { ActivityLog } from 'types/services/activity-log';

export const transformLogs = (resource: ActivityLog) => {
  return {
    id: resource.id,
    article: resource.article ?? '',
    title: resource.article.title,
    event: resource.event,
    user: resource.user ? resource.user?.firstName + ' ' + resource.user?.lastName : '',
    createdAt: resource.createdAt
      .slice(8, 10)
      .concat(
        '.',
        resource.createdAt.slice(5, 7),
        '.',
        resource.createdAt.slice(0, 4),
        ' - ',
        resource.createdAt.slice(11, 16),
      ),
  };
};
