import { UploadFile } from 'types/files';
import { Image } from 'types/services/images';

export const mapImageToUploadFile = (image: Image): UploadFile =>
  ({
    name: 'image',
    status: 'done',
    uid: String(image.id),
    url: image.imageUrl,
    description: image.description,
    source: image.source,
    caption: image.caption,
    response: image,
    createdAt: image.createdAt,
  } as UploadFile);
