export enum Resources {
  USERS = 'USERS',
  CUSTOMERS = 'CUSTOMERS',
  IMAGES = 'IMAGES',
  RESOURCES = 'RESOURCES',
  PERMISSIONS = 'PERMISSIONS',
  ROLES = 'ROLES',
  ARTICLES = 'ARTICLES',
  ACTIVITY_LOG = 'ACTIVITY_LOG',
  ARTICLE_BLOCKS = 'ARTICLE_BLOCKS',
  ARTICLE_BLOCKS_TYPES = 'ARTICLE_BLOCKS_TYPES',
  SETTINGS = 'SETTINGS',
  REPORTS = 'REPORTS',
}

export enum AvailableActions {
  GET = 'GET',
  LIST = 'LIST',
  CREATE = 'CREATE',
  UPDATE = 'UPDATE',
  UPDATE_LIST = 'UPDATE_LIST',
  REMOVE = 'REMOVE',
  GET_HISTORY = 'GET_HISTORY',
  GENERATE_MOCK = 'GENERATE_MOCK',
  MERGE = 'MERGE',
  EDIT_LAYOUT = 'EDIT_LAYOUT',
  APPROVE = 'APPROVE',
}

export enum Roles {
  ADMINISTRATOR = 'ADMINISTRATOR',
  JOURNALIST = 'JOURNALIST',
}
