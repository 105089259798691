import { Divider, Select, Spin } from 'antd';
import { useCallback, useEffect, useState } from 'react';
import { useQuery } from 'react-query';

import { obituaryService } from 'config/services';

import { HttpError } from 'helpers/http';

import { GroupedColumn } from './Histogram';
import { PieChart, PieChartTotal } from './Pie';
import { PieChartCountry, PieChartOS } from './PieCharts';
import { StackedAreaChart } from './StackedAreaChart';
import { StackedAreaChart2 } from './StackedAreaChart2';

export const AnalyticsTotal = (response?: any) => {
  let url = new URL(window.location.href);
  let decode = new URLSearchParams(url.search).getAll('client');
  // const [dailyImpressionsData, setDailyImpressionsData] = useState({});
  const [selectedFilter, setSelectedFilter] = useState('last7days');
  const [selectedClick, setSelectedClick] = useState('impression');

  const { isLoading, data, isRefetching, refetch } = useQuery<any, HttpError>(
    ['getReport', decode],
    () => obituaryService.getReportByClient(decode[0] || '', selectedFilter, selectedClick),
    {
      enabled: typeof decode !== 'undefined',
    },
  );

  useEffect(() => {
    console.log(data, 'IIIII');
  }, [data]);

  useEffect(() => {
    refetch();
  }, [selectedFilter, selectedClick]);

  const useMediaQuery = (width: number) => {
    const [targetReached, setTargetReached] = useState(false);

    const updateTarget = useCallback((e) => {
      if (e.matches) {
        setTargetReached(true);
      } else {
        setTargetReached(false);
      }
    }, []);

    useEffect(() => {
      const media = window.matchMedia(`(max-width: ${width}px)`);
      media.addEventListener('change', updateTarget);
      // Check on mount (callback is not called until a change occurs)
      if (media.matches) {
        setTargetReached(true);
      }

      return () => media.removeEventListener('change', updateTarget);
    }, []);

    return targetReached;
  };

  const isBreakpoint = useMediaQuery(768);
  // useEffect(() => {
  //   if (data) {
  //     const { dailyImpressions } = data;
  //     setDailyImpressionsData(dailyImpressions);
  //   }
  // }, [data, dailyImpressionsData]);
  const { Option } = Select;

  const handleDateFilterChange = (value: any) => {
    setSelectedFilter(value);
  };

  const handleClickFilterChange = (value: any) => {
    setSelectedClick(value);
  };

  if (isRefetching) {
    return (
      <div style={{ padding: '10px', textAlign: 'center' }}>
        <Spin size="large" />
      </div>
    );
  }

  return (
    <>
      <div
        style={{
          background: 'white',
          margin: '0 auto',
          display: 'block',
          maxWidth: '100%',
          padding: '20px',
        }}
      >
        <h1 className="mta main-container" style={{ paddingTop: '0px', marginTop: '0px', fontSize: '20px' }}>
          Izvještaj
        </h1>{' '}
        <Select
          defaultValue={selectedFilter}
          style={{ width: 150, marginRight: '10px' }}
          onChange={handleDateFilterChange}
        >
          <Option value="today">Danas</Option>
          <Option value="yesterday">Jučer</Option>
          <Option value="last7days">Zadnjih 7 dana</Option>
          <Option value="thisMonth">Ovaj mjesec</Option>
          <Option value="lastMonth">Prethodni mjesec</Option>
          <Option value="allTime">Sve ukupno</Option>
        </Select>
        <Select defaultValue={selectedClick} style={{ width: 150 }} onChange={handleClickFilterChange}>
          <Option value="impression">Impresije</Option>
          <Option value="click">Klikovi</Option>
        </Select>
        {/* {data && !isLoading && (
          <>
            <h1
              className="mta"
              style={{
                display: 'flex',
                width: '100%',
                justifyContent: 'center',
                fontSize: '20px',
                fontWeight: '700',
                fontFamily: 'DM Sans',
                marginBottom: '0px',
              }}
            >
              LIVE (30 min)
            </h1>
            <Divider />
          </>
        )} */}
        <div className="main-container" style={{ justifyContent: 'flex-start', paddingTop: '0px' }}>
          {/* {data && !isLoading && (
            <div className="mainReport">
              <div>
                {data.results?.views.length > 0 && <p>Pregledi</p>}

                <div className="contentList">
                  {data.results?.views.map((i: any) => (
                    <p>
                      <span>{i.name}</span> <b style={{ marginLeft: '20px' }}> {i.value}</b>
                    </p>
                  ))}
                </div>
              </div>
              <div>
                {data.results?.clicks.length > 0 && <p>Klikovi</p>}
                <div className="contentList">
                  {data.results?.clicks.map((i: any) => (
                    <p style={{ justifyContent: 'left' }}>
                      <b> {i.value}</b>
                      <span style={{ marginLeft: '20px' }}>{i.name}</span>
                    </p>
                  ))}
                </div>
              </div>
            </div>
          )} */}
        </div>
        {/* {data && !isLoading && (
          <div className="thirdRow" style={{ display: 'flex' }}>
            <div className="pieChart" style={{ width: '50%' }}>
              <div style={{ display: 'flex' }}>
                <p
                  style={{
                    marginTop: 23,
                    marginBottom: 'auto',
                    marginLeft: 28,
                    fontFamily: 'DM Sans',
                    fontSize: '20px',
                    fontWeight: 700,
                    letterSpacing: '-0.02em',
                    textAlign: 'left',
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                    textOverflow: 'ellipsis',
                  }}
                >
                  Pregledi po izvorima
                </p>
              </div>
              <PieChart props={data.results.views} />
            </div>
            <div className="pieChart" style={{ width: '50%', justifyContent: 'center' }}>
              <div style={{ display: 'flex' }}>
                <p
                  style={{
                    marginTop: 23,
                    marginBottom: 'auto',
                    marginLeft: 28,
                    fontFamily: 'DM Sans',
                    fontSize: '20px',
                    fontWeight: 700,
                    letterSpacing: '-0.02em',
                    textAlign: 'left',
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                    textOverflow: 'ellipsis',
                  }}
                >
                  Klikovi po izvorima
                </p>
              </div>
              <PieChart props={data.results.clicks} />
            </div>
          </div>
        )} */}
        {/* {data && !isLoading && (
          <h1
            className="mta"
            style={{
              display: 'flex',
              width: '100%',
              justifyContent: 'center',
              fontSize: '20px',
              fontWeight: '700',
              fontFamily: 'DM Sans',
              marginBottom: '0px',
              paddingTop: '20px',
            }}
          >
            TOTAL
          </h1>
        )} */}
        <div className="main-container" style={{ justifyContent: 'flex-start', marginTop: '0px', paddingTop: '0px' }}>
          {/* {data && !isLoading && (
            <div className="mainReport">
              <div>
                {data.resultsAll?.views.length > 0 && <p>Pregledi</p>}

                <div className="contentList">
                  {data.resultsAll?.views.map((i: any) => (
                    <p>
                      <span>{i.name}</span> <b style={{ marginLeft: '20px' }}> {i.value}</b>
                    </p>
                  ))}
                </div>
              </div>
              <div>
                {data.resultsAll?.clicks.length > 0 && <p>Klikovi</p>}
                <div className="contentList">
                  {data.resultsAll?.clicks.map((i: any) => (
                    <p style={{ justifyContent: 'left' }}>
                      <b> {i.value}</b>
                      <span style={{ marginLeft: '20px' }}>{i.name}</span>
                    </p>
                  ))}
                </div>
              </div>
            </div>
          )} */}
        </div>
        {data && !isLoading && (
          <div className={`${isBreakpoint ? 'secondRowAnalyticsMobile' : 'secondRowAnalytics'}`}>
            <div className={`${isBreakpoint ? 'firstPieMobile' : 'firstPie'}`}>
              <div style={{ display: 'flex' }}>
                <p className={`${isBreakpoint ? `textDescriptionMobile` : `textDescription`}`}>Pregledi po izvorima</p>
              </div>
              <PieChartTotal props={data.resultsAll.views} />
            </div>
            <div className={`${isBreakpoint ? 'areaChart-mobile' : 'areaChart'}`}>
              <div className={`${isBreakpoint ? '' : 'areaChartDiv'}`}>
                {/* <StackedAreaChart response={data.dailyImpressions} client={decode}></StackedAreaChart> */}
                {data && <StackedAreaChart2 response={[data.dailyImpressions, data.dailyClicks]}></StackedAreaChart2>}
              </div>
            </div>
            {/* <div className="secondPie">
              <div style={{ display: 'flex' }}>
                <p
                  style={{
                    marginTop: 23,
                    marginBottom: 'auto',
                    marginLeft: 28,
                    fontFamily: 'DM Sans',
                    fontSize: '20px',
                    fontWeight: 700,
                    letterSpacing: '-0.02em',
                    textAlign: 'left',
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                    textOverflow: 'ellipsis',
                  }}
                >
                  Klikovi po izvorima
                </p>
              </div>
              <PieChartTotal props={data.resultsAll.clicks} />
            </div> */}
          </div>
        )}
        {data && !isLoading && (
          <>
            <div className={`${isBreakpoint ? 'secondRowAnalyticsMobile' : 'secondRowAnalytics'}`}>
              <div className={`${isBreakpoint ? 'histogramm-mobile' : 'histogramm'}`}>
                <div style={{ display: 'flex' }}>
                  <p className={`${isBreakpoint ? `textDescriptionMobile` : `textDescription`}`}>Browser</p>
                </div>
                <GroupedColumn props={data.byBrowserfinal.views}></GroupedColumn>
              </div>
              <div className={`${isBreakpoint ? `firstPieDevices-mobile` : `firstPieDevices`}`}>
                <div style={{ display: 'flex' }}>
                  <p className={`${isBreakpoint ? `textDescriptionMobile` : `textDescription`}`}>Uređaji</p>
                </div>
                <PieChartOS props={data.bydevicefinal.views}></PieChartOS>
              </div>
              <div className={`${isBreakpoint ? `secondPie-mobile` : `secondPie`}`}>
                <div style={{ display: 'flex' }}>
                  <p className={`${isBreakpoint ? `textDescriptionMobile` : `textDescription`}`}>Države</p>
                </div>
                <PieChartCountry props={data.byCountryfinal.views}></PieChartCountry>
              </div>
            </div>
          </>
        )}
        {data && !isLoading && <div style={{ borderBottom: '1px solid #E3E3E3' }}></div>}
      </div>
    </>
  );
};
