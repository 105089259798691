import './index.scss';

import { Droppable } from 'react-beautiful-dnd';

import { ArticleBlock } from 'types/services/article-blocks';

import { Article } from '../../types/services/articles';

import { ArticleItem } from './ArticleItem';

export const ArticlesList = ({
  articles,
  allBlockArticlesIds,
  droppableId,
  getListStyle,
  getItemStyle,
  isDropDisabled,
  isAllDisabled,
  showBlockArticles,
  onDelete,
  blocks,
  allManualsBlockArticlesIds,
}: any) => {
  return (
    <Droppable droppableId={droppableId} isDropDisabled={isDropDisabled}>
      {(provided, snapshot) => (
        <div
          ref={provided.innerRef}
          {...provided.droppableProps}
          className="articles-list"
          style={{ overflowY: droppableId === 'articlesPool' ? 'scroll' : 'unset' }}
        >
          <div style={getListStyle(snapshot.isDraggingOver)}>
            {articles.map((article: Article, index: number) => {
              return (
                <div>
                  {/*  <span>{article.id}</span> */}
                  <ArticleItem
                    parentId={droppableId}
                    article={article}
                    index={index}
                    key={article.id}
                    getItemStyle={getItemStyle}
                    onDelete={onDelete}
                    isDisabled={false}
                    show={
                      droppableId === 'articlesPool' && showBlockArticles && allBlockArticlesIds.includes(article.id)
                        ? true
                        : false
                    }
                  />
                </div>
              );
            })}
            {articles.length === 0 ? (
              <div style={{ height: '50px' }}>{provided.placeholder}</div>
            ) : (
              provided.placeholder
            )}
          </div>
        </div>
      )}
    </Droppable>
  );
};
