import './index.scss';
import { Droppable } from 'react-beautiful-dnd';

import { Article } from 'types/services/articles';

import { ArticleBlockItem } from './ArticleBlockItem';

export interface TimeStampedEntity {
  id: number;
  createdAt: string;
  updatedAt: string;
  deletedAt?: string;
}

export interface ArticleBlock extends TimeStampedEntity {
  name: string;
  header: string;
  color: string;
  active: boolean;
  position: number;
  typeId: number;
  type?: {
    id: number;
    numberOfArticles?: number;
  };
  articles: Article[];
  isRandom: boolean;
}

export const ArticleBlocksList = ({
  articleBlocks,
  droppableId,
  getListStyle,
  getItemStyle,
  isDndDisabled,
  onDelete,
  onEdit,
}: any) => {
  return (
    <Droppable droppableId={droppableId} isDropDisabled={isDndDisabled}>
      {(provided, snapshot) => (
        <div ref={provided.innerRef} {...provided.droppableProps} className="article-blocks-container">
          <div className="inner-container grayOuterBorder borderRadius" style={getListStyle(snapshot.isDraggingOver)}>
            {articleBlocks.map((block: ArticleBlock, index: number) => (
              <ArticleBlockItem
                block={block}
                index={index}
                key={block.id}
                getItemStyle={getItemStyle}
                isDndDisabled={isDndDisabled}
                onDelete={onDelete}
                onEdit={onEdit}
              />
            ))}
            {provided.placeholder}
          </div>
        </div>
      )}
    </Droppable>
  );
};
