import { HttpClient } from 'helpers/http';

import {
  ArticleBlock,
  ArticleBlockOptions,
  ArticleBlockPayload,
  ArticleBlockPayloadWithId,
  ArticleBlocks,
  ArticleBlocksLayoutPayload,
  IArticleBlocksService,
  UpdateMultipleBlocksPayload,
} from '../../types/services/article-blocks';

export class ArticleBlocksService implements IArticleBlocksService {
  constructor(private client: HttpClient) {}

  API = {
    articleBlocks: '/api/article-blocks',
  };

  list = (options: ArticleBlockOptions = {}) => {
    let url = this.API.articleBlocks;
    if (options?.includeArticles) url = url + `?includeArticles=${options.includeArticles}`;
    if (options?.active) url = url + `&active=${options.active}`;
    return this.client.get<ArticleBlocks>(url);
  };

  get = (id: number) => {
    const url = `${this.API.articleBlocks}/${id}`;
    return this.client.get<ArticleBlock>(url);
  };

  create = (articleBlock: ArticleBlockPayload) =>
    this.client.post<ArticleBlock>(this.API.articleBlocks, { articleBlock });

  patch = (articleBlock: ArticleBlockPayloadWithId) => {
    const url = `${this.API.articleBlocks}/${articleBlock.id}`;
    return this.client.patch<ArticleBlock>(url, { articleBlock });
  };

  delete = (id: number) => {
    const url = `${this.API.articleBlocks}/${id}`;
    return this.client.remove<number>(url);
  };

  editLayout = (articleBlockIds: number[]) => {
    const url = `${this.API.articleBlocks}/layout`;
    return this.client.post<ArticleBlocks>(url, { layout: articleBlockIds } as ArticleBlocksLayoutPayload);
  };

  updateMultipleBlocks = (payload: UpdateMultipleBlocksPayload) => {
    const url = `${this.API.articleBlocks}/update-multiple-blocks`;
    return this.client.post<string>(url, payload);
  };
}
