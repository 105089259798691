import './indexArticleItem.scss';
import { DeleteOutlined } from '@ant-design/icons';
import classnames from 'classnames';
import { Draggable } from 'react-beautiful-dnd';

export const ArticleItem = ({ article, index, getItemStyle, onDelete, parentId, isDisabled, show }: any) => {
  const year = article.createdAt.slice(0, 4);
  const month = Number(article.createdAt.slice(5, 7)) - 1;
  const day = article.createdAt.slice(8, 10);
  const hour = article.createdAt.slice(11, 13);
  const minute = article.createdAt.slice(14, 16);

  const currDate = new Date() as any;
  const createdDate = new Date(year, month, day, hour, minute) as any;

  var delta = Math.abs(currDate - createdDate) / 1000;

  // calculate (and subtract) whole days
  var days = Math.floor(delta / 86400);
  delta -= days * 86400;

  // calculate (and subtract) whole hours
  var hours = Math.floor(delta / 3600) % 24;
  delta -= hours * 3600;

  // calculate (and subtract) whole minutes
  var minutes = Math.floor(delta / 60) % 60;
  delta -= minutes * 60;

  if (article.articleStatistics?.numberOfHits !== undefined) {
    article.articleStatistics.numberOfHits = article.articleStatistics.numberOfHits.toString().padStart(7, ' ');
  }

  return (
    <Draggable draggableId={`${parentId}-${article.id.toString()}`} index={index} isDragDisabled={isDisabled}>
      {(provided, snapshot) => (
        <div
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          style={getItemStyle(snapshot.isDragging, provided.draggableProps.style, article)}
          className={classnames({
            'article-row': true,
            'hide-Articles': show,
            'article-row--disabled disableArticleItem': isDisabled,
          })}
        >
          <div className="article-content">
            {parentId !== 'articlesPool' && <div className="content-left">{index + 1}</div>}
            <div>
              <img
                style={{ width: '45px', height: '45px', marginRight: '10px' }}
                src={article.thumbnailImage?.squareThumbnailUrl}
                alt="img"
              />
            </div>
            <div className="article-inner-content">
              <div className="articleBlock-stamp" style={{ textTransform: 'uppercase' }}>
                {article.stamp}
                {'-'}
                {article.id}
              </div>
              <div className="articleBlock-title">{article.title}</div>
            </div>
          </div>
          <div className="right-side-article">
            <div className="article-text">
              <div className="article-timestamp">
                &nbsp;
                {days > 0 && days + 'd ' + hours + 'h ' + minutes + 'm  '}
                {days <= 0 && hours > 0 && hours + 'h ' + minutes + 'm  '}
                {days <= 0 && hours <= 0 && minutes + 'm  '}
                &nbsp;
              </div>
              <div className="article-hits">
                <pre>{article.articleStatistics?.numberOfHits && article.articleStatistics?.numberOfHits}</pre>
              </div>
            </div>
            {onDelete && !isDisabled && (
              <div className="article-remove">
                <DeleteOutlined onClick={() => onDelete(article.id, Number(parentId))} />
              </div>
            )}
          </div>
        </div>
      )}
    </Draggable>
  );
};
