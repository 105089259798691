import { useEffect } from 'react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';

import { articlesService, obituaryService } from 'config/services';

import { HttpError } from 'helpers/http';

import { Article } from 'types/services/articles';

import { Header } from './Header';
import { PieChartTotal } from './Pie';

export const AnalyticsPreview = () => {
  const { hash } = useParams<{ hash: string }>();
  const decode = Buffer.from(hash, 'base64').toString('ascii');

  // const [form] = Form.useForm();
  // const formattedData = undefined;
  // let formattedData2 = [
  //   { title: 'prvi', color: 'blue', cnt: '1' },
  //   { title: 'drugi', color: 'red', cnt: '1' },
  //   { title: 'treci', color: 'yellow', cnt: '1' },
  // ];
  // const { t } = useTranslation();
  const { isLoading, data } = useQuery<any, HttpError>(
    ['getReport', decode],
    () => obituaryService.getReportForID(decode || ''),
    {
      enabled: typeof decode !== 'undefined',
    },
  );

  const {
    isLoading: isLoadingArticle,
    data: article,
    refetch,
  } = useQuery<Article, HttpError>(['getArticle', decode], () => articlesService.get(parseInt(decode || '')), {
    enabled: typeof decode !== 'undefined',
  });

  // useEffect(() => {
  //   console.log(data, 'obi');
  //   if (data) {
  //     console.log('ima data neka');
  //   }
  // }, [data]);

  useEffect(() => {
    console.log(article, 'article');
    if (article) {
      console.log('ima article neki');
    }
  }, [article]);

  //TODO:implement following functions
  // const onFinish = (values: any) => {};
  // const handleFormChange = (changedValues: any) => {};

  return (
    <>
      <Header></Header>
      <div
        style={{
          background: '#f2f2f2',
          margin: '0 auto',
          display: 'block',
          maxWidth: '80%',
          padding: '20px',
          marginTop: '90px',
        }}
      >
        {article && !isLoadingArticle && (
          <h1
            className="mta main-container"
            style={{ paddingTop: '0px', marginTop: '0px', textAlign: 'center', marginBottom: '40px' }}
          >
            Izvještaj za "{article?.title}"
          </h1>
        )}

        {/* <Divider />
        <h1
          className="mta"
          style={{
            display: 'flex',
            width: '100%',
            justifyContent: 'center',
            fontSize: '20px',
            fontWeight: '700',
            fontFamily: 'DM Sans',
            marginBottom: '0px',
          }}
        >
          LIVE (30 min)
        </h1>

        {window.location.hostname.includes('localhost') && (
          <div className="main-container" style={{ justifyContent: 'flex-start', paddingTop: '0px' }}>
            {data && !isLoading && (
              <div className="mainReport">
                <div>
                  {data.results?.views.length > 0 && <p>Pregledi</p>}

                  <div className="contentList">
                    {data.results?.views.map((i: any) => (
                      <p>
                        <span>{i.name}</span> <b style={{ marginLeft: '20px' }}> {i.value}</b>
                      </p>
                    ))}
                  </div>
                </div>
                <div>
                  {data.results?.clicks.length > 0 && <p>Klikovi</p>}
                  <div className="contentList">
                    {data.results?.clicks.map((i: any) => (
                      <p style={{ justifyContent: 'left' }}>
                        <b> {i.value}</b>
                        <span style={{ marginLeft: '20px' }}>{i.name}</span>
                      </p>
                    ))}
                  </div>
                </div>
              </div>
            )}
          </div>
        )} */}
        {/* {data && !isLoading && (
          <div className="thirdRow" style={{ display: 'flex' }}>
            <div className="pieChart" style={{ width: '50%' }}>
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <p
                  style={{
                    marginTop: 23,
                    marginBottom: 'auto',
                    marginLeft: 28,
                    fontFamily: 'DM Sans',
                    fontSize: 24,
                    fontWeight: 700,
                    letterSpacing: '-0.02em',
                    textAlign: 'left',
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                    textOverflow: 'ellipsis',
                  }}
                >
                  Pregledi po izvorima
                </p>
              </div>
              <PieChart props={data.results.views} />
            </div>
            <div className="pieChart" style={{ width: '50%' }}>
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <p
                  style={{
                    marginTop: 23,
                    marginBottom: 'auto',
                    marginLeft: 28,
                    fontFamily: 'DM Sans',
                    fontSize: 24,
                    fontWeight: 700,
                    letterSpacing: '-0.02em',
                    textAlign: 'left',
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                    textOverflow: 'ellipsis',
                  }}
                >
                  Klikovi po izvorima
                </p>
              </div>
              <PieChart props={data.results.clicks} />
            </div>
          </div>
        )} */}
        {/* <Divider /> */}
        {/* <h1
          className="mta"
          style={{
            display: 'flex',
            width: '100%',
            justifyContent: 'center',
            fontSize: '20px',
            fontWeight: '700',
            fontFamily: 'DM Sans',
            marginBottom: '0px',
            paddingTop: '50px',
          }}
        >
          TOTAL
        </h1> */}

        {window.location.hostname.includes('localhost') && (
          <div className="main-container" style={{ justifyContent: 'flex-start', marginTop: '0px', paddingTop: '0px' }}>
            {data && !isLoading && (
              <div className="mainReport">
                <div>
                  {data.resultsAll?.views.length > 0 && <p>Pregledi</p>}

                  <div className="contentList">
                    {data.resultsAll?.views.map((i: any) => (
                      <p>
                        <span>{i.name}</span>{' '}
                        <b style={{ marginLeft: '20px', width: '30px', textAlign: 'right' }}> {i.value}</b>
                      </p>
                    ))}
                  </div>
                </div>
                <div>
                  {data.resultsAll?.clicks.length > 0 && <p>Klikovi</p>}
                  <div className="contentList">
                    {data.resultsAll?.clicks.map((i: any) => (
                      <p style={{ justifyContent: 'left' }}>
                        <b style={{ width: '30px' }}> {i.value}</b>
                        <span style={{ marginLeft: '20px' }}>{i.name}</span>
                      </p>
                    ))}
                  </div>
                </div>
              </div>
            )}
          </div>
        )}
        {data && !isLoading && (
          <div className="thirdRow" style={{ display: 'flex' }}>
            <div className="pieChart" style={{ width: '50%' }}>
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <p
                  style={{
                    marginTop: 23,
                    marginBottom: 'auto',
                    marginLeft: 28,
                    fontFamily: 'DM Sans',
                    fontSize: 20,
                    fontWeight: 700,
                    letterSpacing: '-0.02em',
                    textAlign: 'left',
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                    textOverflow: 'ellipsis',
                  }}
                >
                  Ukupni pregledi po izvorima
                </p>
              </div>
              <PieChartTotal props={data.resultsAll.views} />
            </div>
            <div className="pieChart" style={{ width: '50%' }}>
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <p
                  style={{
                    marginTop: 23,
                    marginBottom: 'auto',
                    marginLeft: 28,
                    fontFamily: 'DM Sans',
                    fontSize: 20,
                    fontWeight: 700,
                    letterSpacing: '-0.02em',
                    textAlign: 'left',
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                    textOverflow: 'ellipsis',
                  }}
                >
                  Ukupni klikovi po izvorima
                </p>
              </div>
              <PieChartTotal props={data.resultsAll.clicks} />
            </div>
          </div>
        )}
        {/* <div style={{ borderBottom: '1px solid #E3E3E3' }}></div> */}
      </div>
      <div style={{ paddingTop: '20px', paddingBottom: '10px' }}></div>
    </>
  );
};
