import {
  EditTwoTone,
  DeleteTwoTone,
  CheckCircleTwoTone,
  CloseCircleTwoTone,
  BarChartOutlined,
} from '@ant-design/icons';
import { Button, Table, Spin, Row, Space, Tooltip, Modal, Input, message, Tag, InputNumber } from 'antd';
import isNil from 'lodash/isNil';
import omitBy from 'lodash/omitBy';
import { useState, useEffect, useRef, useCallback } from 'react';
import { Chart } from 'react-google-charts';
import { useTranslation } from 'react-i18next';
import { useQuery, useMutation } from 'react-query';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { articlesService, usersService } from 'config/services';

import { HttpError } from 'helpers/http';

import { PaginationParams } from 'types/common';
import {
  Article,
  ArticleOptions,
  articleStatuses,
  articleStatusesJOURNALIST,
  ArticleStatusPayload,
  PaginatedArticles,
} from 'types/services/articles';
import { PermissionsMap } from 'types/services/auth';
import { PaginatedUsers } from 'types/services/users';
import { SessionState, StoreState } from 'types/store';

import ArticleRejectionModal from './components/ArticleRejectionModal';
import { routes } from './routes';
import { transformResource } from './utils';

import './index.scss';

const { Column } = Table;

export const Articles = (props?: any) => {
  const { t } = useTranslation();
  const history = useHistory();
  const [pagination, setPagination] = useState<PaginationParams>(
    props.perPage ? { page: 1, perPage: props.perPage } : { page: 1, perPage: 10 },
  );

  const [showArticleRejectionModal, setShowArticleRejectionModal] = useState(false);
  const selectedArticleId = useRef<number | undefined>();
  const filterInputNode = useRef<typeof Input>(null);

  const permissionMap = useSelector<StoreState, PermissionsMap>(({ session }) => session.permissions);
  const permissions = permissionMap['/articles'];

  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const [deleteArticleId, setDeleteArticleId] = useState(0);
  const [isDeletingArticle, setIsDeletingArticle] = useState(false);
  const [isBottomVisible, setIsBottomVisible] = useState(true);
  const [allIds, setAllIds] = useState<number[]>([]);

  const { user } = useSelector<StoreState, SessionState>(({ session }) => session);
  const {
    isError: isErrorUsers,
    data: paginatedUsers,
    error: usersError,
  } = useQuery<PaginatedUsers, HttpError>('listUsers', () =>
    usersService.list({ page: 1, perPage: 1000, filters: {} }),
  );

  const users = paginatedUsers?.data;

  const newDateFrom = new Date();
  newDateFrom.setDate(newDateFrom.getDate() - 3);

  const newDateTo = new Date();
  newDateTo.setDate(newDateTo.getDate() + 1);

  const [filters, setFilters] = useState<ArticleOptions>(
    props.authorId !== -1 && props.authorId !== undefined
      ? { authorId: props.authorId }
      : user?.role === 'JOURNALIST'
      ? { email: user?.email }
      : user?.role === 'COMMERCIALIST'
      ? {}
      : props?.location?.search === '?status=pending'
      ? { status: ['pending'] }
      : {},
  );

  const {
    isLoading,
    isFetching,
    isError,
    data: response,
    error,
    refetch,
  } = useQuery<PaginatedArticles, HttpError>(['listArticles'], () => articlesService.list(pagination, filters));
  //property perPage = 1000 due to retriving all users
  useEffect(() => {
    response?.data.forEach((r) => allIds.push(r.id));
  }, [response?.data]);

  const {
    isError: multipleIsError,
    data: multipleData,
    error: multipleError,
    isLoading: multipleIsLoading,
    refetch: multipleRefetch,
  } = useQuery<any, HttpError>('multiple', () => articlesService.multiple(allIds));

  // const data1 = [
  //   ['Datum', 'Impresije'],
  //   ['2013', 1000],
  //   ['2014', 1170],
  //   ['2015', 660],
  //   ['2016', 1030],
  // ];

  const translateStackedAreaChart = (values: any) => {
    const options = {
      vAxis: { minValue: 0, ticks: [], textPosition: 'none' },
      hAxis: { ticks: [], textPosition: 'none' },
      chartArea: { width: '50%', height: '70%' },
      series: {
        curveType: 'function', // Use 'function' for a smooth curve
      },
      legend: 'none',
    };

    // values.unshift(['Year', 'Sales']); 03 57 911

    let newArray = [['Datum', 'Impresije']];
    const tempdate = new Date();
    tempdate.setDate(tempdate.getDate() - 6);
    values.forEach((element: any) => {
      const tempString = tempdate.toISOString();
      const tempArray = ['0', '0'];
      tempArray[0] = tempString.slice(8, 10) + '.' + tempString.slice(5, 7) + '.' + tempString.slice(0, 4);
      tempArray[1] = element[1];
      newArray.push(tempArray);
      tempdate.setDate(tempdate.getDate() + 1);
    });

    return <Chart chartType="AreaChart" width="100%" height="100px" data={newArray} options={options} />;
  };

  useEffect(() => {
    multipleRefetch();
  }, [response]);

  const { mutateAsync: updateArticleStatus } = useMutation(
    'setArticleStatus',
    ({ id, status }: { id: number; status: ArticleStatusPayload }) => articlesService.setStatus(id, status),
  );

  useEffect(() => {
    const timeOutId = setTimeout(() => {
      refetch();
    }, 1000);
    return () => clearTimeout(timeOutId);
  }, [pagination, filters, refetch]);

  useEffect(() => {
    if (props.authorId !== -1 && props.authorId !== undefined) {
      setFilters({
        ...filters,
        authorId: [props.authorId],
        dateFrom: props.dateFrom as Date,
        dateTo: props.dateTo as Date,
        status: props.status,
      });
      setPagination(props.perPage ? { page: 1, perPage: props.perPage } : { page: 1, perPage: 10 });
    }
    if (props.visible !== undefined) {
      setIsBottomVisible(props.visible);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props]);

  function onPaginationChange(page: number) {
    setPagination((previousPagination) => ({ ...previousPagination, page }));
  }

  function onShowSizeChange(current: number, pageSize: number) {
    setPagination({ ...pagination, page: current, perPage: pageSize });
  }

  function transform(arts: Article[], stats: any) {
    return arts.map((article) => transformResource(article, stats?.resultsAll, stats?.trends));
  }

  const showDeleteArticleError = () => {
    message.error(t('article:Delete article error description'));
  };

  const showDeleteArticleSuccess = () => {
    message.success(` ${t('article:Delete article success description')}`);
  };

  const handleArticleDelete = async () => {
    try {
      setIsDeletingArticle(true);
      await articlesService.delete(deleteArticleId, user?.email);
      await refetch();
      showDeleteArticleSuccess();
    } catch (e) {
      showDeleteArticleError();
    } finally {
      setIsDeletingArticle(false);
      setDeleteModalVisible(false);
    }
  };

  const handleArticleDeleteCancel = () => {
    setDeleteModalVisible(false);
  };

  const openDeleteModal = (id: number) => {
    setDeleteArticleId(id);
    setDeleteModalVisible(true);
  };

  const handleArticleApprove = async (id: number) => {
    await updateArticleStatus({ id, status: { status: 'approved' } });
    refetch();
    message.success(` ${t('article:Status change success')}`);
  };

  const handleArticleReject = (id: number) => {
    setShowArticleRejectionModal(true);
    selectedArticleId.current = id;
  };

  const handlePreviewClick = async (id: number) => {
    let hash = Buffer.from(String(id)).toString('base64');
    history.push('/report/article/' + hash);
    //window.open(process.env.SHAZAM_APP_API_URL + '/report/article/' + hash, '_blank', 'noopener,noreferrer');
  };

  const handleArticleRejection = async (comment: string) => {
    if (selectedArticleId.current) {
      await updateArticleStatus({ id: selectedArticleId.current, status: { status: 'rejected', comment } });
      setShowArticleRejectionModal(false);
      refetch();
      message.success(` ${t('article:Status change success')}`);
    }
  };

  const closeArticleRejectionModal = () => {
    setShowArticleRejectionModal(false);
  };

  const translateStatus = (status: string) => {
    let color = 'green';
    let text = 'Da';
    if (status === 'rejected') {
      color = 'red';
      if (user?.role === 'JOURNALIST') text = 'Ne';
    } else if (status === 'pending') {
      color = 'orange';
      if (user?.role === 'JOURNALIST') {
        text = 'Ne';
        color = 'red';
      }
    }
    if (user?.role === 'JOURNALIST') return <Tag color={color}>{text}</Tag>;
    return <Tag color={color}>{t(`article:Status ${status}`)}</Tag>;
  };

  const useMediaQuery = (width: number) => {
    const [targetReached, setTargetReached] = useState(false);

    const updateTarget = useCallback((e) => {
      if (e.matches) {
        setTargetReached(true);
      } else {
        setTargetReached(false);
      }
    }, []);

    useEffect(() => {
      const media = window.matchMedia(`(max-width: ${width}px)`);
      media.addEventListener('change', updateTarget);
      // Check on mount (callback is not called until a change occurs)
      if (media.matches) {
        setTargetReached(true);
      }

      return () => media.removeEventListener('change', updateTarget);
    }, []);

    return targetReached;
  };

  const isBreakpoint = useMediaQuery(768);

  function navigateToArticle(id: number) {
    const articleRoute = (routes.find((r) => r.key === 'article')?.path as string | undefined)?.replace(
      ':id',
      id.toString(),
    );
    articleRoute && history.push(articleRoute);
  }

  const handleTableChange = (
    pagination: any,
    filters: {
      status?: string[];
      category?: number[];
      author?: null | number[];
      title?: string[];
    },
    sorter: any,
  ) => {
    let filterValues = omitBy(
      {
        status: filters.status,
        categoryIds: filters.category,
        authorId: filters.author,
        title: filters.title,
      },
      isNil,
    );

    //setPagination((p) => ({ ...p, page: 1 }));
    if (Object.keys(filterValues).length === 0) {
      if (props.authorId !== -1 && props.authorId !== undefined) {
        filterValues.authorId = props.authorId;
        filterValues.dateFrom = props.dateFrom;
        filterValues.dateTo = props.dateTo;
        filterValues.status = props.status;
        filterValues.title = props.title;
      }
    }

    if (props.dateFrom && props.dateTo) {
      filterValues.dateFrom = props.dateFrom;
      filterValues.dateTo = props.dateTo;
    }

    if (user?.role === 'JOURNALIST') filterValues.email = [user.email];
    setFilters(filterValues);
  };

  if (isError || isErrorUsers) {
    return (
      <div>
        <pre>{JSON.stringify(error || usersError, undefined, 2)}</pre>
      </div>
    );
  }

  if (!response || !paginatedUsers) {
    return (
      <div style={{ padding: '10px', textAlign: 'center' }}>
        <Spin size="large" />
      </div>
    );
  }

  const userFilterOptions = users?.map((user) => ({ text: `${user.firstName} ${user.lastName}`, value: user.id }));

  const statusFilterOptions =
    user?.role === 'JOURNALIST'
      ? articleStatusesJOURNALIST.map((status) => ({ text: translateStatus(status), value: status }))
      : articleStatuses.map((status) => ({ text: translateStatus(status), value: status }));

  const titleFilterDropdown = () => (
    <div style={{ padding: 8 }}>
      <Input.Search
        value={filters.title}
        placeholder={t('common:Search term')}
        onChange={({ target }) => {
          setFilters((f) => ({ ...f, title: [target.value] }));
        }}
        onSearch={() => setPagination({ ...pagination, filters, page: 1 })}
        ref={(node) => {
          (filterInputNode as any).current = node;
        }}
      />
    </div>
  );

  const idFilterDropdown = () => (
    <div style={{ padding: 8 }}>
      <InputNumber
        value={filters.id}
        placeholder={t('common:Search term')}
        onChange={(target) => {
          if (target) {
            setFilters((f) => ({ ...f, id: target }));
          } else {
            setFilters(() => ({}));
          }
        }}
        ref={(node) => {
          (filterInputNode as any).current = node;
        }}
      />
    </div>
  );

  const handleUserClick = (event: any) => {
    const currUser = event.target.innerText;
    const firstName = currUser.split(' ')[0];
    const lastName = currUser.slice(currUser.indexOf(' ') + 1);

    users?.forEach((m) => {
      if (m.firstName === firstName && m.lastName === lastName) {
        setFilters({ authorId: [m.id] });
      }
    });
  };

  const { data: articles } = response;
  const { total } = response.pagination;

  return (
    <>
      <div style={{ padding: '10px', textAlign: 'center' }}>
        {isBottomVisible && !multipleIsLoading && (
          <Row gutter={[8, 16]}>
            <Table
              bordered
              sticky
              size="middle"
              loading={isLoading || isFetching}
              dataSource={transform(articles, multipleData)}
              pagination={{
                onChange: onPaginationChange,
                onShowSizeChange,
                size: 'default',
                position: ['bottomCenter'],
                showSizeChanger: true,
                showLessItems: true,
                current: pagination.page,
                pageSize: pagination.perPage,
                total,
              }}
              onChange={handleTableChange}
              scroll={{ x: isBreakpoint ? true : undefined }}
            >
              <Column
                key="id"
                dataIndex="id"
                title={'ID'}
                filterDropdown={idFilterDropdown}
                width={50}
                render={(text, art: any) => (
                  // <a style={{ color: '#598ad9' }} href={'/article/' + art.id}>
                  //   {text}
                  // </a>
                  <>{text}</>
                )}
              />
              <Column
                key="timeInBlock"
                dataIndex="timeInBlock"
                title={'Vrijeme u bloku'}
                width={150}
                render={(text, art: any) => (
                  // <a style={{ color: '#598ad9' }} href={'/article/' + art.id}>
                  //   {text}
                  // </a>
                  <>{text}</>
                )}
              />
              <Column
                key="clicks"
                dataIndex="clicks"
                title={'Klikovi'}
                width={75}
                render={(text, art: any) => <>{text}</>}
              />
              <Column
                key="views"
                dataIndex="views"
                title={'Pregledi'}
                width={75}
                render={(text, art: any) => <>{text}</>}
              />
              <Column key="ctr" dataIndex="ctr" title={'CTR %'} width={75} render={(text, art: any) => <>{text}</>} />

              <Column
                key="title"
                dataIndex="title"
                title={t('article:title')}
                filterDropdown={titleFilterDropdown}
                //#0000EE}
                width={300}
                render={(text, art: any) =>
                  user?.role === 'JOURNALIST' ? (
                    <p
                      style={{
                        maxWidth: '500px',
                        wordWrap: 'break-word',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'normal',
                      }}
                    >
                      {text}
                    </p>
                  ) : (
                    <a
                      style={{
                        color: '#598ad9',
                        maxWidth: '500px',
                        wordWrap: 'break-word',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'normal',
                      }}
                      href={'/article/' + art.id}
                    >
                      {text}
                    </a>
                  )
                }
                filteredValue={filters?.title}
              />

              <Column
                key="thumbnailImage"
                dataIndex="thumbnailImage"
                title={'Slika'}
                //filterDropdown={idFilterDropdown}
                width={200}
                render={
                  (image, art: any) => (
                    //<a style={{ color: '#598ad9' }} href={'/article/' + art.id}>
                    <>{image ? <img alt={image} src={image} /> : <span>n/A</span>}</>
                  )
                  //</a>
                }
              />

              <Column
                key="owner"
                dataIndex="owner"
                title={'Klijent'}
                //filters={userFilterOptions}
                //filterSearch
                //filteredValue={filters.ownerId}
                width={130}
                render={(text, art: any) => <>{text}</>}
              />

              {/* {user?.role === 'JOURNALIST' || user?.role === 'COMMERCIALIST' ? (
                <Column
                  key="author"
                  dataIndex="author"
                  title={t('article:author')}
                  filteredValue={filters.authorId}
                  width={150}
                  render={(text, art: any) => (
                    <button
                      style={{
                        color: '#598ad9',
                        backgroundColor: 'transparent',
                        border: 'none',
                        cursor: 'pointer',
                        textAlign: 'left',
                      }}
                      onClick={handleUserClick}
                    >
                      {text}
                    </button>
                  )}
                />
              ) : (
                <Column
                  key="author"
                  dataIndex="author"
                  title={t('article:author')}
                  filters={userFilterOptions}
                  filterSearch
                  filteredValue={filters.authorId}
                  width={150}
                  render={(text, art: any) => (
                    <button
                      style={{
                        color: '#598ad9',
                        backgroundColor: 'transparent',
                        border: 'none',
                        cursor: 'pointer',
                        textAlign: 'left',
                      }}
                      onClick={handleUserClick}
                    >
                      {text}
                    </button>
                  )}
                />
              )} */}

              {/* <Column key="numberOfHits" dataIndex="numberOfHits" width={80} title={t('article:hits')} align={'left'} /> */}
              <Column
                key="createdAt"
                dataIndex="createdAt"
                title={'Vrijeme kreiranja'}
                render={(text, art: any) => <div>{art.createdAt}</div>}
                width={170}
              />
              <Column
                key="status"
                dataIndex="status"
                title={user?.role === 'JOURNALIST' ? 'Aktivan' : t('common:Status')}
                render={translateStatus}
                filters={statusFilterOptions}
                align={'center'}
                filteredValue={filters.status}
                width={90}
              />
              <Column
                key="graph"
                dataIndex="graph"
                title={'Trends 7 dana impresije'}
                render={translateStackedAreaChart}
                align={'center'}
                width={280}
              />
              <Column
                title={t('common:Options')}
                align="center"
                // fixed="right"
                className="optionsList"
                width={65}
                key="edit"
                render={(_, article: { key: React.Key } & Article) => (
                  <Space wrap>
                    {permissions.approve && (
                      <>
                        {article.status !== 'approved' && user?.role !== 'JOURNALIST' && (
                          <Tooltip title={t('article:Approve')}>
                            <Button
                              shape="round"
                              icon={<CheckCircleTwoTone twoToneColor="lime" />}
                              onClick={() => handleArticleApprove(article.id)}
                            />
                          </Tooltip>
                        )}
                        {article.status !== 'rejected' && user?.role !== 'JOURNALIST' && (
                          <Tooltip title={t('article:Reject')}>
                            <Button
                              shape="round"
                              icon={<CloseCircleTwoTone twoToneColor="#eb2f96" />}
                              onClick={() => handleArticleReject(article.id)}
                            />
                          </Tooltip>
                        )}
                      </>
                    )}
                    <Tooltip title={t('common:Analytics')}>
                      <Button
                        shape="round"
                        icon={<BarChartOutlined />}
                        onClick={() => handlePreviewClick(article.id)}
                      />
                    </Tooltip>

                    {permissions.update && user?.role !== 'JOURNALIST' && (
                      <Tooltip title={t('common:Edit')}>
                        <Button shape="round" icon={<EditTwoTone />} onClick={() => navigateToArticle(article.id)} />
                      </Tooltip>
                    )}
                    {permissions.delete && article.blockPivot.length === 0 && user?.role !== 'JOURNALIST' && (
                      <Tooltip title={t('common:Delete')}>
                        <Button
                          shape="round"
                          icon={<DeleteTwoTone twoToneColor="#eb2f96" />}
                          onClick={() => openDeleteModal(article.id)}
                        />
                      </Tooltip>
                    )}
                  </Space>
                )}
              />
            </Table>
          </Row>
        )}
      </div>
      <Modal
        title={t('article:Delete article')}
        visible={deleteModalVisible}
        onOk={handleArticleDelete}
        confirmLoading={isDeletingArticle}
        onCancel={handleArticleDeleteCancel}
      >
        <p>{`${t('common:Delete Modal Text')} članak?`}</p>
      </Modal>
      <ArticleRejectionModal
        visible={showArticleRejectionModal}
        onConfirm={handleArticleRejection}
        onCancel={closeArticleRejectionModal}
      />
    </>
  );
};
