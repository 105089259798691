import { Pie, PieConfig } from '@ant-design/plots';
import { Empty } from 'antd';
import { useMemo } from 'react';

export const PieChartOS = (response: any) => {
  let temp = [
    { title: 'MacOS', value: 44, color: '#527A5F' },
    { title: 'Windows', value: 32, color: '#7C9082' },
    { title: 'Linux', value: 5, color: '#A7A284' },
    { title: 'Android', value: 5, color: 'pink' },
    { title: 'iOS', value: 5, color: 'purple' },
  ];

  const data = useMemo(() => {
    // console.log('pita2');
    // console.log(response);
    const data = [];

    if (response.props !== undefined) {
      for (let i = 0; i < response.props.length; i++) {
        data.push({
          title: response.props[i].name,
          value: Number(response.props[i].value),
          modifiedTitle: '',
        });
      }
    }
    return data;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [response]);

  const config: PieConfig = {
    height: 350,
    animation: {
      update: false,
      enter: false,
      leave: false,
      appear: false,
    },
    appendPadding: 10,
    data,
    legend: {
      layout: 'horizontal',
      position: 'bottom',
      maxRow: 1,
      flipPage: true,
      content: ({ current }: any) => `${current} + 100`,
    },
    tooltip: {
      //showContent: false,
    },
    angleField: 'value',
    colorField: 'title',
    color: ['#ED1C24', '#EE2F36', '#F04248', '#F1555A', '#F3686D', '#F47B7F', '#F68E91', '#F7A1A4', '#F8B4B6'],
    radius: 0.7,
    label: {
      type: 'inner',
      offset: '-30%',
      content: ({ percent }) => `${(percent * 100).toFixed(1)}%`,
      style: {
        fontSize: 14,
        textAlign: 'center',
      },
    },
    interactions: [
      {
        type: 'element-active',
      },
      {
        type: 'legend-filter',
        enable: false,
      },
    ],
  };
  return <>{data && data.length > 0 ? <Pie {...config} /> : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />}</>;
};

export const PieChartCountry = (totalResponse: any) => {
  let temp = [
    { title: 'BiH', value: 53, color: '#527A5F' },
    { title: 'Serbia', value: 48, color: '#A7A284' },
  ];
  const data = useMemo(() => {
    const data = [];

    if (totalResponse.props !== undefined) {
      for (let i = 0; i < totalResponse.props.length; i++) {
        data.push({
          title: totalResponse.props[i].name,
          value: Number(totalResponse.props[i].value),
          modifiedTitle: '',
        });
      }
    }
    return data;
  }, [totalResponse]);
  const config: PieConfig = {
    height: 350,
    animation: {
      update: false,
      enter: false,
      leave: false,
      appear: false,
    },
    appendPadding: 10,
    data,
    legend: {
      layout: 'horizontal',
      position: 'bottom',
      maxRow: 1,
      flipPage: true,
      content: ({ current }: any) => `${current} + 100`,
    },
    tooltip: {
      //showContent: false,
    },
    angleField: 'value',
    colorField: 'title',
    color: ['#ED1C24', '#EE2F36', '#F04248', '#F1555A', '#F3686D', '#F47B7F', '#F68E91', '#F7A1A4', '#F8B4B6'],
    radius: 0.7,
    label: {
      type: 'inner',
      offset: '-30%',
      content: ({ percent }) => `${(percent * 100).toFixed(1)}%`,
      style: {
        fontSize: 14,
        textAlign: 'center',
      },
    },
    interactions: [
      {
        type: 'element-active',
      },
      {
        type: 'legend-filter',
        enable: false,
      },
    ],
  };
  return <>{data && data.length > 0 ? <Pie {...config} /> : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />}</>;
};
