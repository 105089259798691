//* █▀█ █▄▀
//* █▄█ █░█

import classNames from 'classnames';
import { useCallback, useEffect, useState } from 'react';

import { CardDetails, CardDetailsProps } from './Details';

//?   ----------
//?  | -------- |
//?  ||  16:9  ||
//?  | -------- |
//?  |    V.    |
//?   ----------
//?
//?   -------------
//?  | -----       |
//?  || 1:1 |  H.  |
//?  | -----       |
//?   -------------

export const imageTransformer = (src: string) => {
  const aws_base = process.env.NEXT_PUBLIC_AWS_BASE_PATH as string;

  const envirement = process.env.NODE_ENV as string;
  const cdnHost = process.env.NEXT_PUBLIC_CDN_HOST as string;
  let fullpath = src;
  if (src && src.startsWith(aws_base) && envirement === 'production')
    fullpath = cdnHost + '/' + src.replace(aws_base, '');

  return fullpath;
};

export interface CardProps extends CardDetailsProps {
  containerClassNames?: string;
  thumbnail: {
    src: string;
    aspect?: 'square' | 'video';
    width?: '100%' | '48%' | '33%' | '26.5%' | '32%';
  };
  onClick?: any;
  id?: string;
  source?: string;

  hasVideo?: boolean;
  hasGallery?: boolean;
  imageClassNames?: string;
  hasImage?: boolean;
  articlePageCards?: boolean;
  isRelated?: boolean;
  lc3?: boolean;
  isLinked?: boolean;
  isAds?: boolean;
  isMobile?: boolean;
}

export const Card = (
  props: CardProps & {
    orientation: 'vertical' | 'horizontal';
  },
) => {
  const {
    id,
    source,
    isLinked,
    lc3,
    containerClassNames,
    orientation,
    thumbnail,
    hasVideo,
    imageClassNames,
    hasGallery,
    articlePageCards,
    isRelated,
    isMobile,
    ...details
  } = props;

  const { src, width = orientation === 'vertical' ? '100%' : '28.2%' } = thumbnail;

  const clicked = (data: string) => {
    if (source !== undefined) window.gtag('event', 'click-' + data + '-' + source);
  };

  const useMediaQuery = (width: number) => {
    const [targetReached, setTargetReached] = useState(false);

    const updateTarget = useCallback((e) => {
      if (e.matches) {
        setTargetReached(true);
      } else {
        setTargetReached(false);
      }
    }, []);

    useEffect(() => {
      const media = window.matchMedia(`(max-width: ${width}px)`);
      media.addEventListener('change', updateTarget);
      // Check on mount (callback is not called until a change occurs)
      if (media.matches) {
        setTargetReached(true);
      }

      return () => media.removeEventListener('change', updateTarget);
    }, []);

    return targetReached;
  };

  const isBreakpoint = useMediaQuery(768);

  return (
    <a
      href={details.link}
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
        clicked(String(id));
        //window.open('https://google.ba', '_blank');
        if (window.top) {
          setTimeout(() => {
            if (window.top) window.top.location.href = details.link;
          }, 100);
        }
      }}
    >
      <article
        className={classNames('', orientation === 'vertical' ? 'orVertical' : 'orNVertical', containerClassNames)}
        style={
          isBreakpoint
            ? { display: 'flex', flexWrap: 'wrap' }
            : { display: 'flex', flexWrap: 'wrap', maxWidth: '320px', paddingLeft: '10px', paddingRight: '10px' }
        }
      >
        <div style={{ width, position: 'relative' }}>
          <div
            style={{
              position: 'relative',
              // width: '100%',
              backgroundColor: 'rgb(229 231 235)',
              aspectRatio: '16/9',
              width: '100%',
              maxWidth: '320px',
              height: '100%',
            }}
          >
            <a href={details.link}>
              <div>
                <div
                  style={{
                    backgroundColor: '-webkit-linear-gradient(top, rgba(255,255,255,0) 0%,rgba(130,91,0,1) 100%)',
                  }}
                >
                  <img
                    src={imageTransformer(src) || '/#'}
                    alt={details.title}
                    style={{
                      objectFit: 'cover',
                      maxWidth: '320px',
                      aspectRatio: '16/9',
                      width: `${isMobile === true ? '174px' : '100%'}`,
                    }}
                    className={`${hasVideo === true || hasGallery === true ? 'opacity-hasVG' : ''}`}
                  />
                </div>
              </div>
            </a>
          </div>
        </div>

        <div style={{ flex: '1 1 0%' }}>
          <CardDetails {...details} isRelated={isRelated} lc3={isBreakpoint ? true : false} isLinked={isLinked} />
        </div>
      </article>
    </a>
  );
};
