// eslint-disable-next-line import/order
import {
  EditTwoTone,
  DeleteTwoTone,
  CheckCircleTwoTone,
  CloseCircleTwoTone,
  BarChartOutlined,
} from '@ant-design/icons';
// eslint-disable-next-line import/order
import { Button, Input, InputNumber, Row, Space, Spin, Table, Tag, Tooltip } from 'antd';

import './styles.css';
import { isNil, omitBy } from 'lodash';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { articlesService, usersService } from 'config/services';

import { HttpError } from 'helpers/http';

import { PaginationParams } from 'types/common';
import { Article, ArticleOptions, PaginatedArticles } from 'types/services/articles';
import { PaginatedUsers } from 'types/services/users';
import { SessionState, StoreState } from 'types/store';

import { transformResource } from './../articles/utils';
import { AnalyticsTotal } from './AnalyticsTotal';

const { Column } = Table;

export const Home = (props?: any) => {
  type ContainerProps = {
    classNames?: string;
    isAvazTv?: boolean;
    width?: string;
    height?: string;
    articleType?: string;
  };

  const { t } = useTranslation();
  const history = useHistory();
  const [isBottomVisible, setIsBottomVisible] = useState(true);
  const filterInputNode = useRef<typeof Input>(null);

  const [pagination, setPagination] = useState<PaginationParams>(
    props.perPage ? { page: 1, perPage: props.perPage } : { page: 1, perPage: 10 },
  );

  const { user } = useSelector<StoreState, SessionState>(({ session }) => session);
  const {
    isError: isErrorUsers,
    data: paginatedUsers,
    error: usersError,
  } = useQuery<PaginatedUsers, HttpError>('listUsers', () =>
    usersService.list({ page: 1, perPage: 1000, filters: {} }),
  );

  const users = paginatedUsers?.data;

  useEffect(() => {
    const newUrl = window.location.pathname + '?client=' + user?.email;
    window.history.replaceState({}, '', newUrl);
  }, []);

  const [filters, setFilters] = useState<ArticleOptions>(
    props.authorId !== -1 && props.authorId !== undefined
      ? { authorId: props.authorId }
      : user?.role === 'JOURNALIST'
      ? { email: user?.email, status: ['approved'], ownerId: [user.email] }
      : user?.role === 'COMMERCIALIST'
      ? {}
      : props?.location?.search === '?status=pending'
      ? { status: ['pending'] }
      : {},
  );

  const {
    isLoading,
    isFetching,
    isError,
    data: response,
    error,
    refetch,
  } = useQuery<PaginatedArticles, HttpError>(['listArticles'], () => articlesService.list(pagination, filters));
  //property perPage = 1000 due to retriving all users

  function onPaginationChange(page: number) {
    setPagination((previousPagination) => ({ ...previousPagination, page }));
  }

  function onShowSizeChange(current: number, pageSize: number) {
    setPagination({ ...pagination, page: current, perPage: pageSize });
  }

  const handlePreviewClick = async (id: number) => {
    let hash = Buffer.from(String(id)).toString('base64');
    history.push('/report/article/' + hash);
    //window.open(process.env.SHAZAM_APP_API_URL + '/report/article/' + hash, '_blank', 'noopener,noreferrer');
  };

  const useMediaQuery = (width: number) => {
    const [targetReached, setTargetReached] = useState(false);

    const updateTarget = useCallback((e) => {
      if (e.matches) {
        setTargetReached(true);
      } else {
        setTargetReached(false);
      }
    }, []);

    useEffect(() => {
      const media = window.matchMedia(`(max-width: ${width}px)`);
      media.addEventListener('change', updateTarget);
      // Check on mount (callback is not called until a change occurs)
      if (media.matches) {
        setTargetReached(true);
      }

      return () => media.removeEventListener('change', updateTarget);
    }, []);

    return targetReached;
  };

  const isBreakpoint = useMediaQuery(768);

  useEffect(() => {
    const timeOutId = setTimeout(() => {
      refetch();
    }, 1000);
    return () => clearTimeout(timeOutId);
  }, [pagination, filters, refetch]);

  useEffect(() => {
    if (props.authorId !== -1 && props.authorId !== undefined) {
      setFilters({
        ...filters,
        authorId: [props.authorId],
        dateFrom: props.dateFrom as Date,
        dateTo: props.dateTo as Date,
        status: props.status,
        ownerId: [props.ownerId],
      });
      setPagination(props.perPage ? { page: 1, perPage: props.perPage } : { page: 1, perPage: 10 });
    }
    if (props.visible !== undefined) {
      setIsBottomVisible(props.visible);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props]);

  const translateStatus = (status: string) => {
    let color = 'green';
    if (status === 'rejected') {
      color = 'red';
    } else if (status === 'pending') {
      color = 'orange';
    }

    return <Tag color={color}>{t(`article:Status ${status}`)}</Tag>;
  };

  useEffect(() => {
    const iframe = document.getElementById('iframe-1') as HTMLIFrameElement;
    if (iframe) {
      const iframeWindow = iframe.contentWindow;
      if (iframeWindow) {
        iframeWindow.addEventListener('load', () => {
          const iframeDocument = iframeWindow.document;
          const body = iframeDocument.body;

          body.style.backgroundColor = '#f9f9f9';
        });
      }
    }
  }, []);

  const handleTableChange = (
    pagination: any,
    filters: {
      status?: string[];
      category?: number[];
      author?: null | number[];
      title?: string[];
      owner?: null | number[];
    },
    sorter: any,
  ) => {
    let filterValues = omitBy(
      {
        status: filters.status,
        categoryIds: filters.category,
        authorId: filters.author,
        title: filters.title,
        ownerId: filters.owner,
      },
      isNil,
    );

    //setPagination((p) => ({ ...p, page: 1 }));
    if (Object.keys(filterValues).length === 0) {
      if (props.authorId !== -1 && props.authorId !== undefined) {
        filterValues.authorId = props.authorId;
        filterValues.ownerId = props.ownerId;
        filterValues.dateFrom = props.dateFrom;
        filterValues.dateTo = props.dateTo;
        filterValues.status = props.status;
        filterValues.title = props.title;
      }
    }

    if (props.dateFrom && props.dateTo) {
      filterValues.dateFrom = props.dateFrom;
      filterValues.dateTo = props.dateTo;
    }

    if (user?.role === 'JOURNALIST') filterValues.email = [user.email];
    setFilters(filterValues);
  };

  if (isError || isErrorUsers) {
    return (
      <div>
        <pre>{JSON.stringify(error || usersError, undefined, 2)}</pre>
      </div>
    );
  }

  if (!response || !paginatedUsers) {
    return (
      <div style={{ padding: '10px', textAlign: 'center' }}>
        <Spin size="large" />
      </div>
    );
  }

  const idFilterDropdown = () => (
    <div style={{ padding: 8 }}>
      <InputNumber
        value={filters.id}
        placeholder={t('common:Search term')}
        onChange={(target) => {
          if (target) {
            setFilters((f) => ({ ...f, id: target }));
          } else {
            setFilters(() => ({}));
          }
        }}
        ref={(node) => {
          (filterInputNode as any).current = node;
        }}
      />
    </div>
  );

  const { data: articles } = response;
  const { total } = response.pagination;
  return (
    <>
      <>
        <AnalyticsTotal></AnalyticsTotal>
      </>
      {/* <div
        style={{
          backgroundColor: '#f9f9f9',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
        }}
      >
        <p style={{ marginBottom: '0px', marginTop: '20px' }}>
          <h1>
            PREVIEW (<b>LIVE</b>)
          </h1>
        </p>
        <iframe
          src={'/widget/4/5/dark?source=valletta'}
          id={'iframe-1'}
          title={'articleSliderPage'}
          scrolling="no"
          style={{
            width: '100%',
            border: 'none',
            maxWidth: '1260px',
            height: '320px',
            overflow: 'hidden',
            marginTop: '20px',
          }}
          onLoad={() => {
            const iframeDocument = document.getElementsByTagName('iframe')[0]?.contentWindow?.document;
            const iframeLinks = iframeDocument?.getElementsByTagName('a');
            if (iframeLinks) {
              for (let i = 0; i < iframeLinks.length; i++) {
                const link = iframeLinks[i];
                // eslint-disable-next-line no-loop-func
                link.addEventListener('click', (event) => {
                  event.preventDefault();
                  window.location.href = link.href;
                });
              }
            }
          }}
        ></iframe>
      </div> */}
    </>
  );
};
