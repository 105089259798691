import { HttpClient } from 'helpers/http';

import { ByBlockParams, PaginationParams } from 'types/common';
import {
  PaginatedArticles,
  IArticlesService,
  Article,
  ArticlePayload,
  ArticlePayloadWithId,
  ArticleOptions,
  ArticleStatusPayload,
  ArticleStatusResponse,
} from 'types/services/articles';

export class ArticlesService implements IArticlesService {
  constructor(private client: HttpClient) {}

  API = {
    articles: '/api/articles',
    articlesByBlock: '/api/articles/listArticlesByBlock',
    multiple: '/api/statistics/multiple',
  };

  create = (article: ArticlePayload) => this.client.post<Article>(this.API.articles, { article });

  patch = (article: ArticlePayloadWithId) => {
    const url = `${this.API.articles}/${article.id}`;
    return this.client.patch<Article>(url, { article });
  };

  listByBlock = ({ takeNum, blockId }: ByBlockParams) => {
    let url = `${this.API.articlesByBlock}?`;
    const params: Record<string, any> = { blockId, takeNum };

    url =
      url +
      Object.keys(params)
        .map((key) => {
          const value = params[key];
          return Array.isArray(value)
            ? value.map((v) => `${key}=${encodeURIComponent(v)}`).join('&')
            : `${key}=${encodeURIComponent(value)}`;
        })
        .join('&');

    console.log(url);
    return this.client.get<PaginatedArticles>(url);
  };

  list = ({ page, perPage }: PaginationParams, options: ArticleOptions = {}) => {
    let url = `${this.API.articles}?`;
    const params: Record<string, any> = { page, perPage, ...options };

    url =
      url +
      Object.keys(params)
        .map((key) => {
          const value = params[key];
          return Array.isArray(value)
            ? value.map((v) => `${key}=${encodeURIComponent(v)}`).join('&')
            : `${key}=${encodeURIComponent(value)}`;
        })
        .join('&');
    return this.client.get<PaginatedArticles>(url);
  };
  delete = (id: number, email?: string) => {
    let url = `${this.API.articles}/${id}`;
    if (email) url += '?user=' + email;
    return this.client.remove<number>(url);
  };

  get = (id: number) => {
    const url = `${this.API.articles}/${id}`;
    return this.client.get<Article>(url);
  };

  setStatus = (id: number, status: ArticleStatusPayload) => {
    const url = `${this.API.articles}/${id}/set-status`;
    return this.client.post<ArticleStatusResponse>(url, { status });
  };

  multiple = (ids: number[]) => {
    let idNew = '';
    ids.forEach((i) => {
      idNew += i + ',';
    });

    const url = `${this.API.multiple}/${idNew.slice(0, -1)}`;
    return this.client.get<any>(url);
  };
}
