import { ConsoleSqlOutlined, PlusSquareOutlined, SaveOutlined } from '@ant-design/icons';
import { Form, Input, Button, Spin, message, Switch, Select, Drawer, Modal } from 'antd';
import { Image as Picture } from 'antd';
import { capitalize } from 'lodash';
import { useEffect, useState } from 'react';
import { TwitterPicker } from 'react-color';
import { useTranslation } from 'react-i18next';
import { useQuery, useMutation } from 'react-query';
import { useParams } from 'react-router-dom';

import { articleBlocksService, articleBlockTypesService } from 'config/services';

import { HttpError } from 'helpers/http';

import { Image } from 'types/services/images';

import './index.scss';
import { UploadImages } from '../../routes/articles/components/UploadImages/UploadImages';
import { PaginationParams } from '../../types/common';
import {
  ArticleBlock,
  ArticleBlockPayload,
  ArticleBlockPayloadWithId,
  ArticleBlockType,
  ArticleBlockTypes,
} from '../../types/services/article-blocks';
import { Article, ArticleOptions } from '../../types/services/articles';

interface ArticleBlockDrawerProps {
  open: boolean;
  refetchArticleBlocks: () => {};
  blockArticle?: ArticleBlockPayloadWithId;
  onCancel: () => void;
}

export const ArticleBlockDrawer2 = ({
  open,
  refetchArticleBlocks,
  blockArticle,
  onCancel,
}: ArticleBlockDrawerProps) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const { id } = useParams<{ id?: string }>();
  const { Option } = Select;

  const {
    isLoading: isTypesLoading,
    isError: isTypesError,
    data: typesData,
  } = useQuery<ArticleBlockTypes, HttpError>('listTypes', () => articleBlockTypesService.list());

  const {
    isLoading,
    error,
    isError,
    data: articleBlock,
  } = useQuery<ArticleBlock, HttpError>(['getArticleBlock', id], () => articleBlocksService.get(parseInt(id || '')), {
    enabled: typeof id !== 'undefined',
  });

  const { mutateAsync: createArticleBlock } = useMutation(
    'createArticleBlock',
    (articleBlockPayload: ArticleBlockPayload) => articleBlocksService.create(articleBlockPayload),
  );

  const { mutateAsync: updateArticleBlock } = useMutation(
    'updateArticleBlock',
    (blockArticle: ArticleBlockPayloadWithId) => articleBlocksService.patch(blockArticle),
  );

  const types: ArticleBlockType[] = typesData?.data || [];

  const [pagination, setPagination] = useState<PaginationParams>({ page: 1, perPage: 10 });
  const [articleOptions] = useState<ArticleOptions>({});
  const [blockArticles, setBlockArticles] = useState<Article[]>([]);
  const [selectedCategoryId, setSelectedCategoryId] = useState<number>(0);
  const [logoImageInput, setLogoImageInput] = useState(false);
  const [darkLogoImageInput, setDarkLogoImageInput] = useState(false);

  const [coverImagePreview, setCoverImagePreview] = useState<Image>();
  const [coverDarkImagePreview, setCoverDarkImagePreview] = useState<Image>();

  useEffect(() => {
    // console.log('logoimage useEffect');
    if (form.getFieldValue('logoImage') !== undefined) {
      if (Array.isArray(form.getFieldValue('logoImage'))) {
        setCoverImagePreview(form.getFieldValue('logoImage')[0]);
      } else setCoverImagePreview(form.getFieldValue('logoImage'));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form.getFieldValue('logoImage')]);

  useEffect(() => {
    if (form.getFieldValue('darkLogoImage') !== undefined) {
      if (Array.isArray(form.getFieldValue('darkLogoImage'))) {
        setCoverDarkImagePreview(form.getFieldValue('darkLogoImage')[0]);
      } else setCoverDarkImagePreview(form.getFieldValue('darkLogoImage'));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form.getFieldValue('darkLogoImage')]);

  useEffect(() => {
    if (blockArticle) {
      form.setFieldsValue({
        name: blockArticle.name,
        header: blockArticle.header,
        color: blockArticle.color,
        active: blockArticle.active,
        typeId: blockArticle.typeId,
        categoryId: blockArticle.categoryId,
        articleIds: blockArticle.articleIds,
        isRandom: blockArticle.isRandom,
        logoImage: blockArticle.imageBlock,
        darkLogoImage: blockArticle.imageBlockDark,
      });
    }
  }, [blockArticle, form]);

  useEffect(() => {
    if (!isLoading && articleBlock && types) {
      const { typeId, articles } = articleBlock;
      form.setFieldsValue({
        ...articleBlock,
        type: typeId,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [articleBlock, form, types]);

  // PAGINATION

  useEffect(() => {
    setPagination({ ...pagination, page: 1 });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [articleOptions]);

  if (isError || isTypesError) {
    return (
      <div>
        <pre>{JSON.stringify(error, undefined, 2)}</pre>
      </div>
    );
  }

  if (isLoading || isTypesLoading) {
    return (
      <div style={{ padding: '10px', textAlign: 'center' }}>
        <Spin size="large" />
      </div>
    );
  }

  const toggleLogoImageInput = () => {
    setLogoImageInput(!logoImageInput);
  };

  const toggleDarkLogoImageInput = () => {
    setDarkLogoImageInput(!darkLogoImageInput);
  };

  const onFinish = async (values: any) => {
    const articleBlockPayload = {
      name: values.name,
      header: values.header,
      color: values?.color?.hex ? values.color?.hex : values.color,
      active: values.active,
      isRandom: values.isRandom,
      categoryId: values.categoryId === 0 ? null : values.categoryId,
      typeId: values.typeId,
      articleIds: blockArticles.map((a) => a.id),
      logo: values.logoImage,
      logoDark: values.darkLogoImage,
    };
    const isUpdate = blockArticle?.id;
    try {
      isUpdate
        ? await updateArticleBlock({ id: blockArticle?.id, ...articleBlockPayload })
        : await createArticleBlock(articleBlockPayload);
      refetchArticleBlocks();

      handleCancel();
      message.success(
        t(`common:Successfully ${isUpdate ? 'Updated' : 'Created'}`, {
          resource: capitalize(t('articleBlock:Article block')),
        }),
      );
      if (!isUpdate) {
        refetchArticleBlocks();
        handleCancel();
      }
    } catch (e) {
      message.error(
        t('common:Action failed', {
          action: t(`common:${isUpdate ? 'Update' : 'Creation'}`),
          resource: t('articleBlock:Article block'),
          suffix: 'a',
        }),
      );
    }
  };

  const handleCancel = () => {
    form.resetFields();
    onCancel();
  };

  return (
    <Drawer
      width="500"
      placement="right"
      onClose={handleCancel}
      visible={open}
      footer={[
        <div style={{ float: 'right' }}>
          <Button style={{ marginRight: '10px' }} key="back" onClick={handleCancel}>
            {t('common:Cancel')}
          </Button>

          <Button key="submit" type="primary" icon={<SaveOutlined />} onClick={form.submit}>
            {t('common:Save')}
          </Button>
        </div>,
      ]}
    >
      <Form layout="vertical" form={form} name="article-block-form" onFinish={onFinish} className="article-block-form">
        <Form.Item name="name" label={t('articleBlock:name')} rules={[{ required: true }]}>
          <Input />
        </Form.Item>
        <Form.Item name="header" label={t('articleBlock:header')} rules={[{ required: true }]}>
          <Input disabled={selectedCategoryId !== 0} />
        </Form.Item>
        <Form.Item
          name="color"
          initialValue="#fff"
          valuePropName="color"
          label={t('articleBlock:color')}
          rules={[{ required: true }]}
          hidden={selectedCategoryId !== 0}
        >
          <TwitterPicker width="100%" />
        </Form.Item>
        <Form.Item initialValue={false} valuePropName="checked" name="active" label={t('articleBlock:active')}>
          <Switch />
        </Form.Item>
        <Form.Item initialValue={false} valuePropName="checked" name="isRandom" label={t('articleBlock:isRandom')}>
          <Switch />
        </Form.Item>
        <Form.Item name="typeId" label={t('articleBlock:type')} rules={[{ required: true }]}>
          <Select>
            {types.map((t) => (
              <Option key={t.id} value={t.id}>
                {t.name}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Button
          onClick={toggleLogoImageInput}
          icon={<PlusSquareOutlined />}
          style={{
            width: '100%',
            marginBottom: '10px',
            fontFamily: 'DM Sans',
            textTransform: 'uppercase',
          }}
        >
          {t('images:Choose logo image')}
        </Button>

        {coverImagePreview?.imageUrl && (
          <>
            <div style={{ display: 'flex', marginTop: '5px', justifyContent: 'space-between' }}>
              <Picture src={coverImagePreview.imageUrl} style={{ width: '170px', height: '95px' }}></Picture>
              {/*               <Picture src={coverImagePreview.squareThumbnailUrl} style={{ height: '95px', width: '95px' }}></Picture>
               */}{' '}
            </div>
          </>
        )}

        <Modal
          title={t('images:Choose logo image')}
          visible={logoImageInput}
          onOk={toggleLogoImageInput}
          onCancel={toggleLogoImageInput}
          className="imageModal"
          width={500}
        >
          <Form.Item
            name="logoImage"
            style={{ marginTop: '20px' }}
            rules={[{ required: false, message: t('article:Logo image required') }]}
          >
            <UploadImages maxCount={1} showSquareThumbnail={true} index={-1} isCover={true} />
          </Form.Item>
        </Modal>

        <Button
          onClick={toggleDarkLogoImageInput}
          icon={<PlusSquareOutlined />}
          style={{
            width: '100%',
            marginBottom: '10px',
            fontFamily: 'DM Sans',
            textTransform: 'uppercase',
          }}
        >
          {t('images:Choose logo image dark')}
        </Button>

        {coverDarkImagePreview?.imageUrl && (
          <>
            <div style={{ display: 'flex', marginTop: '5px', justifyContent: 'space-between' }}>
              <Picture src={coverDarkImagePreview.imageUrl} style={{ width: '170px', height: '95px' }}></Picture>
              {/* <Picture
                src={coverDarkImagePreview.squareThumbnailUrl}
                style={{ height: '95px', width: '95px' }}
              ></Picture> */}
            </div>
          </>
        )}

        <Modal
          title={t('images:Choose logo image dark')}
          visible={darkLogoImageInput}
          onOk={toggleDarkLogoImageInput}
          onCancel={toggleDarkLogoImageInput}
          className="imageModal"
          width={500}
        >
          <Form.Item
            name="darkLogoImage"
            style={{ marginTop: '20px' }}
            rules={[{ required: false, message: t('article:Logo image required') }]}
          >
            <UploadImages maxCount={1} showSquareThumbnail={true} index={-1} isCover={true} />
          </Form.Item>
        </Modal>
      </Form>
    </Drawer>
  );
};
